import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listItems = createAsyncThunk(
  "listItems",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/items?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getItemsForUi = createAsyncThunk("getItemsForUi", async (data) => {
  const request = await api(`api/admin/getallitems`, "", "get");
  return request;
});

export const getItemById = createAsyncThunk("getItemById", async (data) => {
  const request = await api(`api/admin/item/${data}`, "", "get");
  return request;
});

export const addItem = createAsyncThunk("addItem", async (data) => {
  const request = await api("api/admin/item", data, "post");
  return request;
});

export const updateItem = createAsyncThunk(
  "updateItem",
  async ({ data, id }) => {
    const request = await api(`api/admin/item/${id}`, data, "post");
    return request;
  }
);

export const deleteItems = createAsyncThunk("deleteItems", async (id) => {
  const request = await api(`api/admin/item/${id}`, {}, "delete");
  return request;
});

const itemSlice = createSlice({
  name: "items",
  initialState: {
    loading: false,
    items: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listItems.fulfilled, (state, action) => {
        state.items = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getItemsForUi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getItemsForUi.fulfilled, (state, action) => {
        state.items = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getItemsForUi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addItem.fulfilled, (state, action) => {
        state.loading = false;
        state.items.push(action.payload); // Add the new user to the users array
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(addItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.items = state.items.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(updateItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteItems.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default itemSlice.reducer;
