import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const ListSuppliers = createAsyncThunk(
  "ListSuppliers",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/suppliers?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getSupplierById = createAsyncThunk(
  "getSupplierById",
  async (data) => {
    const request = await api(`api/admin/supplier/${data}`, "", "get");
    return request;
  }
);

export const getSuppliersForUi = createAsyncThunk(
  "getSuppliersForUi",
  async (data) => {
    const request = await api(`api/admin/getallsupplier`, "", "get");
    return request;
  }
);

export const addSupplier = createAsyncThunk("addSupplier", async (data) => {
  const request = await api("api/admin/supplier", data, "post");
  return request;
});

export const updateSupplier = createAsyncThunk(
  "updateSupplier",
  async ({ data, id }) => {
    const request = await api(`api/admin/supplier/${id}`, data, "post");
    return request;
  }
);

const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    loading: false,
    suppliers: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(ListSuppliers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ListSuppliers.fulfilled, (state, action) => {
        state.suppliers = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(ListSuppliers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSuppliersForUi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSuppliersForUi.fulfilled, (state, action) => {
        state.suppliers = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSuppliersForUi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers.push(action.payload);
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(addSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = state.suppliers.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    //   .addCase(deleteUser.pending, (state) => {
    //     state.loading = true;
    //     state.user = null;
    //     state.error = null;
    //   })
    //   .addCase(deleteUser.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.user = action.payload;
    //     state.error = null;
    //   })
    //   .addCase(deleteUser.rejected, (state, action) => {
    //     state.loading = false;
    //     state.user = null;
    //     state.error = action.error.message;
    //   })
  },
});
export default supplierSlice.reducer;
