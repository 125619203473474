import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faEye } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  listCarMileage,
  deleteCarMileage,
} from "../../store/reducer/carMileage/carMileageSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";

function formatDateToMMYYYY(dateString) {
  const date = new Date(dateString);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Format date to dd-mm-yyyy
  return `${month}-${year}`;
}

function ListCarMileage() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { mileages = [], loading } = useSelector((state) => state.carMileage);
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [viewPdf, setViewPdf] = useState("");

  const handleCloseModal = () => setIsOpen(false);

  const viewFile = (pdf) => {
    setViewPdf(pdf);
    setIsOpen(true);
  };

  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem("userSession"));
    if (userSession?.role == 1) {
      setIsAdmin(true);
      // For admin, fetch all users and load car mileage for all users
      dispatch(getUserForUI()).then((result) => {
        if (result.payload) {
          setUsers(
            result.payload.filter(
              (user) => user.companyCar && user.companyCarRegistration
            )
          );
        }
        // Load car mileage for all users
        dispatch(
          listCarMileage({
            user: null, // No specific user, so it fetches all records
            month: null, // Optionally, you can filter by month here or pass as needed
          })
        );
      });
    } else {
      setIsAdmin(false);
      // Load car mileage for the current user
      dispatch(
        listCarMileage({
          user: userSession?.id,
          month: null, // Optionally, you can filter by month here or pass as needed
        })
      );
    }
  }, []);  

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const month = values.month;
    const user = values.user;

    dispatch(
      listCarMileage({
        month,
        user,
      })
    ).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <div className="flex flex-col mt-[20px] gap-[40px]">
      <div className="bg-grey md:flex gap-[10px]">
        <div className="w-full md:flex gap-[24px] flex-col bg-[#F8F8F8] rounded-[12px] p-6">
          <div className="flex flex-row justify-between pb-[20px] border-b border-[#E1E1E1] border-solid">
            <div>
              <span className="font-Inter font-[600] text-[22px] leading-[21px] text-[#1C1C20]">
                Search Car Mileage
              </span>
            </div>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={{
              month: "",
              year: "",
            }}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              values,
              errors,
              touched,
              setFieldValue,
              resetForm,
              handleSubmit,
            }) => (
              <Form>
                <div className="md:grid md:grid-cols-3 gap-3">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="month"
                    >
                      Month
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="month"
                      id="month"
                      name="month"
                      onChange={(e) => {
                        setFieldValue("month", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="user"
                    >
                      User
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="user"
                        name="user"
                        onChange={(e) => {
                          setFieldValue("user", e.target.value);
                        }}
                        disabled={!isAdmin}
                      >
                        {isAdmin ? (
                          <>
                            <option value="">Select User</option>
                            {users.map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.firstname + " " + user.lastname}
                              </option>
                            ))}
                          </>
                        ) : (
                          (() => {
                            const userSession = JSON.parse(
                              localStorage.getItem("userSession")
                            );
                            return (
                              <option value={userSession?.id} selected={true}>
                                {userSession?.firstname || ""}{" "}
                                {userSession?.lastname || ""}
                              </option>
                            );
                          })()
                        )}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-full bg-[#F8F8F8] rounded-[12px] mt-6">
                    <div>
                      <button
                        type="submit"
                        name="submit"
                        className={`py-[10px] px-[70px] text-center text-[#55A14A] border border-[#55A14A] w-full rounded-[12px] text-[18px] ${
                          isSubmitting ? "bg-gray-300" : "bg-white"
                        }`}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="">
        <div className="md:p-5 bg-[#F8F8F8] mt-[10px] rounded-[12px]">
          <div className="flex flex-col">
            <div className="md:flex justify-between items-center p-[5px]">
              <div className="leading-[30px] font-Mulish font-[700] text-[24px] mb-[10px] md:mb-0">
                <span className="">Car Mileage List</span>
              </div>
              <div className="">
                <Link to="/car-mileage/add">
                  <div className="py-[8px] px-[30px] bg-[#F8F8F8] font-[500] font-Inter text-[15px] flex justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B] w-max">
                    <span>Add Car Mileage</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="sm:rounded-lg mt-2 overflow-scroll text-center">
              <table className="w-full">
                <thead className="bg-white">
                  <tr className="rounded-[12px]">
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Expense Period
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Start Mileage
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      End Mileage
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Start Mileage Image
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      End Mileage Image
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                ) : (
                  <tbody>
                    {mileages?.length > 0 ? (
                      mileages.map((mileage) => (
                        <tr key={mileage.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {`${formatDateToMMYYYY(mileage.start_date)} / ${formatDateToMMYYYY(mileage.end_date)}`}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {Number(mileage.start_mileage).toFixed(0)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {Number(mileage.end_mileage).toFixed(0)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {mileage.user}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {mileage.start_mileage_image ? (
                              <div
                                className="bg-blue-100 rounded-lg p-2 cursor-pointer"
                                onClick={() => {
                                  viewFile(mileage.start_mileage_image);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  size="lg"
                                  style={{ color: "#397bd0" }}
                                />
                              </div>
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {mileage.end_mileage_image ? (
                              <div
                                className="bg-blue-100 rounded-lg p-2 cursor-pointer"
                                onClick={() => {
                                  viewFile(mileage.end_mileage_image);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  size="lg"
                                  style={{ color: "#397bd0" }}
                                />
                              </div>
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap font-medium">
                            <div className="flex gap-[10px] justify-center">
                              <div>
                                <Link to={`/car-mileage/${mileage.id}`}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-teal-100 rounded-lg"
                                  />
                                </Link>
                              </div>
                              <div>
                                <button
                                  onClick={(e) => {
                                    dispatch(deleteCarMileage(mileage.id)).then(
                                      (result) => {
                                        if (result.payload) {
                                          toast.success(result.payload.message);
                                        } else {
                                          toast.error(result.error.message);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/icons/delete.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-red-100 rounded-lg"
                                  />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center" colspan="7">
                          <h3 className="p-12 text-gray-400">
                            {" "}
                            No records, Adjust filters.{" "}
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-[800px] w-full h-[600px]">
            <div className="flex justify-between items-center">
              <button onClick={handleCloseModal} className="text-xl">
                &times;
              </button>
            </div>
            {viewPdf && (
              <iframe
                src={viewPdf}
                className="w-full h-[500px] mt-4 border"
                title="PDF Viewer"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ListCarMileage;
