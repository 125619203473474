import React, { useEffect, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faEye } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  editCarMileage,
  getCarMileageById,
} from "../../store/reducer/carMileage/carMileageSlice";
import { getExpensePeriodForUi } from "../../store/reducer/expensePeriod/expensePeriodSlice";

function formatDateToDDMMYYYY(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year from the date
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Format date to dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

function EditCarMileage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [carMileage, setCarMileage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [viewPdf, setViewPdf] = useState("");
  const [loading, setLoading] = useState(true);

  const [startMileageFileName, setStartMileageFileName] = useState("");
  const [endMileageFileName, setEndMileageFileName] = useState("");
  const [expensePeriods, setExpensePeriods] = useState([]);

  const handleCloseModal = () => setIsOpen(false);

  const viewFile = (pdf) => {
    setViewPdf(pdf);
    setIsOpen(true);
  };

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    expense_period: Yup.number().required("Please select expense period"),
    start_mileage: Yup.number()
      .typeError("Start mileage reading must be a number")
      .required("Please enter start mileage reading")
      .integer("Start mileage reading must be a whole number")
      .positive("Start mileage reading must be greater than zero"),
    end_mileage: Yup.number()
    .typeError("End mileage reading must be a number")
    .required("Please enter end mileage reading")
    .integer("End mileage reading must be a whole number")
    .positive("End mileage reading must be greater than zero"),
    start_mileage_image: Yup.mixed()
    .nullable() // Allow null value
    .test(
      "fileType",
      "Unsupported file format",
      (value) =>
        !value || (value && ["image/jpeg", "image/png"].includes(value.type))
    ),
    end_mileage_image: Yup.mixed()
      .nullable() // Allow null value
      .test(
        "fileType",
        "Unsupported file format",
        (value) =>
          !value || (value && ["image/jpeg", "image/png"].includes(value.type))
      ),
  });

  useEffect(() => {
    dispatch(getCarMileageById(id)).then((result) => {
      if (result.payload) {
        setCarMileage(result.payload);
        setLoading(false);
      }
    });
    
    dispatch(getExpensePeriodForUi()).then(result => {
      if(result.payload) {
        setExpensePeriods(result.payload);
      }
    })
  }, []);

  const initialValues = {
    expense_period: carMileage?.expense_period || "",
    start_mileage: carMileage?.start_mileage || "",
    end_mileage: carMileage?.end_mileage || "",
    start_mileage_image: null,
    end_mileage_image: null,
    start_mileage_image_old: carMileage?.start_mileage_image,
    end_mileage_image_old: carMileage?.end_mileage_image,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(editCarMileage({ data: formData, id })).then((result) => {
      if (result.payload) {
        resetForm();
        navigate("/mileage?tab=car-mileage");
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <Form>
                <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
                  <h1 className="mb-[30px] text-[20px]">Edit Car Mileage</h1>
                  <div className="grid grid-cols-5 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                      Expense Period
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                      Start Mileage Reading
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      End Mileage Reading
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Upload Start Mileage Image
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Upload End Mileage Image
                    </div>
                </div>
                <div className="grid grid-cols-5 gap-[20px] mb-[20px]">
                  <div className="mb-2">
                    <div className="relative">
                      <Field as="select" 
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`expense_period`} 
                        name={`expense_period`}>
                        <option value="">Select an expense period</option>
                        {expensePeriods.map(period => (
                          <option key={period.id} value={period.id}>
                            {`${formatDateToDDMMYYYY(period.start_date)} / ${formatDateToDDMMYYYY(period.end_date)}`}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                      <ErrorMessage name={`expense_period`} />
                    </div>
                  </div>

                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`start_mileage`}
                          name={`start_mileage`}
                          type="number"
                          step="1"
                          min="1"
                        />
                        <ErrorMessage name={`start_mileage`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`end_mileage`}
                          name={`end_mileage`}
                          type="number"
                          step="1"
                          min="1"
                        />
                        <ErrorMessage name={`end_mileage`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <input
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        key={Date.now()}
                        type="file"
                        name="start_mileage_image"
                        id="start_mileage_image"
                        accept="image/*"
                        capture="environment"
                        onChange={(e) => {
                          const file = e.currentTarget.files[0];
                          setFieldValue("start_mileage_image", file);
                          setStartMileageFileName(file.name);
                        }}
                      />
                      {values.start_mileage_image_old && (
                        <div
                          className="bg-blue-100 rounded-lg p-2 cursor-pointer"
                          onClick={() => {
                            viewFile(values.start_mileage_image_old);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            size="lg"
                            style={{ color: "#397bd0" }}
                          />
                        </div>
                      )}
                      {startMileageFileName && (
                        <span className="text-gray-600 mt-2 block">
                          {startMileageFileName}
                        </span>
                      )}
                      <ErrorMessage name={`start_mileage_image`} />
                    </div>
                    <div className="mb-2">
                      <input
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        key={Date.now()}
                        type="file"
                        name="end_mileage_image"
                        id="end_mileage_image"
                        accept="image/*"
                        capture="environment"
                        onChange={(e) => {
                          const file = e.currentTarget.files[0];
                          setFieldValue("end_mileage_image", file);
                          setEndMileageFileName(file.name);
                        }}
                      />
                      {values.end_mileage_image_old && (
                        <div
                          className="bg-blue-100 rounded-lg p-2 cursor-pointer"
                          onClick={() => {
                            viewFile(values.end_mileage_image_old);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            size="lg"
                            style={{ color: "#397bd0" }}
                          />
                        </div>
                      )}
                      {endMileageFileName && (
                        <span className="text-gray-600 mt-2 block">
                          {endMileageFileName}
                        </span>
                      )}
                      <ErrorMessage name={`end_mileage_image`} />
                    </div>
                  </div>
                </div>

                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                        isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
                {isOpen && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-[800px] w-full h-[600px]">
                      <div className="flex justify-between items-center">
                        <button onClick={handleCloseModal} className="text-xl">
                          &times;
                        </button>
                      </div>
                      {viewPdf && (
                        <iframe
                          src={viewPdf}
                          className="w-full h-[500px] mt-4 border"
                          title="PDF Viewer"
                        />
                      )}
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditCarMileage;
