import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listTakeOffItems = createAsyncThunk(
  "listTakeOffItems",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/takeoff/items?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getTakeOffItemsForUi = createAsyncThunk(
  "getTakeOffItemsForUi",
  async () => {
    const request = await api(
      `api/admin/takeoff/getalltakeoffitems`,
      "",
      "get"
    );
    return request;
  }
);

export const getTakeOffItemById = createAsyncThunk(
  "getTakeOffItemById",
  async (data) => {
    const request = await api(`api/admin/takeoff/item/${data}`, "", "get");
    return request;
  }
);

export const addTakeOffItem = createAsyncThunk(
  "addTakeOffItem",
  async (data) => {
    const request = await api("api/admin/takeoff/item", data, "post");
    return request;
  }
);

export const updateTakeOffItem = createAsyncThunk(
  "updateTakeOffItem",
  async ({ data, id }) => {
    const request = await api(`api/admin/takeoff/item/${id}`, data, "post");
    return request;
  }
);

export const deleteTakeOffItem = createAsyncThunk(
  "deleteTakeOffItem",
  async (id) => {
    const request = await api(`api/admin/takeoff/item/${id}`, {}, "delete");
    return request;
  }
);

export const getTakeOffOptions = createAsyncThunk(
  "getTakeOffOptions",
  async (data) => {
    const request = await api(`api/admin/takeoff/options/${data}`, "", "get");
    return request;
  }
);

export const deleteTakeOffOption = createAsyncThunk(
  "deleteTakeOffOption",
  async (id) => {
    const request = await api(`api/admin/takeoff/options/${id}`, {}, "delete");
    return request;
  }
);


export const updateTakeOffOptions = createAsyncThunk(
  "updateTakeOffOptions",
  async ({ data, takeoff_item_id }) => {
    const request = await api(`api/admin/takeoff/options/${takeoff_item_id}`, data, "post");
    return request;
  }
);

const takeOffItemSlice = createSlice({
  name: "takeOffItems",
  initialState: {
    loading: false,
    items: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listTakeOffItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listTakeOffItems.fulfilled, (state, action) => {
        state.items = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listTakeOffItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getTakeOffItemsForUi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTakeOffItemsForUi.fulfilled, (state, action) => {
        state.items = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getTakeOffItemsForUi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addTakeOffItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTakeOffItem.fulfilled, (state, action) => {
        state.loading = false;
        state.items.push(action.payload); // Add the new user to the users array
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(addTakeOffItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateTakeOffItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTakeOffItem.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.items = state.items.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(updateTakeOffItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTakeOffItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTakeOffItem.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteTakeOffItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getTakeOffOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTakeOffOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getTakeOffOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTakeOffOption.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTakeOffOption.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteTakeOffOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateTakeOffOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTakeOffOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateTakeOffOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default takeOffItemSlice.reducer;
