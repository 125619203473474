import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunityByCustomerId } from "../../store/reducer/opportunity/opportunitySlice";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { addBusinessMileage } from "../../store/reducer/businessMileage/businessMileageSlice";

function AddBusinessMileage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {} = useSelector((state) => state.ddOptions);

  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [journeyOpportunityData, setJourneyOpportunityData] = useState({});

  useEffect(() => {
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
  }, []);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    journeys: Yup.array()
      .of(
        Yup.object().shape({
          opportunity_id: Yup.number().required("please select opportunity"),
          customer_id: Yup.number().required("Please select customer"),
          date: Yup.date().required("Please select a date"),
          reason: Yup.string().required("Please enter a reason"),
          miles: Yup.number()
            .typeError("Miles must be a number")
            .required("Please enter miles")
            .integer("Miles must be a whole number")
            .positive("Miles must be greater than zero"),
        })
      )
      .required("Must have option name"),
  });

  const initialValues = {
    journeys: [
      {
        date: "",
        customer_id: "",
        opportunity_id: "",
        reason: "",
        miles: "",
      },
    ],
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "journeys") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });
    await dispatch(addBusinessMileage(formData)).then((result) => {
      if (result.payload) {
        resetForm();
        navigate("/mileage?tab=business-mileage");
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <Form>
                <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
                  <h1 className="mb-[30px] text-[20px]">
                    Add Business Mileage
                  </h1>
                  <div className="grid grid-cols-5 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                      Date
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Customer
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Opportunity
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Reason
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Miles
                    </div>
                  </div>
                  <FieldArray
                    name="journeys"
                    render={({ insert, remove, push }) => (
                      <>
                        <div>
                          {values.journeys.map((journey, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-5 gap-[20px] mb-[20px]"
                              data-id={index}
                            >
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`journeys[${index}].date`}
                                    name={`journeys[${index}].date`}
                                    type="date"
                                  />
                                  <ErrorMessage
                                    name={`journeys[${index}].date`}
                                  />
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`journeys[${index}].customer_id`}
                                    name={`journeys[${index}].customer_id`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `journeys[${index}].customer_id`,
                                        e.target.value
                                      );
                                      dispatch(
                                        getOpportunityByCustomerId({
                                          customer_id: e.target.value,
                                        })
                                      ).then((result) => {
                                        if (result.payload) {
                                          setJourneyOpportunityData(
                                            (prevState) => {
                                              const newState = { ...prevState };

                                              newState[index] = result.payload;
                                              return newState;
                                            }
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    <option value={""}>Select Customer</option>
                                    {customerData.map((customer) => (
                                      <option
                                        value={customer.id}
                                        key={customer.id}
                                      >
                                        {customer.customer_name}
                                      </option>
                                    ))}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                  <ErrorMessage
                                    name={`journeys[${index}].customer_id`}
                                  />
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`journeys[${index}].opportunity_id`}
                                    name={`journeys[${index}].opportunity_id`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `journeys[${index}].opportunity_id`,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value={""}>
                                      Select Opportunity
                                    </option>
                                    {journeyOpportunityData[index]?.map(
                                      (opportunity) => (
                                        <option
                                          value={opportunity.id}
                                          key={opportunity.id}
                                        >
                                          {opportunity.opportunity_name}
                                        </option>
                                      )
                                    )}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                  <ErrorMessage
                                    name={`journeys[${index}].opportunity_id`}
                                  />
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`journeys[${index}].reason`}
                                    name={`journeys[${index}].reason`}
                                    type="text"
                                  />
                                  <ErrorMessage
                                    name={`journeys[${index}].reason`}
                                  />
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`journeys[${index}].miles`}
                                    name={`journeys[${index}].miles`}
                                    type="number"
                                    step="1"
                                    min="1"
                                  />
                                  <ErrorMessage
                                    name={`journeys[${index}].miles`}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="text-right p-[16px]">
                          <span
                            className="cursor-pointer text-[#55A14A]"
                            onClick={() =>
                              push({
                                date: "",
                                customer_id: "",
                                opportunity_id: "",
                                reason: "",
                                miles: "",
                              })
                            }
                          >
                            + Add New
                          </span>
                        </div>
                      </>
                    )}
                  />
                </div>

                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                        isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default AddBusinessMileage;
