import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../../store/reducer/ddOption/ddOptionSlice";

import {
  getTakeOffItemById,
  updateTakeOffItem,
} from "../../../store/reducer/takeOffItems/takeOffItemSlice";

function EditTakeOffItem() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getDdOptionForUI());

    dispatch(getTakeOffItemById(id)).then((result) => {
      if (result.payload) {
        setItemData(result.payload);
        setLoading(false);
      }
    });
  }, []);

  const validationSchema = Yup.object().shape({
    item_name: Yup.string().required("please enter item name"),
  });

  const initialValues = {
    item_name: itemData?.item_name || "",
    item_description: itemData?.item_description || "",
    group_id: itemData?.group_id || "",
    sort_by: itemData?.sort_by || 0,
    is_default: itemData?.is_default || false,
    status: itemData?.status || "Inactive",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateTakeOffItem({ data: formData, id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        navigate("/setting/takeoff/item");
      } else {
        toast.error(result.error.message);
      }
    });
  };

  if (!loading) {
    return (
      <>
        <div className="flex justify-between mb-[30px]">
          <div className="flex gap-[16px] items-center">
            <Link to="/setting/takeoff/item">
              <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
                <img
                  className="w-[24px]"
                  src={process.env.PUBLIC_URL + "/icons/back.png"}
                  alt="back icon"
                />
              </div>
            </Link>
            <div className="flex flex-col">
              <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
                Item / Edit Item
              </span>
              <span className="font-Inter font-[700] leading-[30px] text-[24px]">
                Edit Item
              </span>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="flex gap-[20px]">
                <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="item_name"
                    >
                      Item Name
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="item_name"
                      name="item_name"
                      onChange={(e) => {
                        setFieldValue("item_name", e.target.value);
                      }}
                    />
                    {errors.item_name && touched.item_name ? (
                      <div className="text-red-700">{errors.item_name}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="item_description"
                    >
                      Description
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="item_description"
                      name="item_description"
                      onChange={(e) => {
                        setFieldValue("item_description", e.target.value);
                      }}
                    />
                    {errors.item_description && touched.item_description ? (
                      <div className="text-red-700">
                        {errors.item_description}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="uom"
                    >
                      Group
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="group_id"
                        name="group_id"
                        onChange={(e) => {
                          setFieldValue("group_id", e.target.value);
                        }}
                      >
                        <option value={""}>Select group</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "TakeOffItems" &&
                              option.field_name === "Group"
                          )
                          .map((option) => (
                            <option value={option.id}>
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.group_id && touched.group_id ? (
                      <div className="text-red-700">{errors.group_id}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="sort_by"
                    >
                      Sort By
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="number"
                      id="sort_by"
                      name="sort_by"
                      onChange={(e) => {
                        setFieldValue("sort_by", e.target.value);
                      }}
                    />
                    {errors.sort_by && touched.sort_by ? (
                      <div className="text-red-700">{errors.sort_by}</div>
                    ) : null}
                  </div>
                  <div className="flex gap-[30px]">
                    <div>
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="is_default"
                      >
                        Is Default
                      </label>
                      <div>
                        <label
                          htmlFor="is_default"
                          className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                        >
                          <input
                            id="is_default"
                            type="checkbox"
                            className="hidden peer"
                            name="is_default"
                            defaultChecked={values.is_default}
                            onChange={(e) => {
                              const checkedValue = e.target.checked
                                ? true
                                : false;
                              setFieldValue("is_default", checkedValue);
                            }}
                          />
                          <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                            No
                          </span>
                          <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                            Yes
                          </span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="status"
                      >
                        Status
                      </label>
                      <div>
                        <label
                          htmlFor="status"
                          className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                        >
                          <input
                            id="status"
                            type="checkbox"
                            className="hidden peer"
                            name="status"
                            defaultChecked={values.status === "Active"}
                            onChange={(e) => {
                              const checkedValue = e.target.checked
                                ? "Active"
                                : "Inactive";
                              setFieldValue("status", checkedValue);
                            }}
                          />
                          <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                            Inactive
                          </span>
                          <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                            Active
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default EditTakeOffItem;
