import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getSourceAnalysisReport = createAsyncThunk(
  "getSourceAnalysisReport",
  async ({ search = "", page = 1, limit = 100 }) => {
    const request = await api(
      `api/admin/reports/source_analysis?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const searchSourceAnalysisReport = createAsyncThunk(
  "searchSourceAnalysisReport",
  async ({ expected_start_date, expected_close_date, lead_source }) => {
    const request = await api(
      `api/admin/report/source_analysis/search?expected_start_date=${expected_start_date}&expected_close_date=${expected_close_date}&lead_source=${lead_source}`,
      "",
      "get"
    );
    return request;
  }
);

const sourceAnalysisReportSlice = createSlice({
  name: "sourceAnalysisReport",
  initialState: {
    loading: false,
    data: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSourceAnalysisReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSourceAnalysisReport.fulfilled, (state, action) => {
        state.data = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSourceAnalysisReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchSourceAnalysisReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchSourceAnalysisReport.fulfilled, (state, action) => {
        state.data = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(searchSourceAnalysisReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default sourceAnalysisReportSlice.reducer;
