import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const generateInstall = createAsyncThunk(
  "generateInstall",
  async (data) => {
    const request = await api(`api/admin/generateinstall/${data}`, "", "get");
    return request;
  }
);

export const getInstallByDrawingId = createAsyncThunk(
  "getInstallByDrawingId",
  async (data) => {
    const request = await api(`api/admin/install/${data}`, "", "get");
    return request;
  }
);

export const getGenerateInstallStatus = createAsyncThunk(
  "getGenerateInstallStatus",
  async (data) => {
    const request = await api(
      `api/admin/generateinstallstatus/${data}`,
      "",
      "get"
    );
    return request;
  }
);

export const updateDrawingInstall = createAsyncThunk(
  "updateDrawingInstall",
  async ({ data, drawing_id }) => {
    const request = await api(`api/admin/install/${drawing_id}`, data, "post");
    return request;
  }
);

export const deleteInstallItem = createAsyncThunk(
  "deleteInstallItem",
  async ({ id }) => {
    const request = await api(`api/admin/install/${id}`, {}, "delete");
    return request;
  }
);

const drawingInstallSlice = createSlice({
  name: "drawingInstall",
  initialState: {
    loading: false,
    install: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(generateInstall.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateInstall.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(generateInstall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDrawingInstall.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDrawingInstall.fulfilled, (state, action) => {
        state.loading = false;
        state.install = state.install.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateDrawingInstall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteInstallItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteInstallItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteInstallItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default drawingInstallSlice.reducer;
