import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listBusinessTimeEntry = createAsyncThunk(
  "listBusinessTimeEntry",
  async ({ startDate, endDate, user }) => {
    let url = "api/admin/business-time-entries";
    const queryParams = [];
    if (startDate) {
      queryParams.push(`startDate=${encodeURIComponent(startDate)}`);
    }
    if (endDate) {
      queryParams.push(`endDate=${encodeURIComponent(endDate)}`);
    }
    if (user) {
      queryParams.push(`user=${encodeURIComponent(user)}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }
    const request = await api(url, "", "get");
    return request;
  }
);

export const getBusinessTimeEntryById = createAsyncThunk(
  "getBusinessTimeEntryById",
  async (id) => {
    const request = await api(`api/admin/business-time-entries/${id}`, "", "get");
    return request;
  }
);

export const getBusinessTimeEntryByOpportunity = createAsyncThunk(
  "getBusinessTimeEntryByOpportunity",
  async (opportunity_id) => {
    const request = await api(`api/admin/business-time-entries/opportunity/${opportunity_id}`, "", "get");
    return request;
  }
);

export const addBusinessTimeEntry = createAsyncThunk(
  "addBusinessTimeEntry",
  async (data) => {
    const request = await api("api/admin/business-time-entries", data, "post");
    return request;
  }
);

export const editBusinessTimeEntry = createAsyncThunk(
  "editBusinessTimeEntry",
  async ({ data, id }) => {
    const request = await api(`api/admin/business-time-entries/${id}`, data, "post");
    return request;
  }
);

export const deleteBusinessTimeEntry = createAsyncThunk(
  "deleteBusinessTimeEntry",
  async (id) => {
    const request = await api(`api/admin/business-time-entries/delete/${id}`, "", "get");
    return request;
  }
);


const businessTimeEntrySlice = createSlice({
  name: "businessTimeEntry",
  initialState: {
    loading: false,
    timeEntries: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listBusinessTimeEntry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listBusinessTimeEntry.fulfilled, (state, action) => {
        state.timeEntries = action?.payload?.rows;
        state.loading = false;
        state.error = null;
      })
      .addCase(listBusinessTimeEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addBusinessTimeEntry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBusinessTimeEntry.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addBusinessTimeEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteBusinessTimeEntry.fulfilled, (state, action) => {
        state.loading = false;
        state.timeEntries = state.timeEntries.filter(entry => {
          return entry.id != action.payload.entry?.id
        })
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteBusinessTimeEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editBusinessTimeEntry.fulfilled, (state, action) => {
        state.loading = false;
        state.timeEntries = [];
        state.error = null;
      })
      .addCase(editBusinessTimeEntry.rejected, (state, action) => {
        state.loading = false;
        state.timeEntries = [];
        state.error = action.error.message;
      })
  },
});
export default businessTimeEntrySlice.reducer;
