import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listDdOptions = createAsyncThunk(
  "listDdOptions",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/ddoptions?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getDdOptionById = createAsyncThunk(
  "getDdOptionById",
  async (data) => {
    const request = await api(`api/admin/ddoption/${data}`, "", "get");
    return request;
  }
);

export const addDdOption = createAsyncThunk("addDdOption", async (data) => {
  const request = await api("api/admin/ddoption", data, "post");
  return request;
});

export const updateDdOption = createAsyncThunk(
  "updateDdOption",
  async ({ data, id }) => {
    const request = await api(`api/admin/ddoption/${id}`, data, "post");
    return request;
  }
);

export const getDdOptionForUI = createAsyncThunk(
  "getDdOptionForUI",
  async () => {
    const request = await api(`api/admin/ddoptionforui`, "", "get");
    return request;
  }
);

export const deleteDdOption = createAsyncThunk("deleteDdOption", async (id) => {
  const request = await api(`api/admin/ddoption/${id}`, {}, "delete");
  return request;
});

const ddOptionSlice = createSlice({
  name: "ddOptions",
  initialState: {
    loading: false,
    options: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listDdOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listDdOptions.fulfilled, (state, action) => {
        state.options = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listDdOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addDdOption.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDdOption.fulfilled, (state, action) => {
        state.loading = false;
        state.options.push(action.payload); // Add the new user to the users array
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(addDdOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDdOption.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDdOption.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.options = state.options.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(updateDdOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDdOptionForUI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDdOptionForUI.fulfilled, (state, action) => {
        state.options = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDdOptionForUI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteDdOption.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDdOption.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteDdOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default ddOptionSlice.reducer;
