import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../../store/reducer/ddOption/ddOptionSlice";
import { getCountryForUi } from "../../../store/reducer/country/countrySlice";
import { addSupplier } from "../../../store/reducer/supplier/supplierSlice";

function AddSupplier() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("please enter company name"),
    phone: Yup.string().required("please enter phone"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter email"),
    billing_company: Yup.string().required("please enter billing company"),
  });

  const { options = [] } = useSelector((state) => state.ddOptions);
  const { countries = [] } = useSelector((state) => state.countries);

  useEffect(() => {
    dispatch(getDdOptionForUI());
    dispatch(getCountryForUi());
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addSupplier(formData)).then((result) => {
      if (result.payload) {
        resetForm();
        navigate(`/setting/supplier/${result.payload.result.id}`);
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/setting/supplier">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Supplier / Add
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Add Supplier
            </span>
          </div>
        </div>
      </div>
      <Formik
        initialValues={{
          company: "",
          billing_company: "",
          phone: "",
          email: "",
          currency: "",
          vat: "",
          supplier_vat: "",
          supplier_group: "",
          status: "Active",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <div className="flex gap-[20px]">
              <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="company"
                  >
                    Company
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="company"
                    name="company"
                    onChange={(e) => {
                      setFieldValue("company", e.target.value);
                    }}
                  />
                  {errors.company && touched.company ? (
                    <div className="text-red-700">{errors.company}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="billing_company"
                  >
                    Billing Company
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="billing_company"
                    name="billing_company"
                    onChange={(e) => {
                      setFieldValue("billing_company", e.target.value);
                    }}
                  />
                  {errors.billing_company && touched.billing_company ? (
                    <div className="text-red-700">{errors.billing_company}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="phone"
                  >
                    Phone
                  </label>

                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="phone"
                    name="phone"
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value);
                    }}
                  />
                  {errors.phone && touched.phone ? (
                    <div className="text-red-700">{errors.phone}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="email"
                    name="email"
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />
                  {errors.email && touched.email ? (
                    <div className="text-red-700">{errors.email}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="currency"
                  >
                    Currency
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="currency"
                      name="currency"
                      onChange={(e) => {
                        setFieldValue("currency", e.target.value);
                      }}
                    >
                      <option value={""}>Select currency</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Finance" &&
                            option.field_name === "Currency"
                        )
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            selected={option.is_default ? true : false}
                          >
                            {option.field_code} {option.field_value}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.currency && touched.currency ? (
                    <div className="text-red-700">{errors.currency}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="vat"
                  >
                    Vat
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="vat"
                      name="vat"
                      onChange={(e) => {
                        setFieldValue("vat", e.target.value);
                      }}
                    >
                      <option value={""}>Select vat</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Finance" &&
                            option.field_name === "Tax_Rate"
                        )
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            selected={option.is_default ? true : false}
                          >
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.vat && touched.vat ? (
                    <div className="text-red-700">{errors.vat}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="supplier_vat"
                  >
                    Supplier Vat Number
                  </label>
                  <Field
                    type="text"
                    name="supplier_vat"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setFieldValue("supplier_vat", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="vat"
                  >
                    Supplier Group
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="supplier_group"
                      name="supplier_group"
                      onChange={(e) => {
                        setFieldValue("supplier_group", e.target.value);
                      }}
                    >
                      <option value={""}>Select supplier group</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Supplier" &&
                            option.field_name === "Group"
                        )
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            selected={option.is_default ? true : false}
                          >
                            {option.field_code} {option.field_value}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="status"
                  >
                    Status
                  </label>
                  <div>
                    <label
                      htmlFor="status"
                      className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                    >
                      <input
                        id="status"
                        type="checkbox"
                        className="hidden peer"
                        name="status"
                        // defaultChecked
                        onChange={(e) => {
                          const checkedValue = e.target.checked
                            ? "Active"
                            : "Inactive";
                          setFieldValue("status", checkedValue);
                        }}
                      />
                      <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                        Inactive
                      </span>
                      <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                        Active
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
              <div>
                <button
                  type="submit"
                  name="submit"
                  className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddSupplier;
