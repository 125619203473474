import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getAdditionalOptionByDrawingId = createAsyncThunk(
  "getAdditionalOptionByDrawingId",
  async (data) => {
    const request = await api(
      `api/admin/additional-options/${data}`,
      "",
      "get"
    );
    return request;
  }
);

export const getAdditionalOptionItems = createAsyncThunk(
  "getAdditionalOptionItems",
  async (data) => {
    const request = await api(
      `api/admin/additional-options-items`,
      "",
      "get"
    );
    return request;
  }
);

export const updateDrawingAdditionalOption = createAsyncThunk(
  "updateDrawingAdditionalOption",
  async ({ data, id }) => {
    const request = await api(
      `api/admin/additional-option/${id}`,
      data,
      "post"
    );
    return request;
  }
);

export const deleteAdditionalOption = createAsyncThunk(
  "deleteAdditionalOption",
  async ({ id }) => {
    const request = await api(
      `api/admin/additional-option/${id}`,
      {},
      "delete"
    );
    return request;
  }
);

const drawingAdditionalOptionSlice = createSlice({
  name: "drawingAdditionalOption",
  initialState: {
    loading: false,
    addOptions: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAdditionalOptionByDrawingId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdditionalOptionByDrawingId.fulfilled, (state, action) => {
        state.addOptions = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAdditionalOptionByDrawingId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // .addCase(searchOpportunities.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(searchOpportunities.fulfilled, (state, action) => {
      //   state.opportunities = action?.payload?.rows;
      //   state.totalCount = action?.payload.count;
      //   state.totalEstimatedValue = action?.payload.estimated_value;
      //   state.loading = false;
      //   state.error = null;
      // })
      // .addCase(searchOpportunities.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(addDrawing.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(addDrawing.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.drawings = action.payload;
      //   state.error = null;
      // })
      // .addCase(addDrawing.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      .addCase(updateDrawingAdditionalOption.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDrawingAdditionalOption.fulfilled, (state, action) => {
        state.loading = false;
        state.addOptions = state.addOptions.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateDrawingAdditionalOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteAdditionalOption.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAdditionalOption.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteAdditionalOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default drawingAdditionalOptionSlice.reducer;
