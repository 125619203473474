import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import {
  deleteItemPrice,
  getItemPricesForExport,
  listItemPrices,
} from "../../../store/reducer/itemPrices/itemPriceSlice";
import { CSVLink } from "react-csv";

function formatDateToDDMMYYYY(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year from the date
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Format date to dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

function ListItemPrice() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    loading,
    prices = [],
    totalCount,
  } = useSelector((state) => state.itemPrices);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [itemPriceExportData, setItemPriceExportData] = useState([]);

  useEffect(() => {
    dispatch(listItemPrices({ search, page, limit }));
    dispatch(getItemPricesForExport()).then(result => {
      if(result.payload) {
        setItemPriceExportData(result.payload)
      }
    });
  }, []);

  const handleSearch = (search = "") => {
    dispatch(listItemPrices({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [prices]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listItemPrices({ search, page, limit }));
  };

  const handleDelete = async (id) => {
    await dispatch(deleteItemPrice(id)).then((result) => {
      if (result.payload) {
        dispatch(listItemPrices({ search, page, limit }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="block md:flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to="/setting">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Item / List Item Prices
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List Prices
            </span>
          </div>
        </div>
        <div className="">
          <Link to="/setting/item-price/add">
            <div className="py-[10px] px-[40px] bg-[#F8F8F8] font-[500] font-Inter text-[18px] flex justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B] w-max">
              <span>Add Price</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="">
          <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="md:flex justify-between items-center p-[12px]">
                <div className="leading-[30px] font-Mulish font-[700] text-[24px] mb-[10px] md:mb-0 flex gap-[20px] items-center">
                  <span className="">Item Price List</span>
                  {!loading && itemPriceExportData.length > 0 && (
                    <CSVLink data={itemPriceExportData} filename="item_prices.csv">
                      <button className="px-[10px] font-[400] text-[12px] justify-center text-white bg-[#929292] w-full rounded-[12px]">
                        Export CSV
                      </button>
                    </CSVLink>
                  )}
                </div>
                <div className="relative">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/search.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="sm:rounded-lg mt-5 overflow-scroll">
                <table className="w-full text-center">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Supplier
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Item
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                  ) : (
                    <tbody>
                      {prices.map((price) => (
                        <tr key={price.id} className="text-center">
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                            {price.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {price.supplier}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {price.item_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {Number(price.unit_price).toFixed(2)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {formatDateToDDMMYYYY(price.start_date)}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap font-medium">
                            <div className="flex gap-[10px] justify-center">
                              <div>
                                <Link to={`/setting/item-price/${price.id}`}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-teal-100 rounded-lg"
                                  />
                                </Link>
                              </div>
                              <div>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleDelete(price.id)}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/icons/delete.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-red-100 rounded-lg"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center"
          />
        </div>
      </div>
    </>
  );
}

export default ListItemPrice;
