import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCountryForUi } from "../../../store/reducer/country/countrySlice";
import { getSupplierById } from "../../../store/reducer/supplier/supplierSlice";
import {
  getSupplierAddressBySupplierId,
  updateSupplierAddress,
} from "../../../store/reducer/supplier/supplierAddressSlice";

function EditBillingShipping() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [billingData, setBillingData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getSupplierAddressBySupplierId(id)).then((result) => {
      if (result.payload) {
        setBillingData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    dispatch(getSupplierById(id)).then((result) => {
      if (result.payload) {
        setSupplierData(result.payload);
        // setLoading(false);
      }
    });
    dispatch(getCountryForUi());
  }, []);

  const initialValues = {
    billing_street: billingData?.billing_street || "",
    billing_city: billingData?.billing_city || "",
    billing_state: billingData?.billing_state || "",
    billing_post_code: billingData?.billing_post_code || "",
    billing_country: billingData?.billing_country || "",
    sameAsBilling: billingData?.same_as_billing || "",
    shipping_street: billingData?.shipping_street || "",
    shipping_city: billingData?.shipping_city || "",
    shipping_state: billingData?.shipping_state || "",
    shipping_post_code: billingData?.shipping_post_code || "",
    shipping_country: billingData?.shipping_country || "",
  };

  const { countries = [] } = useSelector((state) => state.countries);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    values.supplier_id = supplierData.id;
    values.supplier_uniqueId = supplierData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    if (values.sameAsBilling) {
      values.shipping_street = values.billing_street;
      values.shipping_city = values.billing_city;
      values.shipping_state = values.billing_state;
      values.shipping_post_code = values.billing_post_code;
      values.shipping_country = values.billing_country;
    }

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateSupplierAddress({ data: formData, id })).then(
      (result) => {
        if (result.payload) {
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };
  if (!loading) {
    return (
      <>
        <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10">
          <Link to={`/setting/supplier/${id}`}>
            <div
              className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
                location.pathname === `/setting/supplier/${id}`
                  ? "text-white bg-[#55A14A]"
                  : "text-black hover:bg-[#55A14A] hover:text-white"
              }`}
            >
              Supplier Detail
            </div>
          </Link>
          <Link to={`/setting/supplier/${id}/billing`}>
            <div
              className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
                location.pathname === `/setting/supplier/${id}/billing`
                  ? "text-white bg-[#55A14A]"
                  : "text-black hover:bg-[#55A14A] hover:text-white"
              }`}
            >
              Billing & Shipping
            </div>
          </Link>
          <Link to={`/setting/supplier/${id}/contact`}>
            <div
              className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
                location.pathname === `/setting/supplier/${id}/contact`
                  ? "text-white bg-[#55A14A]"
                  : "text-black hover:bg-[#55A14A] hover:text-white"
              }`}
            >
              Contacts
            </div>
          </Link>
        </div>
        <div className="block md:grid grid-cols-3 mb-[30px]">
          <div className="flex gap-[16px] items-center mb-[8px] md:mb-0">
            <Link to="/setting/supplier">
              <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
                <img
                  className="w-[24px]"
                  src={process.env.PUBLIC_URL + "/icons/back.png"}
                  alt="back icon"
                />
              </div>
            </Link>
            <div className="flex flex-col">
              <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
                Supplier / Edit
              </span>
              <span className="font-Inter font-[700] leading-[30px] text-[24px]">
                Edit Billing & Shipping Address
              </span>
            </div>
          </div>
          <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
            {supplierData.id}-{supplierData.company}
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="block md:flex gap-[20px]">
                <div className="md:grid md:grid-cols-1 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full mb-[16px] md:mb-0 md:w-[50%]">
                  <div className="text-xl font-[500]">Billing Address</div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="billing_street"
                    >
                      Street
                    </label>
                    <Field
                      name="billing_street"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("billing_street", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="billing_city"
                    >
                      City
                    </label>
                    <Field
                      name="billing_city"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("billing_city", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="billing_state"
                    >
                      State
                    </label>
                    <Field
                      name="billing_state"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("billing_state", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="billing_post_code"
                    >
                      Post Code
                    </label>
                    <Field
                      name="billing_post_code"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("billing_post_code", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="billing_country"
                    >
                      Country
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="billing_country"
                        name="billing_country"
                        onChange={(e) => {
                          setFieldValue("billing_country", e.target.value);
                        }}
                      >
                        <option value={""}>Select country</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.short_name}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-1 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full md:w-[50%]">
                  <div className="flex justify-between">
                    <div className="text-xl font-[500]">Shipping Address</div>
                    <div>
                      <div className="flex items-center mb-4">
                        <input
                          id="sameAsBilling"
                          type="checkbox"
                          checked={values.sameAsBilling}
                          onChange={(e) =>
                            setFieldValue("sameAsBilling", e.target.checked)
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="default-checkbox"
                          className="ms-2 text-sm font-medium text-black"
                        >
                          Same as billing address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="shipping_street"
                    >
                      Street
                    </label>
                    <Field
                      name="shipping_street"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("shipping_street", e.target.value);
                      }}
                      disabled={values.sameAsBilling}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="shipping_city"
                    >
                      City
                    </label>
                    <Field
                      name="shipping_city"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("shipping_city", e.target.value);
                      }}
                      disabled={values.sameAsBilling}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="shipping_state"
                    >
                      State
                    </label>
                    <Field
                      name="shipping_state"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("shipping_state", e.target.value);
                      }}
                      disabled={values.sameAsBilling}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="shipping_post_code"
                    >
                      Post Code
                    </label>
                    <Field
                      name="shipping_post_code"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("shipping_post_code", e.target.value);
                      }}
                      disabled={values.sameAsBilling}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="shipping_country"
                    >
                      Country
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="shipping_country"
                        name="shipping_country"
                        onChange={(e) => {
                          setFieldValue("shipping_country", e.target.value);
                        }}
                        disabled={values.sameAsBilling}
                      >
                        <option value={""}>Select country</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.short_name}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default EditBillingShipping;
