import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listCarMileage = createAsyncThunk(
  "listCarMileage",
  async ({ month, user }) => {
    let url = "api/admin/car-mileage";
    const queryParams = [];
    if (month) {
      queryParams.push(`month=${encodeURIComponent(month)}`);
    }
    if (user) {
      queryParams.push(`user=${encodeURIComponent(user)}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }
    const request = await api(url, "", "get");
    return request;
  }
);

export const getCarMileageById = createAsyncThunk(
  "getCarMileageById",
  async (id) => {
    const request = await api(`api/admin/car-mileage/${id}`, "", "get");
    return request;
  }
);

export const addCarMileage = createAsyncThunk(
  "addCarMileage",
  async (data) => {
    const request = await api("api/admin/car-mileage", data, "postFile");
    return request;
  }
);

export const editCarMileage = createAsyncThunk(
  "editCarMileage",
  async ({ data, id }) => {
    const request = await api(`api/admin/car-mileage/${id}`, data, "postFile");
    return request;
  }
);

export const deleteCarMileage = createAsyncThunk(
  "deleteCarMileage",
  async (id) => {
    const request = await api(`api/admin/car-mileage/delete/${id}`, "", "get");
    return request;
  }
);


const carMileageSlice = createSlice({
  name: "carMileage",
  initialState: {
    loading: false,
    mileages: [],
    success: null,
    error: null, 
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listCarMileage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listCarMileage.fulfilled, (state, action) => {
        state.mileages = action?.payload?.rows;
        state.loading = false;
        state.error = null;
      })
      .addCase(listCarMileage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addCarMileage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCarMileage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addCarMileage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteCarMileage.fulfilled, (state, action) => {
        state.loading = false;
        state.mileages = state.mileages.filter(mileage => {
          return mileage.id != action.payload.mileage?.id
        })
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteCarMileage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editCarMileage.fulfilled, (state, action) => {
        state.loading = false;
        state.mileages = [];
        state.error = null;
      })
      .addCase(editCarMileage.rejected, (state, action) => {
        state.loading = false;
        state.mileages = [];
        state.error = action.error.message;
      })
  },
});
export default carMileageSlice.reducer;
