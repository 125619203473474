import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getItemsForUi } from "../../../store/reducer/items/itemSlice";
import { getSuppliersForUi } from "../../../store/reducer/supplier/supplierSlice";
import {
  getItemPriceById,
  updateItemPrice,
} from "../../../store/reducer/itemPrices/itemPriceSlice";
function EditItemPrice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemPriceData, setItemPriceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { suppliers = [] } = useSelector((state) => state.suppliers);
  const { items = [] } = useSelector((state) => state.items);

  useEffect(() => {
    dispatch(getItemPriceById(id)).then((result) => {
      if (result.payload) {
        setItemPriceData(result.payload);
        setLoading(false);
      }
    });
    dispatch(getSuppliersForUi());
    dispatch(getItemsForUi());
  }, []);

  const validationSchema = Yup.object().shape({
    supplier_id: Yup.string().required("please select supplier"),
    item_id: Yup.string().required("please select item"),
    unit_price: Yup.string().required("please enter unit price"),
    start_date: Yup.string().required("please select start date"),
  });

  const initialValues = {
    supplier_id: itemPriceData?.supplier_id || "",
    item_id: itemPriceData?.item_id || "",
    unit_price: itemPriceData?.unit_price || "",
    start_date: itemPriceData?.start_date || "",
    end_date: itemPriceData?.end_date || "",
    price_factor: itemPriceData?.price_factor || "",
    status: itemPriceData?.status || "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateItemPrice({ data: formData, id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        navigate("/setting/item-price");
      } else {
        toast.error(result.error.message);
      }
    });
  };

  if (!loading) {
    return (
      <>
        <div className="flex justify-between mb-[30px]">
          <div className="flex gap-[16px] items-center">
            <Link to="/setting/item-price">
              <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
                <img
                  className="w-[24px]"
                  src={process.env.PUBLIC_URL + "/icons/back.png"}
                  alt="back icon"
                />
              </div>
            </Link>
            <div className="flex flex-col">
              <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
                Item Price / Edit Price
              </span>
              <span className="font-Inter font-[700] leading-[30px] text-[24px]">
                Edit Item Price
              </span>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="flex gap-[20px]">
                <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="supplier_id"
                    >
                      Supplier
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="supplier_id"
                        name="supplier_id"
                        onChange={(e) => {
                          setFieldValue("supplier_id", e.target.value);
                        }}
                      >
                        <option value={""}>Select supplier</option>
                        {suppliers.map((supplier) => (
                          <option value={supplier.id}>
                            {supplier.company}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.supplier_id && touched.supplier_id ? (
                      <div className="text-red-700">{errors.supplier_id}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="item_id"
                    >
                      Item
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="item_id"
                        name="item_id"
                        onChange={(e) => {
                          setFieldValue("item_id", e.target.value);
                        }}
                      >
                        <option value={""}>Select item</option>
                        {items.map((item) => (
                          <option value={item.id}>
                            {item.item_description}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.item_id && touched.item_id ? (
                      <div className="text-red-700">{errors.item_id}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="unit_price"
                    >
                      Unit Price
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="unit_price"
                      name="unit_price"
                      onChange={(e) => {
                        setFieldValue("unit_price", e.target.value);
                      }}
                    />
                    {errors.unit_price && touched.unit_price ? (
                      <div className="text-red-700">{errors.unit_price}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="start_date"
                    >
                      Start Date
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      id="start_date"
                      name="start_date"
                      onChange={(e) => {
                        setFieldValue("start_date", e.target.value);
                      }}
                    />
                    {errors.start_date && touched.start_date ? (
                      <div className="text-red-700">{errors.start_date}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="end_date"
                    >
                      End Date
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      id="end_date"
                      name="end_date"
                      onChange={(e) => {
                        setFieldValue("end_date", e.target.value);
                      }}
                    />
                    {errors.end_date && touched.end_date ? (
                      <div className="text-red-700">{errors.end_date}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="price_factor"
                    >
                      Price Factor
                    </label>
                    <Field
                      type="text"
                      name="price_factor"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("price_factor", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <div>
                      <label
                        htmlFor="status"
                        className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                      >
                        <input
                          id="status"
                          type="checkbox"
                          className="hidden peer"
                          name="status"
                          defaultChecked={values.status === "Active"}
                          onChange={(e) => {
                            const checkedValue = e.target.checked
                              ? "Active"
                              : "Inactive";
                            setFieldValue("status", checkedValue);
                          }}
                        />
                        <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                          Inactive
                        </span>
                        <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                          Active
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default EditItemPrice;
