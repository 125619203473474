import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listOptionCosts = createAsyncThunk(
  "listOptionCosts",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/option_costs?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getOptionCostById = createAsyncThunk(
  "getOptionCostById",
  async (data) => {
    const request = await api(`api/admin/option_costs/${data}`, "", "get");
    return request;
  }
);

export const getOptionCostByOptionId = createAsyncThunk(
  "getOptionCostByOptionId",
  async (data) => {
    const request = await api(`api/admin/option_cost/${data}`, "", "get");
    return request;
  }
);

export const addOptionCosts = createAsyncThunk(
  "addOptionCosts",
  async (data) => {
    const request = await api("api/admin/option_costs", data, "post");
    return request;
  }
);

export const updateOptionCosts = createAsyncThunk(
  "updateOptionCosts",
  async ({ data, id }) => {
    const request = await api(`api/admin/option_costs/${id}`, data, "post");
    return request;
  }
);

export const getDdOptionForUI = createAsyncThunk(
  "getDdOptionForUI",
  async () => {
    const request = await api(`api/admin/ddoptionforui`, "", "get");
    return request;
  }
);

export const deleteOptionCosts = createAsyncThunk(
  "deleteOptionCosts",
  async (id) => {
    const request = await api(`api/admin/option_costs/${id}`, {}, "delete");
    return request;
  }
);

const optionCostsSlice = createSlice({
  name: "optionCosts",
  initialState: {
    loading: false,
    costs: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listOptionCosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listOptionCosts.fulfilled, (state, action) => {
        state.costs = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listOptionCosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addOptionCosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addOptionCosts.fulfilled, (state, action) => {
        state.loading = false;
        state.costs.push(action.payload); // Add the new user to the users array
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(addOptionCosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOptionCosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOptionCosts.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.costs = state.costs.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(updateOptionCosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOptionCosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOptionCosts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteOptionCosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default optionCostsSlice.reducer;
