import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getCustomerSitesByCustomerForUI } from "../../store/reducer/customers/customerSiteSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import { addOpportunity } from "../../store/reducer/opportunity/opportunitySlice";

function AddOpportunity() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required("please select customer"),
    site_id: Yup.string().required("please select site"),
    opportunity_name: Yup.string().required("please enter opportunity name"),
    sales_executive: Yup.string().required("please select sales executive"),
  });

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [customerData, setCustomerData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
    dispatch(getUserForUI()).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
  }, []);

  const getDefaultOption = (ui_name, field_name) => {
    const defaultOption = options.find((option) => {
      return (
        option.ui_name === ui_name &&
        option.field_name === field_name &&
        option.is_default === true
      );
    });
    return defaultOption ? defaultOption.id : "";
  };

  const initialValues = {
    customer_id: "",
    site_id: "",
    opportunity_name: "",
    sales_executive: JSON.parse(localStorage.getItem("userSession")).id,
    first_contact_date: moment().format("yyyy-MM-DD"),
    expected_close_date: moment()
      .add(getDefaultOption("Opportunity", "Expected_Close_Date"), "days")
      .format("yyyy-MM-DD"),
    last_contact_date: moment().format("yyyy-MM-DD"),
    next_contact_date: moment().format("yyyy-MM-DD"),
    estimated_value: 0,
    lead_source: getDefaultOption("Opportunity", "Lead_Source") || "",
    current_status_comment: "",
    job_type: "",
    progress_status: getDefaultOption("Opportunity", "Progress_Status") || "",
    win_probability: getDefaultOption("Opportunity", "Win_Probability") || "",
    status: "Active",
  };

  const getSiteByCustomerId = (customer_id) => {
    dispatch(getCustomerSitesByCustomerForUI({ customer_id })).then(
      (result) => {
        if (result.payload) {
          setSiteData(result.payload);
        }
      }
    );
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addOpportunity(formData)).then((result) => {
      if (result.payload) {
        resetForm();
        navigate(`/opportunity/${result.payload.result.id}`);
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/opportunity">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Opportunity / Add Opportunity
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Add Opportunity
            </span>
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6">
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="customer_id"
                >
                  Customer
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="customer_id"
                    name="customer_id"
                    onChange={(e) => {
                      setFieldValue("customer_id", e.target.value);
                      getSiteByCustomerId(e.target.value);
                    }}
                  >
                    <option value={""}>Select Customer</option>
                    {customerData.map((customer) => (
                      <option value={customer.id} key={customer.id}>
                        {customer.customer_name}
                      </option>
                    ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.customer_id && touched.customer_id ? (
                  <div className="text-red-700">{errors.customer_id}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="site_id"
                >
                  Site
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="site_id"
                    name="site_id"
                    onChange={(e) => {
                      setFieldValue("site_id", e.target.value);
                    }}
                  >
                    <option value={""}>Select Site</option>
                    {siteData.map((site) => (
                      <option value={site.id} key={site.id}>
                        {site.site_name}
                      </option>
                    ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.site_id && touched.site_id ? (
                  <div className="text-red-700">{errors.site_id}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="opportunity_name"
                >
                  Opportunity Name
                </label>
                <Field
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="opportunity_name"
                  name="opportunity_name"
                  onChange={(e) => {
                    setFieldValue("opportunity_name", e.target.value);
                  }}
                />
                {errors.opportunity_name && touched.opportunity_name ? (
                  <div className="text-red-700">{errors.opportunity_name}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="sales_executive"
                >
                  Sales Executive
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="sales_executive"
                    name="sales_executive"
                    onChange={(e) => {
                      setFieldValue("sales_executive", e.target.value);
                    }}
                  >
                    <option value={""}>Select Sales Executive</option>
                    {userData.map((user) => (
                      <option value={user.id} key={user.id}>
                        {user.firstname} {user.lastname}
                      </option>
                    ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.sales_executive && touched.sales_executive ? (
                  <div className="text-red-700">{errors.sales_executive}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="first_contact_date"
                >
                  First Contact Date
                </label>
                <Field
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="date"
                  id="first_contact_date"
                  name="first_contact_date"
                  onChange={(e) => {
                    setFieldValue("first_contact_date", e.target.value);
                  }}
                />
                {errors.first_contact_date && touched.first_contact_date ? (
                  <div className="text-red-700">
                    {errors.first_contact_date}
                  </div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="expected_close_date"
                >
                  Expected Close Date
                </label>
                <Field
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="date"
                  id="expected_close_date"
                  name="expected_close_date"
                  onChange={(e) => {
                    setFieldValue("expected_close_date", e.target.value);
                  }}
                />
                {errors.expected_close_date && touched.expected_close_date ? (
                  <div className="text-red-700">
                    {errors.expected_close_date}
                  </div>
                ) : null}
              </div>
              {/* <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="last_contact_date"
                >
                  Last Contact Date
                </label>
                <Field
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="date"
                  id="last_contact_date"
                  name="last_contact_date"
                  onChange={(e) => {
                    setFieldValue("last_contact_date", e.target.value);
                  }}
                />
                {errors.last_contact_date && touched.last_contact_date ? (
                  <div className="text-red-700">{errors.last_contact_date}</div>
                ) : null}
              </div> */}
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="next_contact_date"
                >
                  Next Contact Date
                </label>
                <Field
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="date"
                  id="next_contact_date"
                  name="next_contact_date"
                  onChange={(e) => {
                    setFieldValue("next_contact_date", e.target.value);
                  }}
                />
                {errors.next_contact_date && touched.next_contact_date ? (
                  <div className="text-red-700">{errors.next_contact_date}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="estimated_value"
                >
                  Total Estimated Value
                </label>
                <Field
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="estimated_value"
                  name="estimated_value"
                  onChange={(e) => {
                    setFieldValue("estimated_value", e.target.value);
                  }}
                />
                {errors.estimated_value && touched.estimated_value ? (
                  <div className="text-red-700">{errors.estimated_value}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="lead_source"
                >
                  Lead Source
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="lead_source"
                    name="lead_source"
                    value={values.lead_source}
                    onChange={(e) => {
                      setFieldValue("lead_source", e.target.value);
                    }}
                  >
                    <option value={""}>Select Lead Source</option>
                    {options
                      .filter(
                        (option) =>
                          option.ui_name === "Opportunity" &&
                          option.field_name === "Lead_Source"
                      )
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.field_code}
                        </option>
                      ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.lead_source && touched.lead_source ? (
                  <div className="text-red-700">{errors.lead_source}</div>
                ) : null}
              </div>

              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="job_type"
                >
                  Job Type
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="job_type"
                    name="job_type"
                    value={values.job_type}
                    onChange={(e) => {
                      setFieldValue("job_type", e.target.value);
                    }}
                  >
                    <option value={""}>Select Job Type</option>
                    {options
                      .filter(
                        (option) =>
                          option.ui_name === "Opportunity" &&
                          option.field_name === "Job_Type"
                      )
                      .map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          selected={option.is_default ? true : false}
                        >
                          {option.field_code}
                        </option>
                      ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.job_type && touched.job_type ? (
                  <div className="text-red-700">{errors.job_type}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="progress_status"
                >
                  Progress Status
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="progress_status"
                    name="progress_status"
                    onChange={(e) => {
                      setFieldValue("progress_status", e.target.value);
                    }}
                  >
                    <option value={""}>Select Progress Status</option>
                    {options
                      .filter(
                        (option) =>
                          option.ui_name === "Opportunity" &&
                          option.field_name === "Progress_Status"
                      )
                      .map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          selected={option.is_default ? true : false}
                        >
                          {option.field_code}
                        </option>
                      ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.progress_status && touched.progress_status ? (
                  <div className="text-red-700">{errors.progress_status}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="win_probability"
                >
                  Win Probability
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="win_probability"
                    name="win_probability"
                    onChange={(e) => {
                      setFieldValue("win_probability", e.target.value);
                    }}
                  >
                    <option value={""}>Select Win Probability</option>
                    {options
                      .filter(
                        (option) =>
                          option.ui_name === "Opportunity" &&
                          option.field_name === "Win_Probability"
                      )
                      .map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          selected={option.is_default ? true : false}
                        >
                          {option.field_code}
                        </option>
                      ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.win_probability && touched.win_probability ? (
                  <div className="text-red-700">{errors.win_probability}</div>
                ) : null}
              </div>
              {/* <div>
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="status"
                >
                  Status
                </label>
                <div>
                  <label
                    htmlFor="status"
                    className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                  >
                    <input
                      id="status"
                      type="checkbox"
                      className="hidden peer"
                      name="status"
                      // defaultChecked
                      onChange={(e) => {
                        const checkedValue = e.target.checked
                          ? "Active"
                          : "Inactive";
                        setFieldValue("status", checkedValue);
                      }}
                    />
                    <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                      Inactive
                    </span>
                    <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                      Active
                    </span>
                  </label>
                </div>
              </div> */}
              <div className="col-span-3 mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="current_status_comment"
                >
                  Opportunity Notes
                </label>
                <Field
                  as="textarea"
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-[200px]"
                  type="text"
                  id="current_status_comment"
                  name="current_status_comment"
                  onChange={(e) => {
                    setFieldValue("current_status_comment", e.target.value);
                  }}
                />
                {errors.current_status_comment &&
                touched.current_status_comment ? (
                  <div className="text-red-700">
                    {errors.current_status_comment}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
              <div>
                <button
                  type="submit"
                  name="submit"
                  className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddOpportunity;
