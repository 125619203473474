import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getOpportunityReport = createAsyncThunk(
  "getOpportunityReport",
  async ({ search = "", page = 1, limit = 100 }) => {
    const request = await api(
      `api/admin/reports/opportunity?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const searchOpportunityReport = createAsyncThunk(
  "searchOpportunityReport",
  async ({
    sales_executive,
    progress_status,
    win_probability,
    expected_close_date,
    lead_source,
  }) => {
    const request = await api(
      `api/admin/report/opportunity/search?sales_executive=${sales_executive}&progress_status=${progress_status}&win_probability=${win_probability}&expected_close_date=${expected_close_date}&lead_source=${lead_source}`,
      "",
      "get"
    );
    return request;
  }
);

const opportunityReportSlice = createSlice({
  name: "opportunityReport",
  initialState: {
    loading: false,
    data: [],
    success: null,
    error: null,
    totalCount: 0,
    totalUnweightedValue: 0,
    totalWeightedValue: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOpportunityReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOpportunityReport.fulfilled, (state, action) => {
        state.data = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.totalUnweightedValue = action?.payload.total_unweighted_value;
        state.totalWeightedValue = action?.payload.total_weighted_value;
        state.loading = false;
        state.error = null;
      })
      .addCase(getOpportunityReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchOpportunityReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchOpportunityReport.fulfilled, (state, action) => {
        state.data = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.totalUnweightedValue = action?.payload.total_unweighted_value;
        state.totalWeightedValue = action?.payload.total_weighted_value;
        state.loading = false;
        state.error = null;
      })
      .addCase(searchOpportunityReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default opportunityReportSlice.reducer;
