import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getCountryForUi } from "../../store/reducer/country/countrySlice";
import { getCustomerById } from "../../store/reducer/customers/customersSlice";
import {
  getCustomerSiteById,
  updateCustomerSite,
} from "../../store/reducer/customers/customerSiteSlice";
import { getCustomerContactForUI } from "../../store/reducer/customers/customerContactSlice";

function EditSite() {
  const { id, site_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);
  const { countries = [] } = useSelector((state) => state.countries);

  const [customerData, setCustomerData] = useState([]);
  const [customerSiteData, setCustomerSiteData] = useState([]);
  const [customerContactData, setcustomerContactData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getCustomerById(id)).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
    dispatch(getCustomerSiteById(site_id)).then((result) => {
      if (result.payload) {
        setCustomerSiteData(result.payload);
        setLoading(false);
      }
    });
    dispatch(getCustomerContactForUI(id)).then((result) => {
      if (result.payload) {
        setcustomerContactData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
    dispatch(getCountryForUi());
  }, []);

  const validationSchema = Yup.object().shape({
    site_name: Yup.string().required("please enter site name"),
    customer_contact_id: Yup.number().required("please select customer contact"),
    vat: Yup.number().required("please select vat"),
    country: Yup.number().required("please select country")
  });

  const initialValues = {
    site_name: customerSiteData?.site_name || "",
    customer_contact_id: customerSiteData?.customer_contact_id || "",
    vat: customerSiteData?.vat || "",
    eori_number: customerSiteData?.eori_number || "",
    street: customerSiteData?.street || "",
    city: customerSiteData?.city || "",
    state: customerSiteData?.state || "",
    post_code: customerSiteData?.post_code || "",
    country: customerSiteData?.country || "",
    status: customerSiteData?.status || "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateCustomerSite({ data: formData, id: site_id })).then(
      (result) => {
        if (result.payload) {
          toast.success(result.payload.message);
          navigate(`/customer/${id}/site`);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };
  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/customer/${id}/site`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Site / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Edit Site
            </span>
          </div>
        </div>
      </div>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="flex gap-[20px]">
                <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="site_name"
                    >
                      Site Name
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="site_name"
                      name="site_name"
                      onChange={(e) => {
                        setFieldValue("site_name", e.target.value);
                      }}
                    />
                    {errors.site_name && touched.site_name ? (
                      <div className="text-red-700">{errors.site_name}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="customer_contact_id"
                    >
                      Contact
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="customer_contact_id"
                        name="customer_contact_id"
                        onChange={(e) => {
                          setFieldValue("customer_contact_id", e.target.value);
                        }}
                      >
                        <option value={""}>Select Customer Contact</option>
                        {customerContactData
                          .map((contact) => (
                            <option
                              key={contact.id}
                              value={contact.id}
                            >
                              {contact.firstname} {contact.lastname}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.customer_contact_id && touched.customer_contact_id ? (
                      <div className="text-red-700">{errors.customer_contact_id}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="vat"
                    >
                      Vat
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="vat"
                        name="vat"
                        onChange={(e) => {
                          setFieldValue("vat", e.target.value);
                        }}
                      >
                        <option value={""}>Select vat</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Finance" &&
                              option.field_name === "Tax_Rate"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.vat && touched.vat ? (
                      <div className="text-red-700">{errors.vat}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="eori_number"
                    >
                      EORI Number
                    </label>
                    <Field
                      type="text"
                      name="eori_number"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("eori_number", e.target.value);
                      }}
                    />
                    {errors.eori_number && touched.eori_number ? (
                      <div className="text-red-700">{errors.eori_number}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="street"
                    >
                      Street
                    </label>
                    <Field
                      as="textarea"
                      name="street"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("street", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="city"
                    >
                      City
                    </label>
                    <Field
                      name="city"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("city", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="state"
                    >
                      State
                    </label>
                    <Field
                      name="state"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("state", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="post_code"
                    >
                      Post Code
                    </label>
                    <Field
                      name="post_code"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("post_code", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="country"
                    >
                      Country
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="country"
                        name="country"
                        onChange={(e) => {
                          setFieldValue("country", e.target.value);
                        }}
                      >
                        <option value={""}>Select country</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.short_name}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                      {errors.country && touched.country ? (
                        <div className="text-red-700">{errors.country}</div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <div>
                      <label
                        htmlFor="status"
                        className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                      >
                        <input
                          id="status"
                          type="checkbox"
                          className="hidden peer"
                          name="status"
                          defaultChecked={values.status === "Active"}
                          onChange={(e) => {
                            const checkedValue = e.target.checked
                              ? "Active"
                              : "Inactive";
                            setFieldValue("status", checkedValue);
                          }}
                        />
                        <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                          Inactive
                        </span>
                        <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                          Active
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditSite;
