import React, { useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import Link for navigation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginuser } from "../../store/reducer/auth/authSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  useEffect(() => {
    const local_data = JSON.parse(localStorage.getItem("userSession"));
    if (local_data && local_data.token) {
      navigateToDashboard();
    }
  }, [navigateToDashboard]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const { loading } = useSelector((state) => state.auth);

  const handleSubmit = async (values) => {
    dispatch(loginuser(values)).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        navigate("/dashboard");
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
      <div className="bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-[#000] flex flex-col items-center">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/image/logo.png"}
              className="w-mx-auto"
              alt=""
            />
          </div>
          <div className="mt-12 flex flex-col items-center w-full">
            <div className="w-full flex-1 mt-8">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className="mx-auto max-w-md">
                      <Field
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                        type="email"
                        name="email"
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-red-700">{errors.email}</div>
                      ) : null}
                      <Field
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                        type="password"
                        name="password"
                        onChange={(e) => {
                          setFieldValue("password", e.target.value);
                        }}
                        placeholder="Password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-red-700">{errors.password}</div>
                      ) : null}
                      <button
                        className="mt-5 tracking-wide font-semibold bg-green-400 text-white-500 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                        type="submit"
                        disabled={loading}
                      >
                        <span className="">Sign In</span>
                      </button>
                      {/* Reset Password Link */}
                      <div className="mt-5 text-center">
                        <Link
                          to="/forgot-password"
                          className="text-green-500 hover:underline"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-green-100 text-center hidden lg:flex">
          <div className="w-full bg-center bg-no-repeat">
            <video
              autoPlay
              loop
              muted
              className="min-w-full min-h-full object-fill"
            >
              <source
                src={
                  process.env.PUBLIC_URL + "/video/Kuboid-Self-Storage-bg.mp4"
                }
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
