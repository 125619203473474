import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../../store/reducer/ddOption/ddOptionSlice";
import { addOptionCosts } from "../../../store/reducer/optionCosts/optionCostsSlice";
import {
  getAdditionalOptionItems
} from "../../../store/reducer/drawing/drawingAdditionalOptionSlice";

function AddOptionCosts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [optionItems, setOptionItems] = useState([]);
  const { options = [] } = useSelector((state) => state.ddOptions);

  useEffect(() => {
    dispatch(getAdditionalOptionItems()).then((result) => {
      if (result.payload) {
        setOptionItems(result.payload);
      }
    });
  }, []);

  const validationSchema = Yup.object().shape({
    item_id: Yup.string().required("please enter item name"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addOptionCosts(formData)).then((result) => {
      if (result.payload) {
        resetForm();
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/setting/option-costs">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Additional Option / Add Cost
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Additional Option Cost
            </span>
          </div>
        </div>
      </div>
      <Formik
        initialValues={{
          item_id: "",
          cost_per_m2: "",
          cost_per_tin: "",
          paint_labour: "",
          roller_price: "",
          sort_field: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <div className="flex gap-[20px]">
              <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="item_id"
                  >
                    Item
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="item_id"
                      name="item_id"
                      onChange={(e) => {
                        setFieldValue("item_id", e.target.value);
                      }}
                    >
                      <option value={""}>Select Item</option>
                      {optionItems
                        .map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                          >
                            {item.item_description}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.item_id && touched.item_id ? (
                    <div className="text-red-700">{errors.item_id}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="cost_per_m2"
                  >
                    Cost per m2
                  </label>

                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="cost_per_m2"
                    name="cost_per_m2"
                    onChange={(e) => {
                      setFieldValue("cost_per_m2", e.target.value);
                    }}
                  />
                  {errors.cost_per_m2 && touched.cost_per_m2 ? (
                    <div className="text-red-700">{errors.cost_per_m2}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="cost_per_tin"
                  >
                    Cost Per Tin
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="cost_per_tin"
                    name="cost_per_tin"
                    onChange={(e) => {
                      setFieldValue("cost_per_tin", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="paint_labour"
                  >
                    Paint Labur
                  </label>
                  <Field
                    type="text"
                    name="paint_labour"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setFieldValue("paint_labour", e.target.value);
                    }}
                  />
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="roller_price"
                  >
                    Roller Price
                  </label>
                  <Field
                    name="roller_price"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setFieldValue("roller_price", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="sort_field"
                  >
                    Sort Field
                  </label>
                  <Field
                    type="number"
                    name="sort_field"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setFieldValue("sort_field", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
              <div>
                <button
                  type="submit"
                  name="submit"
                  className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddOptionCosts;
