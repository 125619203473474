import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listUsers = createAsyncThunk(
  "listUsers",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/users?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getUserById = createAsyncThunk("getUserById", async (data) => {
  const request = await api(`api/admin/user/${data}`, "", "get");
  return request;
});

export const getUserForUI = createAsyncThunk("getUserForUI", async () => {
  const request = await api(`api/admin/userforui`, "", "get");
  return request;
});

export const addUser = createAsyncThunk("addUser", async (data) => {
  const request = await api("api/admin/user", data, "postFile");
  return request;
});

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ data, id }) => {
    const request = await api(`api/admin/user/${id}`, data, "postFile");
    return request;
  }
);

// export const deleteUser = createAsyncThunk(
//     'deleteUser',
//     async()=>{
//         const request = await api("api/admin/get/users", '', "post");
//         return request;
//     }
// )
const userSlice = createSlice({
  name: "adminUsers",
  initialState: {
    loading: false,
    users: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.users = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload); // Add the new user to the users array
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.users = state.users.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          return user;
        });
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    //   .addCase(deleteUser.pending, (state) => {
    //     state.loading = true;
    //     state.user = null;
    //     state.error = null;
    //   })
    //   .addCase(deleteUser.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.user = action.payload;
    //     state.error = null;
    //   })
    //   .addCase(deleteUser.rejected, (state, action) => {
    //     state.loading = false;
    //     state.user = null;
    //     state.error = action.error.message;
    //   })
  },
});
export default userSlice.reducer;
// export { listUsers, addUser, updateUser, deleteUser };
