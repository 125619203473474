import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const ListCustomerContacts = createAsyncThunk(
  "ListCustomerContacts",
  async ({ customer_id, search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/customer_contacts/${customer_id}?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getCustomerContactById = createAsyncThunk(
  "getCustomerContactById",
  async (data) => {
    const request = await api(`api/admin/customer_contact/${data}`, "", "get");
    return request;
  }
);

export const getCustomerContactForUI= createAsyncThunk(
  "getCustomerContactForUI",
  async (data) => {
    const request = await api(`api/admin/customer_contact/ui/${data}`, "", "get");
    return request;
  }
);

export const addCustomerContact = createAsyncThunk(
  "addCustomerContact",
  async (data) => {
    const request = await api("api/admin/customer_contact", data, "post");
    return request;
  }
);

export const updateCustomerContact = createAsyncThunk(
  "updateCustomerContact",
  async ({ data, contact_id }) => {
    const request = await api(
      `api/admin/customer_contact/${contact_id}`,
      data,
      "post"
    );
    return request;
  }
);

export const primaryContact = createAsyncThunk(
  "primaryContact",
  async ({ data, contact_id, customer_id }) => {
    const request = await api(
      `api/admin/${customer_id}/primary_customer_contact/${contact_id}`,
      data,
      "post"
    );
    return request;
  }
);

const customerContactSlice = createSlice({
  name: "customerContacts",
  initialState: {
    loading: false,
    contacts: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(ListCustomerContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ListCustomerContacts.fulfilled, (state, action) => {
        state.contacts = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(ListCustomerContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addCustomerContact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCustomerContact.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts.push(action.payload);
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(addCustomerContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCustomerContact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCustomerContact.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = state.contacts.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateCustomerContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default customerContactSlice.reducer;
