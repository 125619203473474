import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getBomSuppliers
} from "../../store/reducer/drawing/drawingPurchaseOrderSlice";

const SupplierItems = ({ purchaseOrderObject, supplier, handleItemAssign, data }) => (
    <div className="sm:rounded-lg mt-5 overflow-y-auto max-h-[400px]">
      <table className="w-full">
        <thead className="bg-white">
          <tr className="rounded-[12px]">
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Assign
              <input
                type="checkbox"
                className="w-5 h-5 m-1 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                key={`${supplier?.supplier_id}`}
                onChange={(e) => {
                  data?.items_provided?.filter(item => !purchaseOrderObject?.assignedItems?.bom?.find(it => it.supplier?.supplier_id !== supplier?.supplier_id && it.item.id === item.id)).forEach(item => {
                    handleItemAssign(item, { supplier_id: supplier?.supplier_id, supplier_name: data.supplier_name}, e.target.checked)
                  })
                }}
                checked={data?.items_provided?.filter(
                  item => !purchaseOrderObject?.assignedItems?.bom?.find(it => it.item.id === item.id)
                )?.length == 0}
              />
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Item name
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Extra Details
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Quantity
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Length
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Total Quantity
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Cost
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Line Cost
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {data?.items_provided?.length > 0 ? (
            data?.items_provided?.filter(item => !purchaseOrderObject?.assignedItems?.bom?.find(it => it.supplier?.supplier_id != supplier?.supplier_id && it.item.id == item.id)).map((item, index) => (
              <tr key={item.id + supplier?.supplier_id}>
                <input
                    type="checkbox"
                    className="w-5 h-5 mt-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                    key={`${item.id}-${supplier?.supplier_id}`}
                    onChange={(e) => handleItemAssign(item, { supplier_id: supplier?.supplier_id, supplier_name: data.supplier_name}, e.target.checked)}
                    checked={!!purchaseOrderObject['bom'][supplier?.supplier_id]?.items?.find(it => it.id == item.id)}
                  />
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.item_description}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.extra_details}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                  {item.qty}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                  {item.item_length ? item.item_length : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                  {item.revised_qty}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                  {item.item_cost}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                  {item.total_line_cost}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="6">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="2xl"
                  style={{ color: "#55A14A" }}
                  spin
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
)

function PurchaseOrdersBOM({ itemGroup, areaField, supplier, supplierData, drawing_id, purchaseOrderObject, setPurchaseOrderObject }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [bomSupplierData, setBomSupplierData] = useState([]);
  const [supplierItemData, setSupplierItemData] = useState([]);

  const handleItemAssign = (item, supplier, assign) => {
    setPurchaseOrderObject((prevState) => {
      const updatedBom = { ...prevState.bom };
      let updatedBomAssignedItems = [...prevState.assignedItems?.bom];
      if (assign) {
        if (updatedBom[supplier?.supplier_id]) {
          if (!updatedBom[supplier?.supplier_id].items.some((it) => it.id === item.id)) {
            updatedBom[supplier?.supplier_id] = {
              ...updatedBom[supplier?.supplier_id],
              items: [...updatedBom[supplier?.supplier_id].items, item]
            };
          }
        } else {
          updatedBom[supplier?.supplier_id] = { supplier, items: [item] };
        }
        if (!updatedBomAssignedItems.some((it) => it.item.id === item.id)) {
          updatedBomAssignedItems.push({ item, supplier });
        }
      } else {
        if (updatedBom[supplier?.supplier_id]) {
          const updatedItems = updatedBom[supplier?.supplier_id].items.filter(
            (it) => it.id !== item.id
          );
          if (updatedItems.length > 0) {
            updatedBom[supplier?.supplier_id] = {
              ...updatedBom[supplier?.supplier_id],
              items: updatedItems
            };
          } else {
            delete updatedBom[supplier?.supplier_id];
          }
        }
        updatedBomAssignedItems = updatedBomAssignedItems.filter(
          (it) => it.item.id !== item.id
        );
      }
      return {
        ...prevState,
        bom: updatedBom,
        assignedItems: {...prevState.assignedItems, bom: updatedBomAssignedItems}
      };
    });
  };
  
  useEffect(() => {
    if (supplier?.supplier_id && supplierData) {
      // Filter the supplierData based on supplierId
      let itemsData = supplierData.filter(
        (data) => data && data.supplier_id == supplier.supplier_id
      );
  
      // If areaField is selected, further filter by area_id
      if (areaField) {
        itemsData = itemsData.filter((data) => data.area_id == areaField);
      }
  
      // If itemGroup is selected, further filter by group_id
      if (itemGroup) {
        itemsData = itemsData.map((data) => ({
          ...data,
          items_provided: data.items_provided.filter((item) => item.group_id == itemGroup)
        })).filter((data) => data.items_provided.length > 0);
      }
  
      // Aggregate items across all areas
      const aggregatedItems = itemsData.flatMap((data) => data.items_provided).reduce((acc, current) => {
        if(!current) return acc;
        const key = `${current.item_id}-${current.extra_details?.length ? current.extra_details : ''}`;
  
        if (!acc[key]) {
          acc[key] = {
            ...current,
            qty: 0,
            revised_qty: 0,
            total_line_cost: 0,
            groupedBOM: []
          };
        }
  
        acc[key].qty += current.qty;
        acc[key].revised_qty += current.revised_qty;
        acc[key].total_line_cost += current.total_line_cost;
        acc[key].qty = parseFloat(acc[key].qty).toFixed(2);
        acc[key].revised_qty = parseFloat(acc[key].revised_qty).toFixed(2);
        acc[key].total_line_cost = parseFloat(acc[key].total_line_cost).toFixed(2);
        acc[key].groupedBOM?.push(current.id)
        return acc;
      }, {});
  
      // Convert the object back to an array
      const aggregatedItemsArray = Object.values(aggregatedItems);
  
      // Create a single object to hold the aggregated items and supplier details
      const aggregatedData = {
        supplier_id: supplier.supplier_id,
        supplier_name: supplier.supplier_name,
        items_provided: aggregatedItemsArray
      };
    
      setSupplierItemData(aggregatedData);
    }
  }, [supplier, supplierData, areaField, itemGroup]);
  
  return (
    <>
      {!loading ? (
        <>
        <div className="flex gap-[20px] mb-[10px]">
        <div className="bg-[#F8F8F8] w-full rounded-[12px] p-4 items-center">
          { supplier?.supplier_id ? 
            <SupplierItems 
              handleItemAssign={handleItemAssign}
              supplier={supplier}
              purchaseOrderObject={purchaseOrderObject}
              data={supplierItemData}
            />
            : null
          }
        </div>
        </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default PurchaseOrdersBOM;
