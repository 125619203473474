import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listBankTransactions = createAsyncThunk("listBankTransactions", async ({ search = "", page = 1, limit = 10 }) => {
  const request = await api(`api/admin/bank-transactions?search=${search}&page=${page}&limit=${limit}`, "", "get");
  return request;
});

export const updateBankTransactionForExpense = createAsyncThunk(
  "updateBankTransactionForExpense",
  async ({ transactionId, expenseId }) => {
    console.log(transactionId, expenseId)
    const request = await api(`api/admin/bank-transactions/${transactionId}/${expenseId}`, "", "get");
    return request;
  }
);

const bankTransactionSlice = createSlice({
  name: "bankTransactions",
  initialState: {
    loading: false,
    bankTransactions: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listBankTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listBankTransactions.fulfilled, (state, action) => {
        state.bankTransactions = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listBankTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateBankTransactionForExpense.fulfilled, (state, action) => {
        state.loading = false;
      })
  },
});
export default bankTransactionSlice.reducer;
