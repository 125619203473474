import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getPandLByDrawingId = createAsyncThunk(
  "getPandLByDrawingId",
  async (data) => {
    const request = await api(`api/admin/pandl/${data}`, "", "get");
    return request;
  }
);

export const generatePandL = createAsyncThunk("generatePandL", async (data) => {
  const request = await api(`api/admin/generatepandl/${data}`, "", "get");
  return request;
});

export const getGeneratePandLStatus = createAsyncThunk(
  "getGeneratePandLStatus",
  async (data) => {
    const request = await api(
      `api/admin/generatepandlstatus/${data}`,
      "",
      "get"
    );
    return request;
  }
);
