import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams, Link } from 'react-router-dom'; // For navigation to login
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/reducer/auth/authSlice";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission status
  const { token } = useParams(); // Assuming the reset token is passed via the URL
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  useEffect(() => {
    const local_data = JSON.parse(localStorage.getItem("userSession"));
    if (local_data && local_data.token) {
      navigateToDashboard();
    }
  }, [navigateToDashboard]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    setIsSubmitting(true); // Disable the form on submit

    try {
      const result = await dispatch(resetPassword({ token, password }));
      if (result.payload) {
        toast.success(result.payload.message);
        navigate("/"); // Navigate to login after resetting
      } else {
        toast.error('Error resetting password.');
      }
    } catch (error) {
      toast.error('Error resetting password.');
    } finally {
      setIsSubmitting(false); // Reset submitting state after processing
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
      <div className="bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-[#000] flex flex-col items-center">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/image/logo.png"}
              className="w-mx-auto"
              alt=""
            />
          </div>
          <div className="mt-12 flex flex-col items-center w-full">
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="mx-auto max-w-md">
                <input
                  type="password"
                  name="password"
                  className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  disabled={isSubmitting} // Disable input when submitting
                />
                <input
                  type="password"
                  name="confirmPassword"
                  className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  disabled={isSubmitting} // Disable input when submitting
                />
              </div>
              <button
                className={`mt-5 tracking-wide font-semibold bg-green-400 text-white-500 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                type="submit"
                disabled={isSubmitting} // Disable button when submitting
              >
                <span className="">{isSubmitting ? 'Resetting...' : 'Reset Password'}</span>
              </button>
            </form>
          </div>
        </div>
        <div className="flex-1 bg-green-100 text-center hidden lg:flex">
          <div className="w-full bg-center bg-no-repeat">
            <video
              autoPlay
              loop
              muted
              className="min-w-full min-h-full object-fill"
            >
              <source
                src={process.env.PUBLIC_URL + "/video/Kuboid-Self-Storage-bg.mp4"}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
