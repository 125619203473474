import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import {
  addFile,
  deleteFile,
  getAllFiles,
  isFinalFile,
} from "../../store/reducer/drawing/drawingFileSilce";
import Notes from "../Notes/Notes";

function AddDrawingFiles() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setDrawingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [viewPdf, setViewPdf] = useState("");

  const handleCloseModal = () => setIsOpen(false);

  const validationSchema = Yup.object().shape({
    document_of: Yup.string().required("please select documnet of"),
    document_type: Yup.string().required("please select document type"),
    document_file: Yup.string().required("please select document file"),
  });

  const viewFile = (pdf) => {
    setViewPdf(pdf);
    setIsOpen(true);
  };

  const handleIsFinal = async (
    file_id,
    drawing_id,
    document_of,
    document_type
  ) => {
    const data = {
      drawing_id,
      document_of,
      document_type,
    };
    setReload(true);
    await dispatch(isFinalFile({ data, file_id })).then((result) => {
      if (result.payload) {
        dispatch(getAllFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
            setReload(false);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.drawing_id = drawingData.id;
    values.drawing_uniqueId = drawingData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addFile({ data: formData, drawing_id })).then((result) => {
      if (result.payload) {
        resetForm();

        dispatch(getAllFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleDeleteFile = async (id) => {
    await dispatch(deleteFile({ id })).then((result) => {
      if (result.payload) {
        dispatch(getAllFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setDrawingData(result.payload);
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getDdOptionForUI());
    dispatch(getAllFiles(drawing_id)).then((result) => {
      if (result.payload) {
        setFileData(result.payload);
        setLoading(false);
      }
    });
  }, []);
  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Files / Upload
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Files
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-files" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      <Formik
        initialValues={{
          document_of: "",
          document_type: "",
          file_name: "",
          document_file: null,
          convert_to_pdf: false, 
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <div className="grid grid-cols-6 gap-[20px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="document_of"
                >
                  Document Of
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="document_of"
                    name="document_of"
                    onChange={(e) => {
                      setFieldValue("document_of", e.target.value);
                    }}
                  >
                    <option value={""}>Select document of</option>
                    {options
                      .filter(
                        (option) =>
                          option.ui_name === "Files" &&
                          option.field_name === "Document_of"
                      )
                      .map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          selected={option.is_default ? true : false}
                        >
                          {option.field_code}
                        </option>
                      ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.document_of && touched.document_of ? (
                  <div className="text-red-700">{errors.document_of}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="document_type"
                >
                  Document Type
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="document_type"
                    name="document_type"
                    onChange={(e) => {
                      setFieldValue("document_type", e.target.value);
                    }}
                  >
                    <option value={""}>Select Type</option>
                    {options
                      .filter(
                        (option) =>
                          option.ui_name === "Files" &&
                          option.field_name === "Document_type" &&
                          option.id !== 491 &&
                          option.id !== 461
                      )
                      .map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          selected={option.is_default ? true : false}
                        >
                          {option.field_code}
                        </option>
                      ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.document_type && touched.document_type ? (
                  <div className="text-red-700">{errors.document_type}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="document_type"
                >
                  File Name
                </label>
                <div className="relative">
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="file_name"
                    name="file_name"
                    onChange={(e) => {
                      setFieldValue("file_name", e.target.value);
                    }}
                  ></Field>
                </div>
                {errors.file_name && touched.file_name ? (
                  <div className="text-red-700">{errors.file_name}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="document_file"
                >
                  Select File
                </label>
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="file"
                  name="document_file"
                  id="document_file"
                  multiple
                  onChange={(e) => {
                    setFieldValue("document_file", e.currentTarget.files[0]);
                  }}
                />
              </div>
              <div className="m-5 flex items-center">
              <label
                className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px] flex items-center"
                htmlFor="convert_to_pdf"
              >
                <Field
                  type="checkbox"
                  name="convert_to_pdf"
                  id="convert_to_pdf"
                  className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2 mr-2"
                  onChange={(e) => setFieldValue("convert_to_pdf", e.target.checked)}
                />
                Convert to PDF
              </label>
            </div>


              <div className="flex items-center">
                <button
                  type="submit"
                  name="submit"
                  className={`py-[12px] px-[48px] text-center text-white rounded-[12px] text-[18px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Upload File"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
        <div className="flex flex-col">
          <div className="sm:rounded-lg mt-5">
            <table className="w-full">
              <thead className="bg-white">
                <tr className="rounded-[12px]">
                  <th
                    scope="col"
                    className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                  >
                    Document Of
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                  >
                    Document Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                  >
                    Document Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                  >
                    Uploaded By
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                  >
                    Uploaded On
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                  >
                    Is Final
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                  >
                    Options
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {!reload ? (
                  fileData.map((file, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                        {file.document_of_name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                        {file.document_type_name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                        {file.file_name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                        {file.uploaded_by}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                        {file.createdAt}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                        <input
                          type="checkbox"
                          className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                          checked={file.is_final_drawing_doc}
                          disabled={file.is_final_drawing_doc}
                          onChange={() => {
                            const file_id = file.id;
                            handleIsFinal(
                              file_id,
                              drawing_id,
                              file.document_of,
                              file.document_type
                            );
                          }}
                        />
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-right font-medium">
                        <div className="flex gap-[10px] items-center justify-center">
                          <span className="cursor-pointer w-[40px] bg-blue-100 rounded-lg p-2">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/icons/viewfile.png"
                              }
                              width={30}
                              onClick={() => {
                                viewFile(file.document_name);
                              }}
                            />
                          </span>
                          <span className="cursor-pointer">
                            <img
                              src={process.env.PUBLIC_URL + "/icons/delete.png"}
                              alt=""
                              className="w-[40px] bg-red-100 rounded-lg"
                              onClick={() => {
                                handleDeleteFile(file.id);
                              }}
                            />
                          </span>
                        </div>
                        {isOpen && (
                          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                            <div className="bg-white rounded-lg shadow-lg p-6 max-w-[800px] w-full h-[800px]">
                              <div className="flex justify-between items-center">
                                <button
                                  onClick={handleCloseModal}
                                  className="text-xl"
                                >
                                  &times;
                                </button>
                              </div>
                              {viewPdf && (
                                <iframe
                                  src={viewPdf}
                                  className="w-full h-[700px] mt-4 border"
                                  title="PDF Viewer"
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="6">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="2xl"
                        style={{ color: "#55A14A" }}
                        spin
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddDrawingFiles;
