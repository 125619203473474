import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faEye } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getOpportunityByCustomerId } from "../../store/reducer/opportunity/opportunitySlice";
import { Loader } from "@googlemaps/js-api-loader";
import {
  getExpenseById,
  updateExpense,
} from "../../store/reducer/expenses/expensesSlice";

function EditExpense() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [customerData, setCustomerData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [opportunityData, setOpportunityData] = useState([]);
  const [showMileage, setShowMileage] = useState(false);
  const [getMilesButton, setgetMilesButton] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [viewPdf, setViewPdf] = useState("");

  const getOppByCustomerId = (customer_id) => {
    dispatch(getOpportunityByCustomerId({ customer_id })).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
  };

  useEffect(() => {
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
    dispatch(getExpenseById(id)).then((result) => {
      if (result.payload) {
        setExpenseData(result.payload);
        getOppByCustomerId(result.payload.customer_id);
        setLoading(false);
      }
    });
  }, []);

  const handleCloseModal = () => setIsOpen(false);

  const viewFile = (pdf) => {
    setViewPdf(pdf);
    setIsOpen(true);
  };

  const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required("please select customer"),
    opportunity_id: Yup.string().required("please select opportunity"),
    category: Yup.string().required("please select category"),
    expense_date: Yup.string().required("please enter expense date"),
    currency: Yup.string().required("please select currency"),
    payment_method: Yup.string().required("please select payment method"),
    amount: Yup.number("Should be number").required("please select amount"),
    tax: Yup.string().required("please select tax"),
  });

  const initialValues = {
    customer_id: expenseData?.customer_id || "",
    opportunity_id: expenseData?.opportunity_id || "",
    category: expenseData?.category || "",
    expense_date: expenseData?.expense_date || "",
    currency: expenseData?.currency || "",
    payment_method: expenseData?.payment_method || "",
    amount: expenseData?.amount || "",
    tax: expenseData?.tax || "",
    notes: expenseData?.notes || "",
    receipt: expenseData?.receipt || "",
    line_amount: expenseData?.line_amount || "",
    vat_amount: expenseData?.vat_amount || "",
    from_postcode: expenseData?.from_postcode || "",
    to_postcode: expenseData?.to_postcode || "",
    miles: expenseData?.miles || 0,
    is_billable: expenseData?.is_billable || false,
    is_reimbursable: expenseData?.is_reimbursable || false,
    receipt: expenseData?.receipt || null,
    paymentmode: expenseData?.paymentmode || "",
    payment_ref: expenseData?.payment_ref || "",
    paid_on: expenseData?.paid_on || "",
  };

  const showMileageFields = (e) => {
    const category = e.target.value;
    if (category === "245") {
      setShowMileage(true);
    } else {
      setShowMileage(false);
    }
  };

  const getMiles = (from_postcode, to_postcode, setFieldValue) => {
    setgetMilesButton(false);

    const loader = new Loader({
      apiKey: process.env.REACT_APP_MATRIX_KEY,
    });
    loader.load().then(async () => {
      const distanceService = new window.google.maps.DistanceMatrixService();

      const request = {
        origins: [from_postcode],
        destinations: [to_postcode],
        travelMode: "DRIVING",
        unitSystem: window.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      };

      distanceService.getDistanceMatrix(request, (response, status) => {
        if (response.rows[0].elements[0].status === "OK") {
          const distanceText = response.rows[0].elements[0].distance.value;
          const miles = Math.ceil(distanceText / 1000);
          const amount = 0.45; // replace with your per-mile amount
          setFieldValue("miles", miles);
          setFieldValue("amount", (miles * amount).toFixed(2));
        } else {
          toast.error(
            "Error calculating distance: " + response.rows[0].elements[0].status
          );
          setgetMilesButton(true);
        }
      });
    });
  };

  const calculateLineAmount = (tax, amount, setFieldValue) => {
    const vat = options.find((option) => option.id == tax);
    if (vat) {
      const tax_rate = vat.field_value;
      const line_amount = amount / (1 + tax_rate / 100);
      setFieldValue("line_amount", Number(line_amount).toFixed(2));
      const vat_amount = amount - line_amount;
      setFieldValue("vat_amount", Number(vat_amount).toFixed(2));
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;

    if (values.paymentmode && values.payment_ref && values.paid_on) {
      values.expense_paid = true;
      values.paid_by = JSON.parse(localStorage.getItem("userSession")).id;
    }

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateExpense({ data: formData, id })).then((result) => {
      if (result.payload) {
        navigate("/expenses");
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/expenses">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Expense / Edit Expense
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Expense
            </span>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="md:grid md:grid-cols-2 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6">
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="customer_id"
                  >
                    Customer
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="customer_id"
                      name="customer_id"
                      onChange={(e) => {
                        setFieldValue("customer_id", e.target.value);
                        getOppByCustomerId(e.target.value);
                      }}
                    >
                      <option value={""}>Select Customer</option>
                      {customerData.map((customer) => (
                        <option value={customer.id} key={customer.id}>
                          {customer.customer_name}
                        </option>
                      ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.customer_id && touched.customer_id ? (
                    <div className="text-red-700">{errors.customer_id}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="opportunity_id"
                  >
                    Opportunity
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="opportunity_id"
                      name="opportunity_id"
                      onChange={(e) => {
                        setFieldValue("opportunity_id", e.target.value);
                      }}
                    >
                      <option value={""}>Select opportunity</option>
                      {opportunityData.map((opportunity) => (
                        <option value={opportunity.id} key={opportunity.id}>
                          {opportunity.opportunity_name}
                        </option>
                      ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.opportunity_id && touched.opportunity_id ? (
                    <div className="text-red-700">{errors.opportunity_id}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="receipt"
                  >
                    Select File (Images or PDF)
                  </label>
                  <input
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    key={Date.now()}
                    type="file"
                    name="receipt"
                    id="receipt"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFieldValue("receipt", file);
                    }}
                  />
                  {values.receipt && (
                    <div className="mt-2 flex flex-col space-y-2">
                      <span className="text-gray-700 text-sm">
                        File: {values.receipt.name || values.receipt.split('/').pop()}
                      </span>

                      <div
                        className="bg-blue-100 rounded-lg p-2 cursor-pointer inline-flex justify-center items-center w-max"
                        onClick={() => {
                          viewFile(values.receipt);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          size="lg"
                          style={{ color: "#397bd0" }}
                        />
                        <span className="ml-2 text-blue-600 text-sm">View File</span>
                      </div>
                    </div>
                  )}  
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="notes"
                  >
                    Notes
                  </label>
                  <Field
                    as="textarea"
                    type="text"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="notes"
                    name="notes"
                    onChange={(e) => {
                      setFieldValue("notes", e.target.value);
                    }}
                  />
                  {errors.notes && touched.notes ? (
                    <div className="text-red-700">{errors.notes}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="category"
                  >
                    Category
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="category"
                      name="category"
                      onChange={(e) => {
                        setFieldValue("category", e.target.value);
                        showMileageFields(e);
                      }}
                    >
                      <option value={""}>Select category</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Expense" &&
                            option.field_name === "Category"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.category && touched.category ? (
                    <div className="text-red-700">{errors.category}</div>
                  ) : null}
                </div>
                {showMileage && (
                  <>
                    <div className="grid grid-cols-3 gap-[20px] items-center">
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="from_postcode"
                        >
                          From Postcode
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="from_postcode"
                          name="from_postcode"
                          onChange={(e) => {
                            setFieldValue("from_postcode", e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="to_postcode"
                        >
                          To Postcode
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="to_postcode"
                          name="to_postcode"
                          onChange={(e) => {
                            setFieldValue("to_postcode", e.target.value);
                          }}
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          className={`py-[10px] px-[40px] text-center text-white rounded-[12px] text-[18px] bg-[#1C1C20]`}
                          type="button"
                          onClick={() =>
                            getMiles(
                              values.from_postcode,
                              values.to_postcode,
                              setFieldValue
                            )
                          }
                        >
                          Get Miles
                        </button>
                      </div>
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="miles"
                      >
                        Miles
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        id="miles"
                        name="miles"
                        onChange={(e) => {
                          setFieldValue("miles", e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="expense_date"
                  >
                    Expense Date
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="date"
                    id="expense_date"
                    name="expense_date"
                    onChange={(e) => {
                      setFieldValue("expense_date", e.target.value);
                    }}
                  />
                  {errors.expense_date && touched.expense_date ? (
                    <div className="text-red-700">{errors.expense_date}</div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="currency"
                  >
                    Currency
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="currency"
                      name="currency"
                      onChange={(e) => {
                        setFieldValue("currency", e.target.value);
                      }}
                    >
                      <option value={""}>Select currency</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Finance" &&
                            option.field_name === "Currency"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.category && touched.category ? (
                    <div className="text-red-700">{errors.category}</div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="payment_method"
                  >
                    Payment Method
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="payment_method"
                      name="payment_method"
                      onChange={(e) => {
                        const selectedPaymentMethod = e.target.value;
                        setFieldValue("payment_method", selectedPaymentMethod);
                        
                        // Set is_reimbursable to true if "Personal Card" is selected
                        const personalCardOption = options.find(
                          (option) => option.field_code == "Personal Card"
                        );
                        if (selectedPaymentMethod == personalCardOption?.id) {
                          setFieldValue("is_reimbursable", true);
                        }
                      }}
                    >
                      <option value={""}>Select payment method</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Expense" &&
                            option.field_name === "Payment Method"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.tax && touched.tax ? (
                    <div className="text-red-700">{errors.tax}</div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="amount"
                  >
                    Amount
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="amount"
                    name="amount"
                    onChange={(e) => {
                      setFieldValue("amount", e.target.value);
                      calculateLineAmount(
                        values.tax,
                        e.target.value,
                        setFieldValue
                      );
                    }}
                  />
                  {errors.amount && touched.amount ? (
                    <div className="text-red-700">{errors.amount}</div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="tax"
                  >
                    Tax
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="tax"
                      name="tax"
                      onChange={(e) => {
                        setFieldValue("tax", e.target.value);
                        calculateLineAmount(
                          e.target.value,
                          values.amount,
                          setFieldValue
                        );
                      }}
                    >
                      <option value={""}>Select tax rate</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Finance" &&
                            option.field_name === "Tax_Rate"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.tax && touched.tax ? (
                    <div className="text-red-700">{errors.tax}</div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="line_amount"
                  >
                    Line Amount
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="line_amount"
                    name="line_amount"
                    onChange={(e) => {
                      setFieldValue("line_amount", e.target.value);
                    }}
                  />
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="vat_amount"
                  >
                    VAT Amount
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="vat_amount"
                    name="vat_amount"
                    onChange={(e) => {
                      setFieldValue("vat_amount", e.target.value);
                    }}
                  />
                </div>
                {JSON.parse(localStorage.getItem("userSession")).role == 6 && (
                  <>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="paymentmode"
                      >
                        Payment Mode
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        id="paymentmode"
                        name="paymentmode"
                        onChange={(e) => {
                          setFieldValue("paymentmode", e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="payment_ref"
                      >
                        Payment Reference
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        id="payment_ref"
                        name="payment_ref"
                        onChange={(e) => {
                          setFieldValue("payment_ref", e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="paid_on"
                      >
                        Payment Date
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="paid_on"
                        name="paid_on"
                        onChange={(e) => {
                          setFieldValue(
                            "paid_on",
                            e.target.value === "" ? null : e.target.value
                          );
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="m-2 grid grid-cols-3 items-center justify-center ">
                  <div className="m-2">
                    <div className="flex items-center">
                      <Field
                        id="is_billable"
                        type="checkbox"
                        checked={values.is_billable}
                        onChange={(e) =>
                          setFieldValue("is_billable", e.target.checked)
                        }
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="is_billable"
                        className="ms-2 text-sm font-medium text-[#9E9E9E]"
                      >
                        Billable
                      </label>
                    </div>
                  </div>

                  <div className="m-2">
                    <div className="flex items-center">
                      <Field
                        id="is_reimbursable"
                        type="checkbox"
                        checked={values.is_reimbursable}
                        onChange={(e) =>
                          setFieldValue("is_reimbursable", e.target.checked)
                        }
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="is_reimbursable"
                        className="ms-2 text-sm font-medium text-[#9E9E9E]"
                      >
                        Is Re-imbursable
                      </label>
                    </div>
                  </div>
                </div>
                {isOpen && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-[800px] w-full h-[800px]">
                      <div className="flex justify-between items-center">
                        <button onClick={handleCloseModal} className="text-xl">
                          &times;
                        </button>
                      </div>
                      {viewPdf && (
                        <iframe
                          src={viewPdf}
                          className="w-full h-[700px] mt-4 border"
                          title="PDF Viewer"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default EditExpense;
