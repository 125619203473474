import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getDiscountByDrawingId = createAsyncThunk(
  "getDiscountByDrawingId",
  async (data) => {
    const request = await api(`api/admin/discount/${data}`, "", "get");
    return request;
  }
);

export const updateDrawingDiscount = createAsyncThunk(
  "updateDrawingDiscount",
  async ({ data, id }) => {
    const request = await api(`api/admin/discount/${id}`, data, "post");
    return request;
  }
);

const drawingDiscountSlice = createSlice({
  name: "drawingDiscount",
  initialState: {
    loading: false,
    discount: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
});
export default drawingDiscountSlice.reducer;
