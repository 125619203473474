import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listNotes = createAsyncThunk(
  "listNotes",
  async ({ drawing_id, type, search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/notes/${drawing_id}/${type}?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const addNote = createAsyncThunk("addNote", async (data) => {
  const request = await api(`api/admin/notes`, data, "post");
  return request;
});

export const deleteNote = createAsyncThunk("deleteNote", async (id) => {
  const request = await api(`api/admin/notes/${id}`, {}, "delete");
  return request;
});

const noteSlice = createSlice({
  name: "notes",
  initialState: {
    loading: false,
    notes: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listNotes.fulfilled, (state, action) => {
        state.notes = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.loading = false;
        state.notes.push(action.payload);
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default noteSlice.reducer;
