import React from "react";

function Dashboard() {
  return (
    <div className="flex justify-center items-center">
      <img
        src="https://png.pngtree.com/png-clipart/20211024/original/pngtree-coming-soon-png-image_6863544.png"
        width={500}
      />
    </div>
  );
}

export default Dashboard;
