import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getOpportunityBudgetByOpportunityId = createAsyncThunk(
  "getOpportunityBudgetByOpportunityId",
  async (data) => {
    const request = await api(`api/admin/opportunity-budget/opportunity/${data}`, '', "get");
    return request;
  }
);

const opportunityBudgetSlice = createSlice({
  name: "opportunityBudget",
  initialState: {
  },
  reducers: {},
});
export default opportunityBudgetSlice.reducer;
