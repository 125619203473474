import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import {
  deleteTakeOffItem,
  listTakeOffItems,
  getTakeOffOptions,
  deleteTakeOffOption,
  updateTakeOffOptions
} from "../../../store/reducer/takeOffItems/takeOffItemSlice";
import * as Yup from "yup";

const TakeOffOptionModal = ({ takeOffItem, options, handleSubmit, handleClose, handleDeleteOption }) => {
  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700 mt-1 text-sm">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    options: Yup.array()
      .of(
        Yup.object().shape({
          option_name: Yup.string().required("Please enter an option name")
        })
      )
  });
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
    <div className="bg-white p-6 rounded-lg w-[450px]">
      <h2 className="text-2xl font-bold mb-4">TakeOff Item Options</h2>
      <p className="text-md mb-4"><b>Item:</b> {takeOffItem?.item_name || 'NA'}</p>
      <Formik
        initialValues={{
          options: options.length > 0 ? options : [{ id: "", option_name: "", takeoff_item_id: "" }]
        }}
        enableReinitialize={true} // Reinitialize form when takeOffItem changes
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="options">
              {({ push, remove }) => (
                <div>
                  {console.log(values)}
                  {values.options.length > 0 &&
                    values.options.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center mb-2 p-2 rounded-lg"
                      >
                        <div className="flex-1">
                          <Field
                            type="text"
                            className="block w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            name={`options.${index}.option_name`}
                            placeholder="Option name"
                          />
                          <ErrorMessage
                            name={`options.${index}.option_name`}
                          />
                        </div>
                        {values.options.length > 0 && (
                          <button
                            type="button"
                            className="ml-2 text-red-500 hover:text-red-700"
                            onClick={() => {
                              remove(index)
                              if (
                                values.options[index].id
                              ) {
                                handleDeleteOption(
                                  values.options[index].id
                                );
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} size="lg" />
                          </button>
                        )}
                      </div>
                    ))}
                  <div className="text-right p-2">
                    <button
                      type="button"
                      className="text-green-500 hover:text-green-700"
                      onClick={() =>
                        push({
                          id: "",
                          option_name: "",
                          takeoff_item_id: ""
                        })
                      }
                    >
                      + Add New Option
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
            <div className="flex justify-end mt-4 space-x-4">
              <button
                type="submit"
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                Save
              </button>
              <button
                  type="button"
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
                  onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </div>
  );
};

function ListTakeOffItem() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    loading,
    items = [],
    totalCount,
  } = useSelector((state) => state.takeOffItems);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    dispatch(listTakeOffItems({ search, page, limit }));
  }, []);

  const handleSearch = (search = "") => {
    dispatch(listTakeOffItems({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [items]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listTakeOffItems({ search, page, limit }));
  };

  const handleDelete = async (id) => {
    await dispatch(deleteTakeOffItem(id)).then((result) => {
      if (result.payload) {
        dispatch(listTakeOffItems({ search, page, limit }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  
  const openTakeOffOptionsModal = async (item) => {
    setSelectedItem(item);
    dispatch(getTakeOffOptions(item?.id)).then(result => {
      if(result.payload) {
        setOptions(result.payload);
        setShowModal(true);
      }
    })
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  
  const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required("please select customer"),
    opportunity_id: Yup.string().required("please select opportunity"),
    category: Yup.string().required("please select category")
  });

  const initialValues = {
    options: []
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "options") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });

    await dispatch(updateTakeOffOptions({ data: formData, takeoff_item_id: selectedItem?.id })).then(
      (result) => {
        if (result.payload) {
          dispatch(getTakeOffOptions(selectedItem?.id)).then((result) => {
            if (result.payload) {
              setOptions(result.payload);
            }
          });
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };
  
  const handleDeleteOption = (id) => {
    dispatch(deleteTakeOffOption(id)).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="block md:flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to="/setting">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Take Off / List Item
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List TakeOff Items
            </span>
          </div>
        </div>
        <div className="">
          <Link to="/setting/takeoff/item/add">
            <div className="py-[10px] px-[40px] bg-[#F8F8F8] font-[500] font-Inter text-[18px] flex justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B] w-max">
              <span>Add Item</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="">
          <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="md:flex justify-between items-center p-[12px]">
                <div className="leading-[30px] font-Mulish font-[700] text-[24px]">
                  <span className="">Items Data</span>
                </div>
                <div className="relative">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/search.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="sm:rounded-lg mt-5 overflow-scroll">
                <table className="w-full text-center">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Item name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Is_default
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                  ) : (
                    <tbody>
                      {items.map((item) => (
                        <tr key={item.id} className="text-center">
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                            {item.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {item.item_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {item.item_description}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <span
                              className={`p-1 inline-flex text-xs leading-5 font-semibold rounded-lg ${
                                item.is_default
                                  ? `bg-green-200 text-green-800`
                                  : `bg-red-200 text-red-800`
                              } `}
                            >
                              {item.is_default ? "Yes" : "No"}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <span
                              className={`p-1 inline-flex text-xs leading-5 font-semibold rounded-lg ${
                                item.status === "Active"
                                  ? `bg-green-200 text-green-800`
                                  : `bg-red-200 text-red-800`
                              } `}
                            >
                              {item.status}
                            </span>
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap font-medium">
                            <div className="flex gap-[10px] justify-center">
                              <div>
                                <Link to={`/setting/takeoff/item/${item.id}`}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-teal-100 rounded-lg"
                                  />
                                </Link>
                              </div>
                              <div>
                                <button
                                  name="openTakeOffOptions"
                                  className="py-[10px] px-[18px] bg-yellow-200 text-center text-black rounded-[8px] text-[12px]"
                                  onClick={() => openTakeOffOptionsModal(item)}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </button>
                              </div>
                              <div>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/icons/delete.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-red-100 rounded-lg"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center"
          />
        </div>
      </div>
      {showModal && <TakeOffOptionModal takeOffItem={selectedItem} options={options} handleSubmit={handleSubmit} handleClose={closeModal} handleDeleteOption={handleDeleteOption} />}
    </>
  );
}

export default ListTakeOffItem;
