import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listItemPrices = createAsyncThunk(
  "listItemPrices",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/item_prices?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getItemPriceById = createAsyncThunk(
  "getItemPriceById",
  async (data) => {
    const request = await api(`api/admin/item_price/${data}`, "", "get");
    return request;
  }
);

export const getItemPricesForExport = createAsyncThunk(
  "getItemPricesForExport",
  async () => {
    const request = await api(`api/admin/item_prices/export`, "", "get");
    return request;
  }
);

export const getItemPriceByItemId = createAsyncThunk(
  "getItemPriceByItemId",
  async (data) => {
    const request = await api(
      `api/admin/item_price_by_item_id/${data}`,
      "",
      "get"
    );
    return request;
  }
);

export const addItemPrice = createAsyncThunk("addItemPrice", async (data) => {
  const request = await api("api/admin/item_price", data, "post");
  return request;
});

export const updateItemPrice = createAsyncThunk(
  "updateItemPrice",
  async ({ data, id }) => {
    const request = await api(`api/admin/item_price/${id}`, data, "post");
    return request;
  }
);

export const deleteItemPrice = createAsyncThunk(
  "deleteItemPrice",
  async (id) => {
    const request = await api(`api/admin/item_price/${id}`, {}, "delete");
    return request;
  }
);

const itemPriceSlice = createSlice({
  name: "itemPrices",
  initialState: {
    loading: false,
    prices: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listItemPrices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listItemPrices.fulfilled, (state, action) => {
        state.prices = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listItemPrices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addItemPrice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addItemPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.prices.push(action.payload); // Add the new user to the users array
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(addItemPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateItemPrice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateItemPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.prices = state.prices.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message; // Assuming the payload contains a success message
        state.error = null;
      })
      .addCase(updateItemPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteItemPrice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteItemPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteItemPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getItemPricesForExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getItemPricesForExport.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getItemPricesForExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default itemPriceSlice.reducer;
