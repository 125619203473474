import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";

function ListTaxRate() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    loading,
    options = [],
    totalCount,
  } = useSelector((state) => state.ddOptions);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // useEffect(() => {
  //   dispatch(listDdOptions({ search, page, limit }));
  // }, []);

  const handleSearch = (search = "") => {
    // dispatch(listDdOptions({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [options]);

  return (
    <>
      <div className="flex gap-[30px] bg-[#61b354] p-3 rounded-lg mb-10">
        <Link>
          <div
            className={`font-Inter text-[18px] font-[600] px-5 py-1 rounded-lg ${
              location.pathname === "/setting/finance/taxrate"
                ? "text-black bg-gray-200"
                : "text-white hover:bg-gray-200 hover:text-black"
            }`}
          >
            Tax Rates
          </div>
        </Link>
        <Link>
          <div
            className={`font-Inter text-[18px] font-[600] px-5 py-1 rounded-lg ${
              location.pathname === "/setting/finance/currency"
                ? "text-black bg-gray-200"
                : "text-white hover:bg-gray-200 hover:text-black"
            }`}
          >
            Currencies
          </div>
        </Link>
        <Link>
          <div
            className={`font-Inter text-[18px] font-[600] px-5 py-1 rounded-lg ${
              location.pathname === "/setting/finance/payment-mode"
                ? "text-black bg-gray-200"
                : "text-white hover:bg-gray-200 hover:text-black"
            }`}
          >
            Payment Modes
          </div>
        </Link>
        <Link>
          <div
            className={`font-Inter text-[18px] font-[600] px-5 py-1 rounded-lg ${
              location.pathname === "/setting/finance/expense-category"
                ? "text-black bg-gray-200"
                : "text-white hover:bg-gray-200 hover:text-black"
            }`}
          >
            Expenses Categories
          </div>
        </Link>
      </div>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/setting">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Tax Rate / List Rate
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List Tax Rate
            </span>
          </div>
        </div>
        <div className="">
          <Link to="/setting/dd-option/add">
            <div className="py-[10px] px-[40px] bg-[#F8F8F8] font-[500] font-Inter text-[18px] justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B]">
              <span>Add Tax Rate</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="">
          <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="md:flex justify-between items-center p-[12px]">
                <div className="leading-[30px] font-Mulish font-[700] text-[24px]">
                  <span className="">Tax Rate Data</span>
                </div>
                <div className="relative">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/search.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="sm:rounded-lg mt-5 overflow-scroll">
                <table className="w-full text-center">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        UI Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Field Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Field Code
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Field Value
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Is Default
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                  ) : (
                    <tbody>
                      {options.map((option) => (
                        <tr key={option.id} className="text-center">
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                            {option.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {option.ui_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {option.field_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {option.field_code}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {option.field_value}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {option.field_desc}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <span
                              className={`p-1 inline-flex text-xs leading-5
                      font-semibold rounded-lg ${
                        option.is_default
                          ? `bg-green-200 text-green-800`
                          : `bg-red-200 text-red-800`
                      } `}
                            >
                              {option.is_default ? "Yes" : "No"}
                            </span>
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap font-medium">
                            <div className="flex gap-[10px] justify-center">
                              <div>
                                <Link to={`/setting/dd-option/${option.id}`}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-teal-100 rounded-lg"
                                  />
                                </Link>
                              </div>
                              <div>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/icons/delete.png"
                                  }
                                  alt=""
                                  className="w-[40px] bg-red-100 rounded-lg"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListTaxRate;
