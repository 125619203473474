import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import {
  deleteDdOption,
  listDdOptions,
} from "../../../store/reducer/ddOption/ddOptionSlice";

function ListDdOption() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    loading,
    options = [],
    totalCount,
  } = useSelector((state) => state.ddOptions);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSearch = (search = "") => {
    dispatch(listDdOptions({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [options]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listDdOptions({ search, page, limit }));
  };

  const handleDelete = async (id) => {
    await dispatch(deleteDdOption(id)).then((result) => {
      if (result.payload) {
        dispatch(listDdOptions({ search, page, limit }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  useEffect(() => {
    dispatch(listDdOptions({ search, page, limit }));
  }, []);

  return (
    <>
      <div className="block md:flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to="/setting">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              DropDown / List Option
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List DropDown Option
            </span>
          </div>
        </div>
        <div className="">
          <Link to="/setting/dd-option/add">
            <div className="py-[10px] px-[40px] bg-[#F8F8F8] font-[500] font-Inter text-[18px] flex justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B] w-max">
              <span>Add Option</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
          <div className="flex flex-col">
            <div className="md:flex justify-between items-center p-[12px]">
              <div className="leading-[30px] font-Mulish font-[700] text-[24px] mb-[8px] md:mb-0">
                <span className="">Options Data</span>
              </div>
              <div className="relative">
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="search"
                  name="search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debouncedSearch(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/search.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="sm:rounded-lg mt-5 overflow-scroll">
              <table className="w-full text-center">
                <thead className="bg-white">
                  <tr className="rounded-[12px]">
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Id
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      UI Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Field Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Field Code
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Field Value
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Is Default
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
                ) : (
                  <tbody>
                    {options.map((option) => (
                      <tr key={option.id} className="text-center">
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                          {option.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {option.ui_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {option.field_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {option.field_code}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {option.field_value}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {option.field_desc}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          <span
                            className={`p-1 inline-flex text-xs leading-5
                      font-semibold rounded-lg ${
                        option.is_default
                          ? `bg-green-200 text-green-800`
                          : `bg-red-200 text-red-800`
                      } `}
                          >
                            {option.is_default ? "Yes" : "No"}
                          </span>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap font-medium">
                          <div className="flex gap-[10px] justify-center">
                            <div>
                              <Link to={`/setting/dd-option/${option.id}`}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/icons/edit.png"
                                  }
                                  alt=""
                                  className="w-[40px] bg-teal-100 rounded-lg"
                                />
                              </Link>
                            </div>
                            <div>
                              <span
                                className="cursor-pointer"
                                onClick={() => handleDelete(option.id)}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/icons/delete.png"
                                  }
                                  alt=""
                                  className="w-[40px] bg-red-100 rounded-lg"
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center"
          />
        </div>
      </div>
    </>
  );
}

export default ListDdOption;
