import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getAllProposalFiles = createAsyncThunk(
  "getAllProposalFiles",
  async () => {
    const request = await api(`api/admin/proposalfiles`, "", "get");
    return request;
  }
);

export const getAllProposalFilesByUser = createAsyncThunk(
  "getAllProposalFilesByUser",
  async (data) => {
    const request = await api(`api/admin/proposalfiles/${data}`, "", "get");
    return request;
  }
);

export const addProposalFile = createAsyncThunk(
  "addProposalFile",
  async ({ data }) => {
    const request = await api(`api/admin/proposalfile`, data, "postFile");
    return request;
  }
);

export const deleteProposalFile = createAsyncThunk(
  "deleteProposalFile",
  async ({ id }) => {
    const request = await api(`api/admin/proposalfile/${id}`, {}, "delete");
    return request;
  }
);

const proposalFileSilce = createSlice({
  name: "proposalFile",
  initialState: {
    loading: false,
    files: [],
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProposalFiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllProposalFiles.fulfilled, (state, action) => {
        state.files = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllProposalFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addProposalFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProposalFile.fulfilled, (state, action) => {
        state.loading = false;
        state.files.push(action.payload);
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(addProposalFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteProposalFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProposalFile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteProposalFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default proposalFileSilce.reducer;
