import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listInvoices = createAsyncThunk("listInvoices", async ({ search = "", page = 1, limit = 10, invoiceType = "ACCREC" }) => {
  const request = await api(`api/admin/invoices?search=${search}&page=${page}&limit=${limit}&invoicetype=${invoiceType}`, "", "get");
  return request;
});

export const getInvoicesForFinancials = createAsyncThunk("getInvoicesForFinancials", async (opportunity_id) => {
  const request = await api(`api/admin/invoices/financials/${opportunity_id}`, "", "get");
  return request;
});

const invoiceSlice = createSlice({
  name: "invoices",
  initialState: {
    loading: false,
    invoices: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listInvoices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listInvoices.fulfilled, (state, action) => {
        state.invoices = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getInvoicesForFinancials.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInvoicesForFinancials.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getInvoicesForFinancials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});
export default invoiceSlice.reducer;
