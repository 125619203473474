import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getBomSuppliers = createAsyncThunk(
  "getBomSuppliers",
  async (data) => {
    const request = await api(`api/admin/bomsuppliers/${data}`, "", "get");
    return request;
  }
);

export const getInstallSuppliers = createAsyncThunk(
  "getInstallSuppliers",
  async (data) => {
    const request = await api(`api/admin/installsuppliers`, "", "get");
    return request;
  }
);

export const getAdditionalOptionSuppliers = createAsyncThunk(
  "getAdditionalOptionSuppliers",
  async (data) => {
    const request = await api(`api/admin/additional-options-suppliers`, "", "get");
    return request;
  }
);

export const getAdditionalOptionItems = createAsyncThunk(
  "getAdditionalOptionItems",
  async (data) => {
    const request = await api(`api/admin/additional-options-items/${data}`, "", "get");
    return request;
  }
);

export const getInstallItems = createAsyncThunk(
  "getInstallItems",
  async (data) => {
    const request = await api(`api/admin/installitems/${data}`, "", "get");
    return request;
  }
);

export const createPurchaseOrder = createAsyncThunk(
  "createPurchaseOrder",
  async ({ data }) => {
    const request = await api(`api/admin/purchaseorder`, data, "post");
    return request;
  }
);

export const listPurchaseOrders = createAsyncThunk(
  "listPurchaseOrders",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/purchaseorder?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

const drawingPurchaseOrderSlice = createSlice({
  name: "drawingPurchaseOrder",
  initialState: {
    loading: false,
    bomSuppliers: [],
    purchaseOrders: [],
    totalCount: 0,
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(listPurchaseOrders.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(listPurchaseOrders.fulfilled, (state, action) => {
      state.purchaseOrders = action?.payload?.rows;
      state.totalCount = action?.payload.count;
      state.loading = false;
      state.error = null;
    })
    .addCase(listPurchaseOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(createPurchaseOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message;
      state.error = null;
    })
    .addCase(createPurchaseOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
  },
});
export default drawingPurchaseOrderSlice.reducer;
