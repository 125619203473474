import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getSiteByDrawingId = createAsyncThunk(
  "getSiteByDrawingId",
  async (data) => {
    const request = await api(`api/admin/site-detail/${data}`, "", "get");
    return request;
  }
);

export const updateDrawingSite = createAsyncThunk(
  "updateDrawingSite",
  async ({ data, id }) => {
    const request = await api(`api/admin/site-detail/${id}`, data, "post");
    return request;
  }
);

export const deleteSiteArea = createAsyncThunk(
  "deleteSiteArea",
  async ({ id }) => {
    const request = await api(`api/admin/site-detail/${id}`, {}, "delete");
    return request;
  }
);

const drawingSiteSlice = createSlice({
  name: "drawingSite",
  initialState: {
    loading: false,
    site: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // .addCase(listDrawingsByOpportunity.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(listDrawingsByOpportunity.fulfilled, (state, action) => {
      //   state.drawings = action?.payload?.rows;
      //   state.drawings = action?.payload?.rows;
      //   state.totalCount = action?.payload.count;
      //   state.loading = false;
      //   state.error = null;
      // })
      // .addCase(listDrawingsByOpportunity.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(searchOpportunities.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(searchOpportunities.fulfilled, (state, action) => {
      //   state.opportunities = action?.payload?.rows;
      //   state.totalCount = action?.payload.count;
      //   state.totalEstimatedValue = action?.payload.estimated_value;
      //   state.loading = false;
      //   state.error = null;
      // })
      // .addCase(searchOpportunities.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(addDrawing.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(addDrawing.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.drawings = action.payload;
      //   state.error = null;
      // })
      // .addCase(addDrawing.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      .addCase(updateDrawingSite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDrawingSite.fulfilled, (state, action) => {
        state.loading = false;
        state.site = state.site.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateDrawingSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSiteArea.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSiteArea.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteSiteArea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default drawingSiteSlice.reducer;
