import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useBlocker,
  usePrompt,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModel from "../../components/ConfirmModel";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import {
  deleteSiteArea,
  getSiteByDrawingId,
  updateDrawingSite,
} from "../../store/reducer/drawing/drawingSiteSlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import Notes from "../Notes/Notes";

function EditDrawingSiteDetail() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [proposalStatus, setProposalStatus] = useState(true);

  const formRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isNavigating, setIsNavigating] = useState(false);

  // const blocker = useBlocker((tx) => {
  //   if (formRef.current && formRef.current.dirty && !isNavigating) {
  //     setShowModal(true);
  //     setNextLocation(tx);
  //   } else {
  //     tx.retry();
  //   }
  // });

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      if (formRef.current && formRef.current.dirty) {
        const message =
          "You have unsaved changes, are you sure you want to leave?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // const handleConfirmNavigation = () => {
  //   setShowModal(false);
  //   setIsNavigating(true);
  //   blocker.proceed();
  // };

  // const handleCancelNavigation = () => {
  //   setShowModal(false);
  //   setNextLocation(null);
  // };

  useEffect(() => {
    dispatch(getDdOptionForUI());
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getUserForUI()).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
      }
    });
    dispatch(getSiteByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setSiteData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const getDefaultOption = (ui_name, field_name) => {
    const defaultOption = options.find((option) => {
      return (
        option.ui_name === ui_name &&
        option.field_name === field_name &&
        option.is_default === true
      );
    });
    return defaultOption ? defaultOption.id : "";
  };

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    terms: Yup.string().required("please select terms"),
    site_areas: Yup.array()
      .of(
        Yup.object().shape({
          area_name: Yup.string().required("please enter area name"),
        })
      )
      .test("area_name", "Area names must be unique", function (value) {
        const areaNames = value.map((area) => area.area_name);
        const hasDuplicates = areaNames.some(
          (name, index) => areaNames.indexOf(name) !== index
        );
        return !hasDuplicates;
      })
      .required("Must have area name"),
  });

  const initialValues = {
    delivery_required: siteData?.delivery_required || false,
    accomodation_required: siteData?.accomodation_required || false,
    forklift_supplied_by:
      siteData?.forklift_supplied_by ||
      getDefaultOption("Site_Details", "ForkLift_Supplied") ||
      "",
    terms: siteData?.terms || getDefaultOption("Site_Details", "Terms") || "",
    deliver_to:
      siteData?.deliver_to ||
      getDefaultOption("Site_Details", "Deliver_To") ||
      "",
    install:
      siteData?.install || getDefaultOption("Site_Details", "Install") || "",
    skips: siteData?.skips || getDefaultOption("Site_Details", "Skips") || "",
    roller_doors_required:
      siteData && siteData.roller_doors_required ? true : false,
    swing_door_type:
      siteData?.swing_door_type ||
      getDefaultOption("Site_Details", "Swing_Door_Type") ||
      "",
    swing_door_latch: siteData
      ? siteData.swing_door_latch
      : getDefaultOption("Site_Details", "Swing_Door_Latch"),

    roller_door_latch: siteData
      ? siteData.roller_door_latch
      : // getDefaultOption("Site_Details", "Roller_Door_Latch") ||
        "",
    jamb_details:
      siteData?.jamb_details ||
      getDefaultOption("Site_Details", "Jamb_Details") ||
      "",
    corner_guard:
      siteData?.corner_guard ||
      getDefaultOption("Site_Details", "Corner_Guard") ||
      "",
    corner_guard_height:
      siteData?.corner_guard_height ||
      getDefaultOption("Site_Details", "Corner_Guard_Height") ||
      "",
    kicker_guard:
      siteData?.kicker_guard ||
      getDefaultOption("Site_Details", "Kicker_Guard") ||
      "",
    kicker_guard_height:
      siteData?.kicker_guard_height ||
      getDefaultOption("Site_Details", "Kicker_Guard_Height") ||
      "",
    white_corridor_type:
      siteData?.white_corridor_type ||
      getDefaultOption("Site_Details", "White_Corridor_Type") ||
      "",
    aluzinc_type:
      siteData?.aluzinc_type ||
      getDefaultOption("Site_Details", "Aluzinc_Type") ||
      "",
    mesh_type: siteData
      ? siteData.mesh_type
      : // getDefaultOption("Site_Details", "Mesh_Type") ||
        "",
    soffit_type:
      siteData?.soffit_type ||
      getDefaultOption("Site_Details", "Soffit_Type") ||
      "",
    filler_angle: siteData?.filler_angle || false,
    bottom_channel_insert: siteData?.bottom_channel_insert || false,
    ceiling_brackets: siteData?.ceiling_brackets || false,
    cdm_regs_required: siteData?.cdm_regs_required || false,
    site_areas: siteData?.site_areas || [
      {
        id: "",
        area_name: "",
        rd_colour: getDefaultOption("Site_Details", "Roller_Door_Colour") || "",
        sd_colour: getDefaultOption("Site_Details", "Swing_Door_Colour") || "",
        jamb_color: getDefaultOption("Site_Details", "Jamb_Colour") || "",
        hammer_fixing: getDefaultOption("Site_Details", "Hammer_Fixings") || "",
      },
    ],
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.drawing_id = id;
    values.drawing_uniqueId = drawingData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "site_areas") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });

    await dispatch(updateDrawingSite({ data: formData, id: drawing_id })).then(
      (result) => {
        if (result.payload) {
          dispatch(getSiteByDrawingId(drawing_id)).then((result) => {
            if (result.payload) {
              setSiteData(result.payload);
            }
          });
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };

  const handleDeleteSiteArea = (id) => {
    dispatch(deleteSiteArea({ id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing Site / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Site Detail
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-sitedetail" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {/* <ConfirmModel
        show={showModal}
        onConfirm={handleConfirmNavigation}
        onCancel={handleCancelNavigation}
      /> */}
      {!loading ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="block md:flex gap-[20px] mb-[30px]">
                <div className="w-[100%] mb-[16px] md:mb-0 md:w-[50%] bg-[#F8F8F8] rounded-[12px] p-6 items-center">
                  <div className="md:grid md:grid-cols-2 gap-[20px] ">
                    <div className="mb-2">
                      <div className="flex items-center mb-4">
                        <Field
                          id="delivery_required"
                          type="checkbox"
                          checked={values.delivery_required}
                          onChange={(e) =>
                            setFieldValue("delivery_required", e.target.checked)
                          }
                          className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="delivery_required"
                          className="ms-2 text-sm font-medium text-[#9E9E9E]"
                        >
                          Delivery Required
                        </label>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="flex items-center mb-4">
                        <Field
                          id="accomodation_required"
                          type="checkbox"
                          checked={values.accomodation_required}
                          onChange={(e) =>
                            setFieldValue(
                              "accomodation_required",
                              e.target.checked
                            )
                          }
                          className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="accomodation_required"
                          className="ms-2 text-sm font-medium text-[#9E9E9E]"
                        >
                          Accomodation Required
                        </label>
                      </div>
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="install"
                      >
                        Install
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="install"
                          name="install"
                          value={values.install}
                          onChange={(e) => {
                            setFieldValue("install", e.target.value);
                          }}
                        >
                          <option value={""}>No install selected</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Install"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.install && touched.install ? (
                        <div className="text-red-700">{errors.install}</div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="forklift_supplied_by"
                      >
                        ForkLift Supplied By
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="forklift_supplied_by"
                          name="forklift_supplied_by"
                          value={values.forklift_supplied_by}
                          onChange={(e) => {
                            setFieldValue(
                              "forklift_supplied_by",
                              e.target.value
                            );
                          }}
                        >
                          <option value={""}>
                            No forklift supplied by selected
                          </option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "ForkLift_Supplied"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.forklift_supplied_by &&
                      touched.forklift_supplied_by ? (
                        <div className="text-red-700">
                          {errors.forklift_supplied_by}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="md:grid md:grid-cols-2 gap-[20px]">
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="deliver_to"
                      >
                        Deliver To
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="deliver_to"
                          name="deliver_to"
                          value={values.deliver_to}
                          onChange={(e) => {
                            setFieldValue("deliver_to", e.target.value);
                          }}
                        >
                          <option value={""}>No deliver to selected</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Deliver_To"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.deliver_to && touched.deliver_to ? (
                        <div className="text-red-700">{errors.deliver_to}</div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="skips"
                      >
                        Skips
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="skips"
                          name="skips"
                          value={values.skips}
                          onChange={(e) => {
                            setFieldValue("skips", e.target.value);
                          }}
                        >
                          <option value={""}>No skips selected</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Skips"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.skips && touched.skips ? (
                        <div className="text-red-700">{errors.skips}</div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <div className="flex items-center mb-4">
                        <Field
                          id="roller_doors_required"
                          type="checkbox"
                          checked={values.roller_doors_required}
                          onChange={(e) =>
                            setFieldValue(
                              "roller_doors_required",
                              e.target.checked
                            )
                          }
                          className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="roller_doors_required"
                          className="ms-2 text-sm font-medium text-[#9E9E9E]"
                        >
                          Roller Doors Required
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[100%] md:w-[50%] flex flex-col gap-[20px]">
                  <div className="md:grid md:grid-cols-1 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6">
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="terms"
                      >
                        Terms
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="terms"
                          name="terms"
                          value={values.terms}
                          onChange={(e) => {
                            setFieldValue("terms", e.target.value);
                          }}
                        >
                          <option value={""}>No terms selected</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Terms"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.terms && touched.terms ? (
                        <div className="text-red-700">{errors.terms}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6">
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="swing_door_type"
                      >
                        Swing Door Type
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="swing_door_type"
                          name="swing_door_type"
                          value={values.swing_door_type}
                          onChange={(e) => {
                            setFieldValue("swing_door_type", e.target.value);
                          }}
                        >
                          <option value={""}>
                            No swing door type selected
                          </option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Swing_Door_Type"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="swing_door_latch"
                      >
                        Swing Door Latch
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="swing_door_latch"
                          name="swing_door_latch"
                          value={values.swing_door_latch}
                          onChange={(e) => {
                            setFieldValue("swing_door_latch", e.target.value);
                          }}
                        >
                          <option value={""}>
                            No swing door latch selected
                          </option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Swing_Door_Latch"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="roller_door_latch"
                      >
                        Roller Door Latch
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="roller_door_latch"
                          name="roller_door_latch"
                          value={values.roller_door_latch}
                          disabled={!values.roller_doors_required}
                          onChange={(e) => {
                            setFieldValue("roller_door_latch", e.target.value);
                          }}
                        >
                          <option value={""}>
                            No roller door latch selected
                          </option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Roller_Door_Latch"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="jamb_details"
                      >
                        Jamb Details
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="jamb_details"
                          name="jamb_details"
                          value={values.jamb_details}
                          onChange={(e) => {
                            setFieldValue("jamb_details", e.target.value);
                          }}
                        >
                          <option value={""}>No jamb detail selected</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Site_Details" &&
                                option.field_name === "Jamb_Details"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:grid md:grid-cols-4 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 mb-[30px]">
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="corner_guard"
                  >
                    Corner Guards
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="corner_guard"
                      value={values.corner_guard}
                      name="corner_guard"
                      onChange={(e) => {
                        setFieldValue("corner_guard", e.target.value);
                      }}
                    >
                      <option value={""}>No corner guard selected</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "Corner_Guard"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="corner_guard_height"
                  >
                    Corner Guard Height
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="corner_guard_height"
                      name="corner_guard_height"
                      value={values.corner_guard_height}
                      onChange={(e) => {
                        setFieldValue("corner_guard_height", e.target.value);
                      }}
                    >
                      <option value={""}>
                        No corner guard height selected
                      </option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "Corner_Guard_Height"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="kicker_guard"
                  >
                    Kicker Guard
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="kicker_guard"
                      name="kicker_guard"
                      value={values.kicker_guard}
                      onChange={(e) => {
                        setFieldValue("kicker_guard", e.target.value);
                      }}
                    >
                      <option value={""}>No kicker guard selected</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "Kicker_Guard"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="kicker_guard_height"
                  >
                    Kicker Guard Height
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="kicker_guard_height"
                      name="kicker_guard_height"
                      value={values.kicker_guard_height}
                      onChange={(e) => {
                        setFieldValue("kicker_guard_height", e.target.value);
                      }}
                    >
                      <option value={""}>
                        No kicker guard height selected
                      </option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "Kicker_Guard_Height"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="white_corridor_type"
                  >
                    White Corridor Type
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="white_corridor_type"
                      name="white_corridor_type"
                      value={values.white_corridor_type}
                      onChange={(e) => {
                        setFieldValue("white_corridor_type", e.target.value);
                      }}
                    >
                      <option value={""}>
                        No white corridor type selected
                      </option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "White_Corridor_Type"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="aluzinc_type"
                  >
                    Aluzinc Type
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="aluzinc_type"
                      name="aluzinc_type"
                      value={values.aluzinc_type}
                      onChange={(e) => {
                        setFieldValue("aluzinc_type", e.target.value);
                      }}
                    >
                      <option value={""}>No aluzinc type selected</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "Aluzinc_Type"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="mesh_type"
                  >
                    Mesh Type
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="mesh_type"
                      name="mesh_type"
                      value={values.mesh_type}
                      onChange={(e) => {
                        setFieldValue("mesh_type", e.target.value);
                      }}
                    >
                      <option value={""}>No mesh type selected</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "Mesh_Type"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="soffit_type"
                  >
                    Soffit Type
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="soffit_type"
                      name="soffit_type"
                      value={values.soffit_type}
                      onChange={(e) => {
                        setFieldValue("soffit_type", e.target.value);
                      }}
                    >
                      <option value={""}>No soffit type selected</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "Site_Details" &&
                            option.field_name === "Soffit_Type"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px] overflow-scroll">
                <div className="mb-[30px]">Areas</div>
                {/* {areas.map((_, index) => ( */}
                <div className="grid grid-cols-5 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                    Area name
                  </div>
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                    Roller Door Colour
                  </div>
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                    Swing Door Colour
                  </div>
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                    Jamb Colour
                  </div>
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                    Floor Type
                  </div>
                </div>
                <FieldArray
                  name="site_areas"
                  render={(arrayHelpers) => (
                    <>
                      <div className="overflow-x-scroll">
                        <div className="w-[250%] md:w-full">
                          {values.site_areas.map((sitearea, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-5 items-center gap-[20px] mb-[20px]"
                              data-id={index}
                            >
                              <div className="mb-2 hidden">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`site_areas[${index}].id`}
                                    name={`site_areas[${index}].id`}
                                  ></Field>
                                </div>
                                <ErrorMessage
                                  name={`site_areas[${index}].id`}
                                />
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`site_areas[${index}].area_name`}
                                    name={`site_areas[${index}].area_name`}
                                  ></Field>
                                </div>
                                <ErrorMessage
                                  name={`site_areas[${index}].area_name`}
                                />
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`site_areas[${index}].rd_colour`}
                                    name={`site_areas[${index}].rd_colour`}
                                    disabled={!values.roller_doors_required}
                                  >
                                    <option value={""}>
                                      No roller door colour selected
                                    </option>
                                    {options
                                      .filter(
                                        (option) =>
                                          option.ui_name === "Site_Details" &&
                                          option.field_name ===
                                            "Roller_Door_Colour"
                                      )
                                      .map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.id}
                                          selected={
                                            option.is_default ? true : false
                                          }
                                        >
                                          {option.field_code}
                                        </option>
                                      ))}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`site_areas[${index}].sd_colour`}
                                    name={`site_areas[${index}].sd_colour`}
                                    // onChange={(e) => {
                                    //   setFieldValue("sd_colour", e.target.value);
                                    // }}
                                  >
                                    <option value={""}>
                                      No swing door colour selected
                                    </option>
                                    {options
                                      .filter(
                                        (option) =>
                                          option.ui_name === "Site_Details" &&
                                          option.field_name ===
                                            "Swing_Door_Colour"
                                      )
                                      .map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.id}
                                          selected={
                                            option.is_default ? true : false
                                          }
                                        >
                                          {option.field_code}
                                        </option>
                                      ))}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`site_areas[${index}].jamb_color`}
                                    name={`site_areas[${index}].jamb_color`}
                                  >
                                    <option value={""}>
                                      No jamb colour selected
                                    </option>
                                    {options
                                      .filter(
                                        (option) =>
                                          option.ui_name === "Site_Details" &&
                                          option.field_name === "Jamb_Colour"
                                      )
                                      .map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.id}
                                          selected={
                                            option.is_default ? true : false
                                          }
                                        >
                                          {option.field_code}
                                        </option>
                                      ))}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="grid grid-cols-4 gap-[10px] items-center">
                                  <div className="relative col-span-3">
                                    <Field
                                      as="select"
                                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      id={`site_areas[${index}].hammer_fixing`}
                                      name={`site_areas[${index}].hammer_fixing`}
                                    >
                                      <option value={""}>
                                        No Floor Type selected
                                      </option>
                                      {options
                                        .filter(
                                          (option) =>
                                            option.ui_name === "Site_Details" &&
                                            option.field_name ===
                                              "Hammer_Fixings"
                                        )
                                        .map((option) => (
                                          <option
                                            key={option.id}
                                            value={option.id}
                                            selected={
                                              option.is_default ? true : false
                                            }
                                          >
                                            {option.field_code}
                                          </option>
                                        ))}
                                    </Field>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                      <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                      </svg>
                                    </div>
                                  </div>
                                  {values.site_areas.length > 1 && (
                                    <div>
                                      <span
                                        className="text-red-400 hover:text-red-700 p-3 bg-white rounded-lg cursor-pointer"
                                        onClick={() => {
                                          if (!sitearea.uniqueId) {
                                            arrayHelpers.remove(index);
                                          } else {
                                            handleDeleteSiteArea(sitearea.id);
                                            arrayHelpers.remove(index);
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          size="xl"
                                        />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          {errors.site_areas &&
                            typeof errors.site_areas === "string" && (
                              <div className="text-red-400">
                                {errors.site_areas}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="text-right p-[16px]">
                        <span
                          className="cursor-pointer text-[#55A14A]"
                          onClick={() =>
                            arrayHelpers.push({
                              id: "",
                              area_name: "",
                              rd_colour: "",
                              sd_colour: "",
                              jamb_color: "",
                              hammer_fixing: "",
                            })
                          }
                        >
                          + Add New Area
                        </span>
                      </div>
                    </>
                  )}
                />
              </div>

              {/* <div className="md:grid md:grid-cols-4 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 items-center">
                <div className="mb-[8px] md:mb-0">
                  <div className="flex items-center">
                    <Field
                      id="filler_angle"
                      type="checkbox"
                      checked={values.filler_angle}
                      onChange={(e) =>
                        setFieldValue("filler_angle", e.target.checked)
                      }
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="filler_angle"
                      className="ms-2 text-sm font-medium text-[#9E9E9E]"
                    >
                      Filler Angle
                    </label>
                  </div>
                </div>

                <div className="mb-[8px] md:mb-0">
                  <div className="flex items-center">
                    <Field
                      id="bottom_channel_insert"
                      type="checkbox"
                      checked={values.bottom_channel_insert}
                      onChange={(e) =>
                        setFieldValue("bottom_channel_insert", e.target.checked)
                      }
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="bottom_channel_insert"
                      className="ms-2 text-sm font-medium text-[#9E9E9E]"
                    >
                      Bottom Channel Insert
                    </label>
                  </div>
                </div>
                <div className="mb-[8px] md:mb-0">
                  <div className="flex items-center">
                    <Field
                      id="ceiling_brackets"
                      type="checkbox"
                      checked={values.ceiling_brackets}
                      onChange={(e) =>
                        setFieldValue("ceiling_brackets", e.target.checked)
                      }
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="ceiling_brackets"
                      className="ms-2 text-sm font-medium text-[#9E9E9E]"
                    >
                      Ceiling Brackets
                    </label>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center">
                    <Field
                      id="cdm_regs_required"
                      type="checkbox"
                      checked={values.cdm_regs_required}
                      onChange={(e) =>
                        setFieldValue("cdm_regs_required", e.target.checked)
                      }
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="cdm_regs_required"
                      className="ms-2 text-sm font-medium text-[#9E9E9E]"
                    >
                      CDM Regs Required
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting || proposalStatus
                        ? "bg-gray-300"
                        : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting || proposalStatus}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditDrawingSiteDetail;
