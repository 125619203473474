import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeAuthUser } from "../../store/reducer/auth/authSlice";

function Header({ isSidebarOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const handleLogout = () => {
    dispatch(removeAuthUser());
    localStorage.removeItem("userSession");
    navigate("/");
  };
  useEffect(() => {
    setUserName(
      JSON.parse(localStorage.getItem("userSession")).firstname +
        " " +
        JSON.parse(localStorage.getItem("userSession")).lastname
    );
    setProfileImg(JSON.parse(localStorage.getItem("userSession")).profile_img);
  }, []);
  return (
    <div className="header flex flex-row justify-between p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
      <div className="flex gap-[30px]">
        <button className="text-black" onClick={toggleSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isSidebarOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>
        <h1 className="text-[24px] font-[600] text-[#9a9a9a] hidden md:block">
          Welcome {userName}
        </h1>
      </div>
      <div>
        <div className="flex flex-row gap-[8px]">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/icons/message.png"}
              className="w-[48px] h-[48px] rounded-[12px]"
              alt=""
            />
          </div>
          <div>
            <img
              src={
                profileImg
                  ? profileImg
                  : process.env.PUBLIC_URL + "/icons/profile.png"
              }
              className="w-[48px] h-[48px] rounded-[12px]"
              alt=""
            />
          </div>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/icons/logout.png"}
              className="w-[48px] h-[48px] rounded-[12px]"
              alt=""
              onClick={handleLogout}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
