import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import { listOpportunities } from "../../store/reducer/opportunity/opportunitySlice";
import {
  listDrawings,
  searchDrawings,
} from "../../store/reducer/drawing/drawingSlice";
import moment from "moment";

function ListDrawings() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const formRef = useRef(null);
  const {
    loading,
    drawings = [],
    totalCount,
  } = useSelector((state) => state.drawing);
  const { options = [] } = useSelector((state) => state.ddOptions);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDeleted, setIsDeleted] = useState(false);

  const [customerData, setCustomerData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
    dispatch(getUserForUI()).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
    dispatch(listDrawings({ search, page, limit }));
  }, []);

  const handleSearch = (search = "") => {
    dispatch(listDrawings({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [drawings]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listDrawings({ search, page, limit }));
  };

  const handleResetFilterForm = () => {
    if (formRef.current) {
      formRef.current.resetForm();
      dispatch(listDrawings({ search, page, limit }));
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const customer_id = values.customer_id;
    const drawing_status = values.drawing_status;
    const date_required = values.date_required;

    await dispatch(
      searchDrawings({
        customer_id,
        drawing_status,
        date_required,
      })
    );
  };

  return (
    <div className="flex flex-col mt-[20px] gap-[40px]">
      <div className="bg-grey md:flex gap-[10px]">
        <div className="w-full md:w-[28%] md:flex flex-col justify-between">
          <div className="h-[114px] bg-[#5E6FF0] rounded-[12px] border-[10px] border-[#5E6FF0] p-[20px] flex items-center justify-between mb-3">
            <div className="flex flex-col gap-[10px]">
              <span className="text-[32px] leading-[40px] text-white font-Mulish font-[700]">
                {totalCount}
              </span>
              <span className="text-[16px] text-white font-Mulish font-[700]">
                Records returned
              </span>
            </div>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/icons/pad.png"}
                className="w-[88px] h-[88px]"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-[72%] md:flex gap-[24px] flex-col bg-[#F8F8F8] rounded-[12px] p-6">
          <div className="flex flex-row justify-between pb-[20px] border-b border-[#E1E1E1] border-solid">
            <div>
              <span className="font-Inter font-[600] text-[18px] leading-[21px] text-[#1C1C20]">
                Search Your Drawing
              </span>
            </div>
            <div>
              <span className="cursor-pointer" onClick={handleResetFilterForm}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/reset.png"}
                  alt=""
                  className="w-[24px] cursor-pointer"
                />
              </span>
            </div>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={{
              customer_id: "",
              drawing_status: "",
              date_required: "",
            }}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              values,
              errors,
              touched,
              setFieldValue,
              resetForm,
              handleSubmit,
            }) => (
              <Form>
                <div className="md:grid md:grid-cols-3 gap-3">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="customer_id"
                    >
                      Customer
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="customer_id"
                        name="customer_id"
                        onChange={(e) => {
                          setFieldValue("customer_id", e.target.value);
                        }}
                      >
                        <option value={""}>Select Customer</option>
                        {customerData.map((customer) => (
                          <option value={customer.id} key={customer.id}>
                            {customer.customer_name}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="drawing_status"
                    >
                      Drawing Status
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="drawing_status"
                        name="drawing_status"
                        onChange={(e) => {
                          setFieldValue("drawing_status", e.target.value);
                        }}
                      >
                        <option value={""}>Select Status</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Drawing" &&
                              option.field_name === "Status"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="date_required"
                    >
                      Date Required
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      id="date_required"
                      name="date_required"
                      onChange={(e) => {
                        setFieldValue("date_required", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex bg-[#F8F8F8] rounded-[12px] mt-2">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[10px] px-[70px] text-center text-[#55A14A] border border-[#55A14A] w-full rounded-[12px] text-[16px] ${
                        isSubmitting ? "bg-gray-300" : "bg-white"
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <div className="w-[30%] bg-[#000] rounded-[12px]"></div> */}
      </div>
      <div className="">
        <div className="flex justify-between items-center">
          <div className="leading-[30px] font-Mulish font-[700] text-[24px]">
            <span className="">List Of Drawings</span>
          </div>
        </div>
        <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
          <div className="flex flex-col">
            <div className="md:flex justify-end items-center p-[12px]">
              <div className="relative">
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="search"
                  name="search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debouncedSearch(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/search.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="sm:rounded-lg mt-5 overflow-scroll">
              <table className="w-full">
                <thead className="bg-white">
                  <tr className="rounded-[12px]">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Id
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Site
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Date Requested
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Date Required
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                ) : (
                  <tbody>
                    {drawings.map((drawing) => (
                      <tr key={drawing.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                          {drawing.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {drawing.customer_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {drawing.site_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {moment(drawing.date_requested).format("DD-MM-yyyy")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {moment(drawing.date_required).format("DD-MM-yyyy")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {drawing.status_name}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-right font-medium">
                          <div className="flex gap-[10px]">
                            <div>
                              <Link
                                to={`/opportunity/${drawing.opportunity_id}/drawing/${drawing.id}`}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/icons/edit.png"
                                  }
                                  alt=""
                                  className="w-[40px] bg-teal-100 rounded-lg"
                                />
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center "
          />
        </div>
      </div>
    </div>
  );
}

export default ListDrawings;
