import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { getDiscountByDrawingId } from "../../store/reducer/drawing/drawingDiscountSlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import {
  getPandLByDrawingId,
  getGeneratePandLStatus,
  generatePandL,
} from "../../store/reducer/drawing/drawingPandLSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import Notes from "../Notes/Notes";

function EditDrawingPandL() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [pandlData, setPandlData] = useState([]);
  const [supplierListData, setSupplierListData] = useState([]);
  const [generatePandLStatus, setGeneratePandLStatus] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [proposalStatus, setProposalStatus] = useState(true);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });

    dispatch(getDiscountByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setDiscountData(result.payload);
      }
    });

    dispatch(getPandLByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setSupplierListData(result.payload.supplierListData);
        setPandlData(result.payload.pandlData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    dispatch(getGeneratePandLStatus(drawing_id)).then((result) => {
      if (result.payload.hasData) {
        setGeneratePandLStatus(true);
      } else {
        setGeneratePandLStatus(false);
      }
    });
  }, []);

  const handleGeneratePandL = () => {
    setLoading(true);
    dispatch(generatePandL(drawingData.uniqueId)).then((result) => {
      if (result.payload) {
        dispatch(getGeneratePandLStatus(drawing_id)).then((result) => {
          if (result.payload.hasData) {
            setGeneratePandLStatus(true);
          } else {
            setGeneratePandLStatus(false);
          }
        });
        setLoading(false);
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  let totalCost = 0;
  let discountCost = 0;
  let totalSell = 0;
  let discountSell = 0;
  let totalMargin = 0;
  let discountMargin = 0;
  let discount_per = discountData.is_approved ? discountData.discount : 0;

  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing P&L / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              P&L
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-pandl" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {!loading ? (
        <>
          <div className="border border-gray-200 p-4 rounded-lg overflow-auto">
            <div className="flex gap-[20px] justify-between mb-2">
              <div className="col-span-4">
                <div className="flex gap-[30px] p-2 text-nowrap overflow-x-scroll">
                  {generatePandLStatus ? (
                    supplierListData.length ? (
                      supplierListData
                        .sort((a, b) => 
                          a.supplier_name === "Proposal Pricing" ? -1 : 1
                        )
                        .map((supplierList) => {
                          return (
                            <span
                              className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                                activeTab === supplierList.bom_supplier_id
                                  ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                                  : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                              }`}
                              key={supplierList.bom_supplier_id}
                              onClick={() =>
                                handleTabClick(supplierList.bom_supplier_id)
                              }
                              data_id={supplierList.bom_supplier_id}
                            >
                              {supplierList.supplier_name}
                            </span>
                          );
                        })
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )
                  ) : (
                    "No Data available"
                  )}
                </div>
              </div>
              {!proposalStatus && (
                <div className="grid-cols-1">
                  <button onClick={handleGeneratePandL}>
                    <div className="py-[10px] px-[40px] font-[500] font-Inter text-[18px] text-center text-[#17171B] rounded-[12px] text-[#55A14A] border border-[#55A14A]">
                      <span>Generate P&L</span>
                    </div>
                  </button>
                </div>
              )}
            </div>

            {generatePandLStatus && (
              <div className="w-[800px]">
                <div className="grid grid-cols-6 gap-[30px] justify-between bg-[#F8F8F8] p-3 rounded mb-4">
                  <div>No.</div>
                  <div className="col-span-2">Product Type</div>
                  <div>Cost</div>
                  <div>Sell</div>
                  <div>Margin</div>
                </div>
                {pandlData.length ? (
                  pandlData
                    .filter((pandl, index) => pandl.bom_supplier_id === activeTab)
                    .map((pandl, index) => {
                      totalCost = totalCost + Number(pandl.total_cost);
                      totalSell = totalSell + Number(pandl.total_price);
                      totalMargin = totalMargin + Number(pandl.total_margin);

                      return (
                        <div className="grid grid-cols-6 gap-[30px] items-center p-5 mb-2" key={index}>
                          <div>{index + 1}</div>
                          <div className="col-span-2">{pandl.item_group_name}</div>
                          <div>{parseFloat(pandl.total_cost).toFixed(2)}</div>
                          <div>{parseFloat(pandl.total_price).toFixed(2)}</div>
                          <div>{(pandl.total_margin * 100).toFixed(2)}%</div>
                        </div>
                      );
                    })
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faSpinner} size="2xl" style={{ color: "#55A14A" }} spin />
                  </div>
                )}

                <div className="border b-[#F8F8F8] rounded-lg p-5">
                  <div className="grid grid-cols-6 gap-[30px] mb-3">
                    <div className="font-[600] col-span-3">Totals</div>
                    <div>{parseFloat(totalCost).toFixed(2)}</div>
                    <div>{parseFloat(totalSell).toFixed(2)}</div>
                    <div>{parseFloat(((totalSell - totalCost) / totalSell) * 100).toFixed(2)}%</div>
                  </div>
                  <div className="grid grid-cols-6 gap-[30px] mb-3">
                    <div className="font-[600] col-span-3">Discounts</div>
                    <div></div>
                    <div>
                      {parseFloat((totalSell * discountData.discount) / 100).toFixed(2)}
                    </div>
                    <div></div>
                  </div>
                  <div className="grid grid-cols-6 mb-3 gap-[30px] text-[#55A24A]">
                    <div className="font-[600] col-span-3">Total Less Discount</div>
                    <div>{parseFloat(totalCost).toFixed(2)}</div>
                    <div>
                      {parseFloat(totalSell - (totalSell * discountData.discount) / 100).toFixed(2)}
                    </div>
                    <div>
                      {parseFloat(
                        (((totalSell - (totalSell * discountData.discount) / 100) - totalCost) /
                          (totalSell - (totalSell * discountData.discount) / 100)) *
                          100
                      ).toFixed(2)}%
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditDrawingPandL;
