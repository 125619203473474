import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn, useFormikContext } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { getSiteByDrawingId } from "../../store/reducer/drawing/drawingSiteSlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import { getOptionCostByOptionId } from "../../store/reducer/optionCosts/optionCostsSlice";
import {
  deleteAdditionalOption,
  getAdditionalOptionByDrawingId,
  updateDrawingAdditionalOption,
  getAdditionalOptionItems,
} from "../../store/reducer/drawing/drawingAdditionalOptionSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import Notes from "../Notes/Notes";

const ExtraOptionsModal = ({ isOpen, onClose, optionIndex }) => {
  const { values, setFieldValue } = useFormikContext();

  if (!isOpen || optionIndex === null) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative w-full max-w-2xl h-full max-h-[80vh] overflow-auto">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-2xl"
        >
          &times;
        </button>
        <h2 className="text-xl font-semibold mb-4">Extra Options</h2>
        {optionIndex !== null && (
          <div>
            <FieldArray
              name={`options[${optionIndex}].extraOptions`}
              render={({ remove }) => (
                <>
                  {values.options[optionIndex]?.extraOptions.length > 0 ? (
                    values.options[optionIndex].extraOptions.map((extraOption, idx) => {
                      // Use extra_option_default_value if extra_option_value is not available
                      const currentValue =
                        extraOption.extra_option_value ||
                        extraOption.extra_option_default_value;
                      return (
                        <div
                          key={idx}
                          className="mb-4 grid grid-cols-2 gap-4 items-center"
                        >
                          <div className="text-gray-800 font-medium">
                            {extraOption.extra_option_name}
                          </div>
                          {String(extraOption.type).toLowerCase() === 'boolean' ? (
                            <Field
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-green-600"
                              name={`options[${optionIndex}].extraOptions[${idx}].extra_option_value`}
                              checked={currentValue === 'yes'}
                              onChange={() => {
                                // Toggle boolean value
                                setFieldValue(
                                  `options[${optionIndex}].extraOptions[${idx}].extra_option_value`,
                                  currentValue === 'yes' ? 'no' : 'yes'
                                );
                              }}
                            />
                          ) : String(extraOption.type).toLowerCase() === 'number' ? (
                            <Field
                              type="number"
                              className="block appearance-none w-full bg-gray-100 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg leading-tight focus:outline-none focus:ring-2 focus:ring-green-500"
                              id={`options[${optionIndex}].extraOptions[${idx}].extra_option_value`}
                              name={`options[${optionIndex}].extraOptions[${idx}].extra_option_value`}
                              value={currentValue}
                            />
                          ) : (
                            <Field
                              className="block appearance-none w-full bg-gray-100 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg leading-tight focus:outline-none focus:ring-2 focus:ring-green-500"
                              name={`options[${optionIndex}].extraOptions[${idx}].extra_option_value`}
                              type="text"
                              value={currentValue || ''}
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-gray-600">No extra options available for this item.</p>
                  )}
                </>
              )}
            />
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-[#55A14A] hover:bg-[#4a9e3a] text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

function EditDrawingAdditionalOption() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [optionData, setOptionData] = useState({});
  const [addOptData, setAddOptData] = useState({});
  const [optionItems, setOptionItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [proposalStatus, setProposalStatus] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(null);
  
  const handleOpenExtraOptionsModal = (index) => {
    setCurrentOptionIndex(index);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentOptionIndex(null);
  };
  
  useEffect(() => {
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });
    dispatch(getAdditionalOptionItems()).then((result) => {
      if (result.payload) {
        setOptionItems(result.payload);
      }
    });
    dispatch(getSiteByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setSiteData(result.payload);
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getDdOptionForUI());
  }, []);

  useEffect(() => {
    if (optionItems.length > 0 && options.length > 0) {
      dispatch(getAdditionalOptionByDrawingId(drawing_id)).then((result) => {
        if (result.payload) {
          const updatedPayload = result.payload.map((option) => {
            if (option.extraOptions?.length === 0) {
              const item = optionItems.find((item) => item.id === option.item_id);
              if (item) {
                const newExtraOptions = options
                  .filter(
                    (opt) =>
                      opt.ui_name === 'Proposal' &&
                      opt.field_name === item.item_description
                  )
                  .map((opt) => ({
                    extra_option_id: opt.id,
                    extra_option_name: opt.field_value,
                    type: opt.datatype,
                    extra_option_value: String(opt.datatype).toLowerCase() === 'boolean' ? 'no' : null,
                    sort_field: opt.sort_field
                  }));
                return { ...option, extraOptions: newExtraOptions };
              }
            }
            return option;
          });
          setAddOptData(updatedPayload);
          setLoading(false);
        }
      });
    }
  }, [dispatch, drawing_id, optionItems, options]);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    options: Yup.array()
      .of(
        Yup.object().shape({
          item_id: Yup.string().required("please enter option name"),
          sq_m: Yup.number("Sq m must be number"),
        })
      )
      .required("Must have option name"),
  });

  const initialValues = {
    options: addOptData.length
      ? addOptData
      : [
          {
            id: "",
            item_id: "",
            item_description: "",
            type: "",
            area_id: "",
            sq_m: "",
            cost: "",
            margin_id: "",
            sell: "",
            extraOptions: []
          },
        ],
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.drawing_id = Number(drawing_id);
    values.drawing_uniqueId = drawingData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "options") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });

    await dispatch(
      updateDrawingAdditionalOption({ data: formData, id: drawing_id })
    ).then((result) => {
      if (result.payload) {
        dispatch(getAdditionalOptionByDrawingId(drawing_id)).then((result) => {
          if (result.payload) {
            setAddOptData(result.payload);
            resetForm({ values: { options: result.payload } })
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleDeleteAddOpt = (id) => {
    dispatch(deleteAdditionalOption({ id })).then((result) => {
      if (result.payload) {
        dispatch(getAdditionalOptionByDrawingId(drawing_id)).then((result) => {
          if (result.payload) {
            setAddOptData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing Additional Option / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Additional Options
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-additional-options" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <Form>
                <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
                  <div className="mb-[30px]">Additional Options</div>
                  <div className="grid grid-cols-12 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
                    <div className="col-span-2 px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                      Item
                    </div>
                    <div className="col-span-2 px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Type
                    </div>
                    <div className="col-span-2 px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Area
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Sq M
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Cost
                    </div>
                    <div className="col-span-2 px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Margin
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Sell
                    </div>
                  </div>
                  <FieldArray
                    name="options"
                    render={({ insert, remove, push }) => (
                      <>
                        <div>
                          {values.options?.map((option, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-12 gap-[20px] mb-[20px]"
                              data-id={index}
                            >
                              <div className="mb-2 hidden">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`options[${index}].id`}
                                    name={`options[${index}].id`}
                                  ></Field>
                                </div>
                                <ErrorMessage name={`options[${index}].id`} />
                              </div>
                              <div className="mb-2 col-span-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`options[${index}].item_id`}
                                    name={`options[${index}].item_id`}
                                    onChange={(e) => {
                                      const selectedItemValue = e.target.value;
                                      const selectedItemText = e.target.options[e.target.selectedIndex].text;
                                  
                                      // Update item_id and item_description
                                      setFieldValue(`options[${index}].item_id`, selectedItemValue);
                                      setFieldValue(`options[${index}].item_description`, selectedItemText);
                                  
                                      // Update extraOptions after item_id and item_description have been set
                                      const newExtraOptions = options
                                        .filter(
                                          (option) =>
                                            option.ui_name === "Proposal" &&
                                            option.field_name === selectedItemText
                                        )
                                        .sort((a, b) => a.sort_field - b.sort_field) // Sorting by sort_field
                                        .map((option) => ({
                                          extra_option_id: option.id,
                                          extra_option_name: option.field_value,
                                          type: option.datatype,
                                          extra_option_value: option.default_value ? option.default_value : 
                                            (String(option.datatype).toLowerCase() === 'boolean' ? 'no' : 
                                            (String(option.datatype).toLowerCase() === 'number' ? 0 : '')),
                                          extra_option_default_value: option.default_value,
                                          sort_field: option.sort_field
                                        }));
                                      setFieldValue(`options[${index}].extraOptions`, newExtraOptions);
                                  
                                      // Dispatch action to get option cost
                                      dispatch(getOptionCostByOptionId(selectedItemValue)).then((result) => {
                                        if (result.payload) {
                                          setOptionData(result.payload);
                                        } else {
                                          setOptionData({});
                                        }
                                      });                                  
                                    }}
                                  >
                                    <option value={""}>Select item</option>
                                    {optionItems.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.item_description}
                                      </option>
                                    ))}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                                <ErrorMessage
                                  name={`options[${index}].item_id`}
                                />
                              </div>
                              <div className="mb-2 col-span-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`options[${index}].type`}
                                    name={`options[${index}].type`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `options[${index}].type`,
                                        e.target.value
                                      );
                                      setFieldValue(
                                        `options[${index}].sq_m`,
                                        ""
                                      );
                                      setFieldValue(
                                        `options[${index}].cost`,
                                        ""
                                      );
                                    }}
                                  >
                                    <option value={""}>Select type</option>
                                    <option value={"Supplier Quote"}>
                                      Supplier Quote
                                    </option>
                                    <option value={"Sq M"}>Sq M</option>
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2 col-span-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`options[${index}].area_id`}
                                    name={`options[${index}].area_id`}
                                  >
                                    <option value={""}>Select Area</option>
                                    <option value={"Whole Building"}>
                                      Whole Building
                                    </option>
                                    {siteData?.site_areas?.map((site_area) => (
                                      <option
                                        key={site_area.id}
                                        value={site_area.id}
                                      >
                                        {site_area.area_name}
                                      </option>
                                    ))}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`options[${index}].sq_m`}
                                    name={`options[${index}].sq_m`}
                                    disabled={
                                      values.options[index].type ===
                                      "Supplier Quote"
                                    }
                                    onChange={(e) => {
                                      const sq_m = e.target.value;
                                      setFieldValue(
                                        `options.${index}.sq_m`,
                                        sq_m
                                      );
                                      const totalCost = (
                                        parseFloat(sq_m || 0) *
                                        optionData.cost_per_m2
                                      ).toFixed(2);

                                      setFieldValue(
                                        `options.${index}.cost`,
                                        totalCost
                                      );
                                    }}
                                  ></Field>
                                </div>
                                <ErrorMessage name={`options[${index}].sq_m`} />
                              </div>
                              <div className="mb-2">
                                <div className="relative">
                                  <Field
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`options[${index}].cost`}
                                    name={`options[${index}].cost`}
                                  ></Field>
                                </div>
                              </div>
                              <div className="mb-2 col-span-2">
                                <div className="relative">
                                  <Field
                                    as="select"
                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`options[${index}].margin_id`}
                                    name={`options[${index}].margin_id`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `options[${index}].margin_id`,
                                        e.target.value
                                      );

                                      const cost = parseFloat(
                                        values.options[index].cost || 0
                                      );

                                      const margin = options.filter(
                                        (option) => option.id == e.target.value
                                      );
                                      const margin_val = margin[0].field_value;

                                      const sell = parseFloat(
                                        cost * (1 + margin_val / 100)
                                      ).toFixed(2);

                                      setFieldValue(
                                        `options[${index}].sell`,
                                        sell
                                      );
                                    }}
                                  >
                                    <option value={""}>Select margin</option>
                                    {options
                                      .filter(
                                        (option) =>
                                          option.ui_name ===
                                            "Additional_Options" &&
                                          option.field_name === "Margin"
                                      )
                                      .map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.id}
                                          selected={
                                            option.is_default ? true : false
                                          }
                                        >
                                          {option.field_code}
                                        </option>
                                      ))}
                                  </Field>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2 col-span-2">
                                <div className="grid grid-cols-4 gap-[10px] items-center">
                                  <div className="relative col-span-3">
                                    <Field
                                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      id={`options[${index}].sell`}
                                      name={`options[${index}].sell`}
                                    ></Field>
                                  </div>
                                  <div className="flex items-center space-x-2 col-span-1">
                                    {values.options.length > 0 && (
                                      <span
                                        className="text-red-400 hover:text-red-700 p-1 bg-white rounded-lg cursor-pointer"
                                        onClick={() => {
                                          if (values.options[index].uniqueId) {
                                            handleDeleteAddOpt(values.options[index].id);
                                            remove(index);
                                          } else {
                                            remove(index);
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} size="sm" />
                                      </span>
                                    )}
                                    <span
                                      className="text-black-400 hover:text-black-700 p-1 bg-white rounded-lg cursor-pointer"
                                      onClick={() => handleOpenExtraOptionsModal(index)}
                                    >
                                      <FontAwesomeIcon icon={faPlus} size="sm" />
                                    </span>
                                  </div>
                                </div>
                              </div>

                            </div>
                          ))}
                        </div>
                        <div className="text-right p-[16px]">
                          <span
                            className="cursor-pointer text-[#55A14A]"
                            onClick={() =>
                              push({
                                id: "",
                                item_id: "",
                                item_description: "",
                                type: "",
                                area_id: "",
                                sq_m: "",
                                cost: "",
                                margin_id: "",
                                sell: "",
                                extraOptions: []
                              })
                            }
                          >
                            + Add New Option
                          </span>
                        </div>
                      </>
                    )}
                  />
                  <ExtraOptionsModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    optionIndex={currentOptionIndex}
                    options={options}
                    values={values}
                  />

                </div>

                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                        isSubmitting || proposalStatus
                          ? "bg-gray-300"
                          : "bg-[#1C1C20]"
                      }`}
                      disabled={isSubmitting || proposalStatus}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditDrawingAdditionalOption;
