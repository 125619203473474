import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getCustomerById } from "../../store/reducer/customers/customersSlice";
import {
  getCustomerContactById,
  updateCustomerContact,
} from "../../store/reducer/customers/customerContactSlice";

function EditCustomerContact() {
  const { id, contact_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [customerData, setCustomerData] = useState([]);
  const [customerContactData, setCustomerContactData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getCustomerById(id)).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
    dispatch(getCustomerContactById(contact_id)).then((result) => {
      if (result.payload) {
        setCustomerContactData(result.payload);
        setLoading(false);
      }
    });
  }, []);

  const initialValues = {
    firstname: customerContactData?.firstname || "",
    lastname: customerContactData?.lastname || "",
    email: customerContactData?.email || "",
    phone: customerContactData?.phone || "",
    position: customerContactData?.position || "",
    direction: customerContactData?.direction || "",
    status: customerContactData?.status || "",
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Please enter first name"),
    lastname: Yup.string().required("Please enter last name"),
    phone: Yup.string().required("Please enter phone"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter email"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.customer_id = customerData.id;
    values.customer_uniqueId = customerData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateCustomerContact({ data: formData, contact_id })).then(
      (result) => {
        if (result.payload) {
          navigate(`/customer/${id}/contacts`);
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };
  return (
    <>
      <div className="block md:grid grid-cols-3 mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[8px] md:mb-0">
          <Link to={`/customer/${id}/contacts`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Customer / Add Contact
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Add Customer Contact
            </span>
          </div>
        </div>
        <div className="font-Inter font-[700] leading-[30px] text-[24px] text-center">
          {customerData.id} - {customerData.customer_name}
        </div>
      </div>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="flex gap-[20px]">
                <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="firstname"
                    >
                      First Name
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="firstname"
                      name="firstname"
                      onChange={(e) => {
                        setFieldValue("firstname", e.target.value);
                      }}
                    />
                    {errors.firstname && touched.firstname ? (
                      <div className="text-red-700">{errors.firstname}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="lastname"
                    >
                      Last Name
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="lastname"
                      name="lastname"
                      onChange={(e) => {
                        setFieldValue("lastname", e.target.value);
                      }}
                    />
                    {errors.lastname && touched.lastname ? (
                      <div className="text-red-700">{errors.lastname}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="email"
                    >
                      Email
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="email"
                      name="email"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {errors.email && touched.email ? (
                      <div className="text-red-700">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="phone"
                    >
                      Phone
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="phone"
                      name="phone"
                      onChange={(e) => {
                        setFieldValue("phone", e.target.value);
                      }}
                    />
                    {errors.phone && touched.phone ? (
                      <div className="text-red-700">{errors.phone}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="position"
                    >
                      Position
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="position"
                      name="position"
                      onChange={(e) => {
                        setFieldValue("position", e.target.value);
                      }}
                    />
                    {errors.position && touched.position ? (
                      <div className="text-red-700">{errors.position}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="direction"
                    >
                      Direction
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="direction"
                      name="direction"
                      onChange={(e) => {
                        setFieldValue("direction", e.target.value);
                      }}
                    />
                    {errors.direction && touched.direction ? (
                      <div className="text-red-700">{errors.direction}</div>
                    ) : null}
                  </div>

                  <div>
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <div>
                      <label
                        htmlFor="status"
                        className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                      >
                        <input
                          id="status"
                          type="checkbox"
                          className="hidden peer"
                          name="status"
                          defaultChecked={values.status === "Active"}
                          onChange={(e) => {
                            const checkedValue = e.target.checked
                              ? "Active"
                              : "Inactive";
                            setFieldValue("status", checkedValue);
                          }}
                        />
                        <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                          Inactive
                        </span>
                        <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                          Active
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditCustomerContact;
