import React from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addDdOption } from "../../../store/reducer/ddOption/ddOptionSlice";

function AddDdOption() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    ui_name: Yup.string().required("please enter ui name"),
    field_name: Yup.string().required("please enter field name"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addDdOption(formData)).then((result) => {
      if (result.payload) {
        resetForm();
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/setting/dd-option">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              DropDown / Add Option
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Add DropDown Option
            </span>
          </div>
        </div>
      </div>
      <Formik
        initialValues={{
          ui_name: "",
          field_name: "",
          field_code: "",
          field_value: "",
          field_desc: "",
          is_default: false,
          sort_field: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <div className="flex gap-[20px]">
              <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="ui_name"
                  >
                    UI Name
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="ui_name"
                    name="ui_name"
                    onChange={(e) => {
                      setFieldValue("ui_name", e.target.value);
                    }}
                  />
                  {errors.ui_name && touched.ui_name ? (
                    <div className="text-red-700">{errors.ui_name}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="field_name"
                  >
                    Field Name
                  </label>

                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="field_name"
                    name="field_name"
                    onChange={(e) => {
                      setFieldValue("field_name", e.target.value);
                    }}
                  />
                  {errors.field_name && touched.field_name ? (
                    <div className="text-red-700">{errors.field_name}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="field_code"
                  >
                    Field Code
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="field_code"
                    name="field_code"
                    onChange={(e) => {
                      setFieldValue("field_code", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="field_value"
                  >
                    Field Value
                  </label>
                  <Field
                    type="text"
                    name="field_value"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setFieldValue("field_value", e.target.value);
                    }}
                  />
                </div>

                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="field_desc"
                  >
                    Description
                  </label>
                  <Field
                    as="textarea"
                    name="field_desc"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setFieldValue("field_desc", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="sort_field"
                  >
                    Sort Field
                  </label>
                  <Field
                    type="number"
                    name="sort_field"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      setFieldValue("sort_field", e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="is_default"
                  >
                    Is Default
                  </label>
                  <div>
                    <label
                      htmlFor="is_default"
                      className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                    >
                      <input
                        id="is_default"
                        type="checkbox"
                        className="hidden peer"
                        name="is_default"
                        // defaultChecked
                        onChange={(e) => {
                          const checkedValue = e.target.checked ? true : false;
                          setFieldValue("is_default", checkedValue);
                        }}
                      />
                      <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                        No
                      </span>
                      <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                        Yes
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
              <div>
                <button
                  type="submit"
                  name="submit"
                  className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddDdOption;
