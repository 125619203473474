import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const generateProposal = createAsyncThunk(
  "generateProposal",
  async ({ drawing_uniqueId, data }) => {
    const request = await api(
      `api/admin/generateproposal/${drawing_uniqueId}`,
      data,
      "post"
    );
    return request;
  }
);

export const getGeneratedProposalData = createAsyncThunk(
  "getGeneratedProposalData",
  async (data) => {
    const request = await api(
      `api/admin/generatedproposaldata/${data}`,
      "",
      "get"
    );
    return request;
  }
);

export const sendDocumentForSigning = createAsyncThunk(
  "sendDocumentForSigning",
  async (data) => {
    const request = await api(`api/admin/senddocumentforsigning`, data, "post");
    return request;
  }
);

export const cancelInvite = createAsyncThunk("cancelInvite", async (data) => {
  const request = await api(`api/admin/cancelinvite`, data, "post");
  return request;
});

export const downloadSignedDocument = createAsyncThunk(
  "downloadSignedDocument",
  async (data) => {
    const request = await api(`api/admin/downloadsigneddocument`, data, "post");
    return request;
  }
);

export const proposalStatusByDrawingId = createAsyncThunk(
  "proposalStatusByDrawingId",
  async (data) => {
    console.log(data);
    const request = await api(
      `api/admin/generatedproposalstatus/${data}`,
      {},
      "get"
    );
    return request;
  }
);
