import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faSpinner,
  faTimes, 
  faNoteSticky,
  faAngleRight,
  faAngleLeft,
  faEllipsis 
} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { addNote, listNotes, deleteNote } from "../../store/reducer/notes/noteSlice";
import { useDispatch, useSelector } from "react-redux";

Modal.setAppElement("#root"); // Set app element for accessibility

function formatDate(isoDateString) {
  // Create a Date object from the ISO string
  const date = new Date(isoDateString);

  // Extract day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Format the date as DD-MM-YYYY HH:mm
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

function Notes({drawing_id, type}) {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const {
    loading,
    notes = [],
    totalCount,
  } = useSelector((state) => state.notes);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const validationSchema = Yup.object().shape({
    note_text: Yup.string().required("Please enter note text."),
  });

  const initialValues = {
    note_text: ""
  };

  useEffect(() => {
    dispatch(listNotes({ drawing_id, type, page, limit }));
  }, [])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    values.drawing_id = drawing_id;
    values.type = type;
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });
    await dispatch(addNote(formData)).then((result) => {
      if (result.payload) {
        resetForm();
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
      setSubmitting(false);
    });
  };

  const openModal = () => {
    setModalIsOpen(true);
  }
  const closeModal = () => setModalIsOpen(false);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listNotes({ drawing_id, type, page, limit }));
  };

  const handleDelete = async (id) => {
    await dispatch(deleteNote(id)).then((result) => {
      if (result.payload) {
        dispatch(listNotes({ drawing_id, type, page, limit }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="flex justify-end">
        <button
          onClick={openModal}
          className={`py-2 px-3 rounded-full hover:bg-gray-100 transition-colors duration-200 bg-gray-200`}
          title="Add Notes"
        >
          <FontAwesomeIcon icon={faNoteSticky} className={notes.length > 0 ? 'text-green-600' : 'text-gray-600'}/>
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Notes Modal"
        className="bg-white rounded-[12px] p-6 w-[90%] max-w-[800px] h-[80vh] mx-auto mt-[5vh] border border-gray-300 relative overflow-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <button
          className="absolute top-[10px] right-[10px] text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={closeModal}
          aria-label="Close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <h2 className="font-Inter font-[700] text-[24px] mb-4 text-center">
          Notes
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="note_text"
                >
                  Add a Note
                </label>
                <Field
                  as="textarea"
                  type="text"
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-3 rounded-[8px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-1"
                  id="note_text"
                  name="note_text"
                  onChange={(e) => {
                    setFieldValue("note_text", e.target.value);
                  }}
                />
                {errors.note_text && touched.note_text ? (
                  <div className="text-red-700">{errors.note_text}</div>
                ) : null}
              </div>
              <div className="flex justify-end gap-[12px] mt-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className="py-[8px] px-[16px] text-center bg-gray-300 rounded-[8px] text-[14px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  name="submit"
                  className={`py-[8px] px-[16px] text-center text-white rounded-[8px] text-[14px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>

        
      <div className="flex flex-col mt-3 gap-[40px]">
        <div className="">
          <div className="md:p-2 bg-[#F8F8F8] mt-1 rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="md:flex justify-between items-center p-[12px]">
                <div className="leading-[30px] font-Mulish font-[700] text-[20px] mb-[8px] md:mb-0">
                  <span className="">Notes List</span>
                </div>
              </div>
              <div className="sm:rounded-lg mt-3 overflow-scroll">
                <table className="w-full text-center">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Note
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Created By
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Created On
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                  ) : (
                    <tbody>
                      {notes.length > 0 ? (
                        notes.map((note) => (
                          <tr key={note.id} className="text-center">
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {note.note_text}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {note.createdBy}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {formatDate(note.createdAt)}
                            </td>
                            <td className="px-3 py-2 whitespace-nowrap font-medium">
                              <div className="flex gap-[10px] justify-center">
                                <div>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => handleDelete(note.id)}
                                  >
                                    <img
                                      src={process.env.PUBLIC_URL + "/icons/delete.png"}
                                      alt=""
                                      className="w-[40px] bg-red-100 rounded-lg"
                                    />
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center py-4 text-gray-500">
                            No notes created!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-[10px] gap-[40px] md:p-2 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center"
          />
        </div>
      </div>
      </Modal>
    </>
  );
}

export default Notes;
