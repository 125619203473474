import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { getSupplierById } from "../../../store/reducer/supplier/supplierSlice";
import { ListSupplierContacts } from "../../../store/reducer/supplier/supplierContactSlice";

function ListSupplierContact() {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    loading,
    contacts = [],
    totalCount,
  } = useSelector((state) => state.supplierContacts);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [supplierData, setSupplierData] = useState([]);

  useEffect(() => {
    dispatch(ListSupplierContacts({ search, page, limit }));
    dispatch(getSupplierById(id)).then((result) => {
      if (result.payload) {
        setSupplierData(result.payload);
      }
    });
  }, []);

  const handleSearch = (search = "") => {
    // dispatch(listDdOptions({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [contacts]);

  return (
    <>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10">
        <Link to={`/setting/supplier/${id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/setting/supplier/${id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Supplier Detail
          </div>
        </Link>
        <Link to={`/setting/supplier/${id}/billing`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/setting/supplier/${id}/billing`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Billing & Shipping
          </div>
        </Link>
        <Link to={`/setting/supplier/${id}/contact`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/setting/supplier/${id}/contact`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Contacts
          </div>
        </Link>
      </div>
      <div className="block md:grid grid-cols-3 mb-[30px] items-center">
        <div className="flex gap-[16px] items-center mb-[16px] md:mb-0">
          <Link to="/setting/supplier/">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Supplier / List Contacts
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List Supplier Contacts
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center mb-[16px] md:mb-0">
          {supplierData.id}-{supplierData.company}
        </div>
        <div className="ml-auto">
          <Link to={`/setting/supplier/${id}/contact/add`}>
            <div className="py-[10px] px-[40px] bg-[#F8F8F8] font-[500] font-Inter text-[18px] flex justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B] w-max">
              <span>Add Contact</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="">
          <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="md:flex justify-between items-center p-[12px]">
                <div className="leading-[30px] font-Mulish font-[700] text-[24px] mb-[8px] md:mb-0">
                  <span className="">Supplier Contact Data</span>
                </div>
                <div className="relative">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/search.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="sm:rounded-lg mt-5 overflow-scroll">
                <table className="w-full text-center">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                  ) : (
                    <tbody>
                      {contacts
                        .filter((contact) => contact.supplier_id === Number(id))
                        .map((contact) => (
                          <tr key={contact.id} className="text-center">
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                              {contact.id}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {contact.firstname}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {contact.lastname}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {contact.email}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              <span
                                className={`px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-lg ${
                                  contact.status === "Active"
                                    ? `bg-green-200 text-green-800`
                                    : `bg-red-200 text-red-800`
                                } `}
                              >
                                {contact.status}
                              </span>
                            </td>
                            <td className="px-3 py-2 whitespace-nowrap font-medium">
                              <div className="flex gap-[10px] justify-center">
                                <div>
                                  <Link
                                    to={`/setting/supplier/${contact.supplier_id}/contact/${contact.id}`}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/icons/edit.png"
                                      }
                                      alt=""
                                      className="w-[40px] bg-teal-100 rounded-lg"
                                    />
                                  </Link>
                                </div>
                                <div>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/icons/delete.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-red-100 rounded-lg"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListSupplierContact;
