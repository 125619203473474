import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom'; // For navigation to login
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../store/reducer/auth/authSlice";

const RequestPasswordReset = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission status
  const [isSuccess, setIsSuccess] = useState(false); // State to track success message
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  useEffect(() => {
    const local_data = JSON.parse(localStorage.getItem("userSession"));
    if (local_data && local_data.token) {
      navigateToDashboard();
    }
  }, [navigateToDashboard]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the form on submit
    setIsSuccess(false); // Reset success state

    // Dispatch the forgotPassword action
    dispatch(forgotPassword(email)).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        setIsSuccess(true); // Set success state to true
      } else {
        toast.error('Error sending password reset link.');
      }
      setIsSubmitting(false); // Re-enable the form after dispatch
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
      <div className="bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-[#000] flex flex-col items-center">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/image/logo.png"}
              className="w-mx-auto"
              alt="Logo"
            />
          </div>
          <div className="mt-12 flex flex-col items-center w-full">
            <div className="w-full flex-1 mt-8">
              {!isSuccess ? (
                <form onSubmit={handleSubmit}>
                  <div className="mx-auto max-w-md">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      disabled={isSubmitting} // Disable input when submitting
                    />
                    <button
                      type="submit"
                      className="mt-5 tracking-wide font-semibold bg-green-400 text-white-500 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      disabled={isSubmitting} // Disable button when submitting
                    >
                      {isSubmitting ? 'Sending...' : 'Send Reset Link'}
                    </button>
                    {/* Back to login link */}
                    <div className="mt-5 text-center">
                      <Link
                        to="/"
                        className="text-green-500 hover:underline"
                      >
                        Back to Login
                      </Link>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="text-center text-white">
                  <p className="mt-2">Check your email and follow the instructions to reset your password.</p>
                  <p className="mt-2">If you don't see it, be sure to check your spam folder!</p>
                  <Link to="/" className="text-green-500 hover:underline mt-4">Back to Login</Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex-1 bg-green-100 text-center hidden lg:flex">
          <div className="w-full bg-center bg-no-repeat">
            <video
              autoPlay
              loop
              muted
              className="min-w-full min-h-full object-fill"
            >
              <source
                src={process.env.PUBLIC_URL + "/video/Kuboid-Self-Storage-bg.mp4"}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default RequestPasswordReset;
