import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listExpensePeriod = createAsyncThunk(
  "listExpensePeriod",
  async ({ page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/expense-period?page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getExpensePeriodById = createAsyncThunk(
  "getExpensePeriodById",
  async (data) => {
    const request = await api(`api/admin/expense-period/${data}`, "", "get");
    return request;
  }
);

export const getExpensePeriodDataForCsv = createAsyncThunk(
  "getExpensePeriodDataForCsv",
  async (data) => {
    const request = await api(`api/admin/expense-period/download/${data}`, "", "get");
    return request;
  }
);

export const getExpensePeriodForUi = createAsyncThunk(
  "getExpensePeriodForUi",
  async () => {
    const request = await api(`api/admin/expense-period-ui`, "", "get");
    return request;
  }
);

export const addExpensePeriod = createAsyncThunk("addExpensePeriod", async (data) => {
  const request = await api("api/admin/expense-period/add", data, "post");
  return request;
});

export const updateExpensePeriod = createAsyncThunk(
  "updateExpensePeriod",
  async ({ data, id }) => {
    const request = await api(`api/admin/expense-period/${id}`, data, "post");
    return request;
  }
);

export const deleteExpensePeriod = createAsyncThunk("deleteExpensePeriod", async (id) => {
  const request = await api(`api/admin/expense-period/${id}`, {}, "delete");
  return request;
});

const expensePeriodSlice = createSlice({
  name: "expensePeriod",
  initialState: {
    loading: false,
    expensePeriods: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listExpensePeriod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listExpensePeriod.fulfilled, (state, action) => {
        state.expensePeriods = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listExpensePeriod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addExpensePeriod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addExpensePeriod.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addExpensePeriod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateExpensePeriod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExpensePeriod.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.expensePeriods = state.expensePeriods.map((expensePeriod) => {
          if (expensePeriod.id === action.payload.id) {
            return action.payload;
          }
          return expensePeriod;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateExpensePeriod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteExpensePeriod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteExpensePeriod.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteExpensePeriod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default expensePeriodSlice.reducer;
