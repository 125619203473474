import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Papa from "papaparse";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import {
  deleteExpensePeriod,
  listExpensePeriod,
  getExpensePeriodDataForCsv
} from "../../../store/reducer/expensePeriod/expensePeriodSlice";

function formatDateToDDMMYYYY(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year from the date
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Format date to dd-mm-yyyy
  return `${day}-${month}-${year}`;
}
function ListExpensePeriod() {
  const dispatch = useDispatch();
  const {
    loading,
    expensePeriods = [],
    totalCount,
  } = useSelector((state) => state.expensePeriods);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleFetchCSVData = async (expensePeriod) => {
    const result = await dispatch(getExpensePeriodDataForCsv(expensePeriod?.id));
    if (result.payload) {
      // Create a new CSV download link
      const csvData = result.payload;
      // Convert JSON to CSV using Papaparse
      const csvString = Papa.unparse(csvData);
      // Create a Blob with UTF-8 encoding
      const bom = '\uFEFF'; // BOM for UTF-8
      const blob = new Blob([bom + csvString], { type: 'text/csv;charset=utf-8;' });
  
      // Create a temporary link element
      const link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', `expense_period_${formatDateToDDMMYYYY(expensePeriod.start_date)}___${formatDateToDDMMYYYY(expensePeriod.end_date)}.csv`);
    
      // Append the link to the document
      document.body.appendChild(link);
      
      // Programmatically click the link to trigger the download
      link.click();
      
      // Clean up and remove the link
      document.body.removeChild(link);
    }
  };
  
  useEffect(() => {
    dispatch(listExpensePeriod({ page, limit }));
  }, []);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listExpensePeriod({ page, limit }));
  };

  const handleDelete = async (uniqueId) => {
    await dispatch(deleteExpensePeriod(uniqueId)).then((result) => {
      if (result.payload) {
        dispatch(listExpensePeriod({ page, limit }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="block sm:flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[30px] sm:mb-0">
          <Link to="/setting">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Expense Periods
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List Expense Periods
            </span>
          </div>
        </div>
        <div className="">
          <Link to="/setting/expense-period/add">
            <div className="py-[10px] px-[40px] bg-[#F8F8F8] font-[500] font-Inter text-[18px] flex justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B] w-max">
              Add Expense Period
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="">
          <div className="md:p-5 bg-[#F8F8F8] mt-[10px] rounded-[12px]">
            <div className="flex flex-col">
              <div className="md:flex justify-between items-center p-[12px]">
                <div className="leading-[30px] font-Mulish font-[700] text-[24px] mb-[10px] md:mb-0">
                  <span className="">Expense Period Data</span>
                </div>
              </div>
              <div className="sm:rounded-lg mt-2 overflow-scroll">
                <table className="w-full text-center">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        End Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                  ) : (
                    <tbody>
                      {expensePeriods.map((expensePeriod) => (
                        <tr key={expensePeriod.id} className="text-center">
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {formatDateToDDMMYYYY(expensePeriod.start_date)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {formatDateToDDMMYYYY(expensePeriod.end_date)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <span
                              className={`px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-lg ${
                                expensePeriod.status == "Open"
                                  ? `bg-green-200 text-green-800`
                                  : `bg-red-200 text-red-800`
                              } `}
                            >
                              {expensePeriod.status}
                            </span>
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap font-medium">
                            <div className="flex gap-[10px] justify-center">
                              <div>
                                <Link to={`/setting/expense-period/${expensePeriod.id}`}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-teal-100 rounded-lg"
                                  />
                                </Link>
                              </div>
                              <div>
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleDelete(expensePeriod.uniqueId)
                                  }
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/icons/delete.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-red-100 rounded-lg"
                                  />
                                </span>
                              </div>
                              <div>
                              <img
                                src={process.env.PUBLIC_URL + "/icons/download.png"}
                                alt="Download CSV"
                                className="w-[40px] bg-blue-100 rounded-lg cursor-pointer"
                                onClick={() => handleFetchCSVData(expensePeriod)} // Call your function directly
                              />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center "
          />
        </div>
      </div>
    </>
  );
}

export default ListExpensePeriod;
