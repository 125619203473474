import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdditionalOptionItems
} from "../../store/reducer/drawing/drawingPurchaseOrderSlice";

const SupplierItems = ({ purchaseOrderObject, supplier, handleItemAssign, items, areaId }) => (
    <div className="sm:rounded-lg mt-5 overflow-y-auto max-h-[400px]">
      <table className="w-full">
        <thead className="bg-white">
          <tr className="rounded-[12px]">
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Assign
              <input
                type="checkbox"
                className="w-5 h-5 m-1 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                key={`${supplier?.supplier_id}`}
                onChange={(e) => {
                  items?.filter(item => !purchaseOrderObject?.assignedItems?.additionalOptions?.find(it => it.supplier?.supplier_id !== supplier?.supplier_id && it.item.id === item.id)).forEach(item => {
                    handleItemAssign(item, { supplier_id: supplier?.supplier_id, supplier_name: supplier.supplier_name}, e.target.checked)
                  })
                }}
                checked={items?.filter(
                  item => !purchaseOrderObject?.assignedItems?.additionalOptions?.find(it => it.item.id === item.id)
                )?.length == 0}
              />
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Item name
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Type
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Sq M
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Cost
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {items?.length > 0 ? (
            items?.filter(item => !purchaseOrderObject?.assignedItems?.additionalOptions?.find(it => it.supplier?.supplier_id != supplier?.supplier_id && it.item.id == item.id)).map((item, index) => (
              <tr key={item.id + supplier?.supplier_id}>
                <input
                    type="checkbox"
                    className="w-5 h-5 mt-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                    key={`${item.id}-${supplier?.supplier_id}`}
                    onChange={(e) => handleItemAssign(item, { supplier_id: supplier?.supplier_id, supplier_name: supplier.supplier_name }, e.target.checked)}
                    checked={!!purchaseOrderObject['additionalOptions'][supplier?.supplier_id]?.items?.find(it => it.id == item.id)}
                  />
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.item_description}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.type}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.sq_m}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.cost}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="6">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="2xl"
                  style={{ color: "#55A14A" }}
                  spin
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
)

function PurchaseOrdersAdditionalOption({ itemGroup, areaField, supplier, drawing_id, purchaseOrderObject, setPurchaseOrderObject }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [additionalOptionItems, setAdditionalOptionItems] = useState([]);
  
  const handleItemAssign = (item, supplier, assign) => {
    setPurchaseOrderObject((prevState) => {
      const updatedAdditionalOptions = { ...prevState.additionalOptions };
      let updatedAdditionalOptionAssignedItems = [...prevState.assignedItems?.additionalOptions];
      if (assign) {
        if (updatedAdditionalOptions[supplier?.supplier_id]) {
          if (!updatedAdditionalOptions[supplier?.supplier_id].items.some((it) => it.id === item.id)) {
            updatedAdditionalOptions[supplier?.supplier_id] = {
              ...updatedAdditionalOptions[supplier?.supplier_id],
              items: [...updatedAdditionalOptions[supplier?.supplier_id].items, item]
            };
          }
        } else {
          updatedAdditionalOptions[supplier?.supplier_id] = { supplier, items: [item] };
        }
        if (!updatedAdditionalOptionAssignedItems.some((it) => it.item.id === item.id)) {
          updatedAdditionalOptionAssignedItems.push({ item, supplier });
        }
      } else {
        if (updatedAdditionalOptions[supplier?.supplier_id]) {
          const updatedItems = updatedAdditionalOptions[supplier?.supplier_id].items.filter(
            (it) => it.id !== item.id
          );
          if (updatedItems.length > 0) {
            updatedAdditionalOptions[supplier?.supplier_id] = {
              ...updatedAdditionalOptions[supplier?.supplier_id],
              items: updatedItems
            };
          } else {
            delete updatedAdditionalOptions[supplier?.supplier_id];
          }
        }
        updatedAdditionalOptionAssignedItems = updatedAdditionalOptionAssignedItems.filter(
          (it) => it.item.id !== item.id
        );
      }
      return {
        ...prevState,
        additionalOptions: updatedAdditionalOptions,
        assignedItems: {...prevState.assignedItems, additionalOptions: updatedAdditionalOptionAssignedItems }
      };
    });
  };
  

  useEffect(() => {
    dispatch(getAdditionalOptionItems(drawing_id)).then((result) => {
      if (result.payload) {
        let itemsData = result.payload;
        if (supplier?.supplier_id) {
          // If areaField is selected, further filter by area_id
          if (areaField) {
            itemsData = itemsData.filter((data) => data.area_id == areaField);
          }
      
          // If itemGroup is selected, further filter by group_id
          if (itemGroup) {
            itemsData = itemsData.map((data) => ({
              ...data,
              items_provided: data.items_provided.filter((item) => item.group_id == itemGroup)
            })).filter((data) => data.items_provided.length > 0);
          }
      
          // Aggregate items across all areas
          const aggregatedItems = itemsData.flatMap((data) => data.items_provided).reduce((acc, current) => {
            const key = `${current.item_id}`;
      
            if (!acc[key]) {
              acc[key] = {
                ...current,
                cost: 0,
                sq_m: 0,
                groupedAdditionalOptions: []
              };
            }
            
            acc[key].sq_m += current.sq_m;
            acc[key].cost += current.cost;
            acc[key].cost = parseFloat(acc[key].cost).toFixed(2);
            acc[key].sq_m = parseFloat(acc[key].sq_m).toFixed(2);
            acc[key].groupedAdditionalOptions?.push(current.id)
            
            return acc;
          }, {});
      
          // Convert the object back to an array
          const aggregatedItemsArray = Object.values(aggregatedItems);
        
          setAdditionalOptionItems(aggregatedItemsArray);
        }
      }
    });
  }, [supplier, areaField, itemGroup]);
  
  return (
    <>
      {!loading ? (
        <>
        <div className="flex gap-[20px] mb-[10px]">
        <div className="bg-[#F8F8F8] w-full rounded-[12px] p-4 items-center">
          {supplier?.supplier_id ? 
            <SupplierItems 
              handleItemAssign={handleItemAssign}
              supplier={supplier}
              purchaseOrderObject={purchaseOrderObject}
              items={additionalOptionItems}
              areaId={areaField}
            />
            : null
          }
        </div>
        </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default PurchaseOrdersAdditionalOption;
