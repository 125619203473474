import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getSupplierAddressBySupplierId = createAsyncThunk(
  "getSupplierAddressBySupplierId",
  async (data) => {
    const request = await api(`api/admin/supplier-address/${data}`, "", "get");
    return request;
  }
);

export const updateSupplierAddress = createAsyncThunk(
  "updateSupplierAddress",
  async ({ data, id }) => {
    const request = await api(`api/admin/supplier-address/${id}`, data, "post");
    return request;
  }
);

const supplierAddressSlice = createSlice({
  name: "supplierAddress",
  initialState: {
    loading: false,
    address: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateSupplierAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSupplierAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.address = state.address.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateSupplierAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default supplierAddressSlice.reducer;
