import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

// Login User
export const loginuser = createAsyncThunk(
  'auth/loginuser',
  async (userCredentials) => {
    const request = await api("api/admin/login", userCredentials, "post");
    localStorage.setItem("userSession", JSON.stringify({ ...request }));
    return request;
  }
);

// Forgot Password
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email) => {
    const request = await api("api/admin/request-password-reset", { email }, "post");
    return request;
  }
);

// Reset Password
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ token, password }) => {
    const request = await api(`api/admin/reset-password`, { token, password }, "post");
    return request;
  }
);

const authSlice = createSlice({
  name: 'userSession',
  initialState: {
    loading: false,
    user: null,
    error: null,
    forgotPasswordSuccess: null,
    resetPasswordSuccess: null,
  },
  reducers: {
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    removeAuthUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // Login User Cases
    builder
      .addCase(loginuser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(loginuser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginuser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action.error.message;
      });

    // Forgot Password Cases
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.forgotPasswordSuccess = null;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.forgotPasswordSuccess = action.payload.message;
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.forgotPasswordSuccess = null;
        state.error = action.error.message;
      });

    // Reset Password Cases
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.resetPasswordSuccess = null;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.resetPasswordSuccess = action.payload.message;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.resetPasswordSuccess = null;
        state.error = action.error.message;
      });
  }
});

export const { setAuthUser, removeAuthUser } = authSlice.actions;
export default authSlice.reducer;
