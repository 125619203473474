import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getCountryForUi = createAsyncThunk("getCountryForUi", async () => {
  const request = await api(`api/admin/countries`, "", "get");
  return request;
});

const countrySlice = createSlice({
  name: "countries",
  initialState: {
    loading: false,
    countries: [],
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCountryForUi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCountryForUi.fulfilled, (state, action) => {
        state.countries = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCountryForUi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default countrySlice.reducer;
