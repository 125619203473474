import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import {
  deleteExpense,
  listExpenses,
  searchExpenses,
  updateExpense,
} from "../../store/reducer/expenses/expensesSlice";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

function ListExpense() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const formRef = useRef(null);
  const { expenses = [], totalCount } = useSelector((state) => state.expenses);
  const { options = [] } = useSelector((state) => state.ddOptions);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [customerData, setCustomerData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const user_id = JSON.parse(localStorage.getItem("userSession")).id;

  useEffect(() => {
    dispatch(getDdOptionForUI());
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
    
    const userSession = JSON.parse(localStorage.getItem("userSession"));
    if (userSession?.role == 1) { // Admin check
      setIsAdmin(true);
      dispatch(getUserForUI()).then((result) => {
        if (result.payload) {
          setUserData(result.payload);
        }
      });
    }
    dispatch(listExpenses({ user_id, search, page, limit })).then((result) => {
      if (result.payload) {
        setLoading(false);
      }
    });
  }, []);

  const handleSearch = (search = "") => {
    dispatch(listExpenses({ user_id, search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [expenses]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listExpenses({ user_id, search, page, limit }));
  };

  const handleResetFilterForm = () => {
    if (formRef.current) {
      formRef.current.resetForm();
      dispatch(listExpenses({ user_id, search, page, limit }));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setAllChecked(isChecked);

    if (isChecked) {
      const allIds = expenses.map((expense) => expense.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelectOne = (e, id) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedIds((prevIds) => [...prevIds, id]);
    } else {
      setSelectedIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
    }
  };

  const handleApprovedStatus = (id, value) => {
    const data = {
      is_approved: value,
      approved_by: JSON.parse(localStorage.getItem("userSession")).id,
      updatedBy: JSON.parse(localStorage.getItem("userSession")).id,
      approved_on: new Date(),
    };
    dispatch(updateExpense({ data, id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        dispatch(listExpenses({ user_id, search, page, limit }));
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const user_id = values.user_id;
    const category = values.category;
    const expense_date = values.expense_date;
    await dispatch(
      searchExpenses({
        user_id,
        category,
        expense_date,
      })
    );
  };

  const handleDelete = async (uniqueId) => {
    await dispatch(deleteExpense(uniqueId)).then((result) => {
      if (result.payload) {
        dispatch(listExpenses({ user_id, search, page, limit }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <div className="flex flex-col mt-[20px] gap-[40px]">
      <div className="bg-grey md:flex gap-[10px]">
        <div className="w-full md:w-[28%] md:flex flex-col justify-between mb-[32px] md:mb-0">
          <div className="h-[114px] bg-[#5E6FF0] rounded-[12px] border-[10px] border-[#5E6FF0] p-[20px] flex items-center justify-between mb-3">
            <div className="flex flex-col gap-[10px]">
              <span className="text-[32px] leading-[40px] text-white font-Mulish font-[700]">
                {totalCount}
              </span>
              <span className="text-[16px] text-white font-Mulish font-[700]">
                Records returned
              </span>
            </div>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/icons/pad.png"}
                className="w-[88px] h-[88px]"
                alt=""
              />
            </div>
          </div>
          <div className="">
            <Link to="/expense/add">
              <div className="py-[20px] px-[20px] font-[400] text-[20px] justify-center text-white bg-[#55A14A] w-full rounded-[12px] flex gap-[20px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/plus.png"}
                  alt=""
                  className="w-[24px]"
                />
                <span>Add Expense</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="w-full md:w-[72%] md:flex gap-[24px] flex-col bg-[#F8F8F8] rounded-[12px] p-6">
          <div className="flex flex-row justify-between pb-[20px] border-b border-[#E1E1E1] border-solid">
            <div>
              <span className="font-Inter font-[600] text-[22px] leading-[21px] text-[#1C1C20]">
                Search Your Expenses
              </span>
            </div>
            <div>
              <span className="cursor-pointer" onClick={handleResetFilterForm}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/reset.png"}
                  alt=""
                  className="w-[24px] cursor-pointer"
                />
              </span>
            </div>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={{
              user_id: !isAdmin ? user_id : '',
              category: "",
              expense_date: "",
            }}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              values,
              errors,
              touched,
              setFieldValue,
              resetForm,
              handleSubmit,
            }) => (
              <Form>
                <div className="md:grid md:grid-cols-4 gap-3">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="user_id"
                    >
                      User
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="user_id"
                        name="user_id"
                        onChange={(e) => {
                          setFieldValue("user_id", e.target.value);
                        }}
                        disabled={!isAdmin}
                      >
                        {isAdmin ? (
                          <>
                            <option value="">Select User</option>
                            {userData.map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.firstname + " " + user.lastname}
                              </option>
                            ))}
                          </>
                        ) : (
                          (() => {
                            const userSession = JSON.parse(
                              localStorage.getItem("userSession")
                            );
                            return (
                              <option value={userSession?.id} selected={true}>
                                {userSession?.firstname || ""}{" "}
                                {userSession?.lastname || ""}
                              </option>
                            );
                          })()
                        )}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="category"
                    >
                      Category
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="category"
                        name="category"
                        onChange={(e) => {
                          setFieldValue("category", e.target.value);
                        }}
                      >
                        <option value={""}>Select category</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Expense" &&
                              option.field_name === "Category"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="expense_date"
                    >
                      Expense Date
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      id="expense_date"
                      name="expense_date"
                      onChange={(e) => {
                        setFieldValue("expense_date", e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full bg-[#F8F8F8] rounded-[12px] mt-6">
                    <div>
                      <button
                        type="submit"
                        name="submit"
                        className={`py-[10px] px-[70px] text-center text-[#55A14A] border border-[#55A14A] w-full rounded-[12px] text-[18px] ${
                          isSubmitting ? "bg-gray-300" : "bg-white"
                        }`}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <div className="w-[30%] bg-[#000] rounded-[12px]"></div> */}
      </div>
      <div className="">
        {/* <div className="flex justify-between items-center">
          <div className="leading-[30px] font-Mulish font-[700] text-[24px]">
            <span className="">List Of Expenses</span>
          </div>
        </div> */}
        <div className="md:p-5 bg-[#F8F8F8] mt-[10px] rounded-[12px]">
          <div className="flex flex-col">
            <div className="md:flex justify-between items-center p-[5px]">
              <div className="leading-[30px] font-Mulish font-[700] text-[24px] mb-[10px] md:mb-0 flex gap-[20px] items-center">
                <span className="">Expense List</span>
                {!loading && (
                  <CSVLink data={expenses} filename="expenses.csv">
                    <button className="px-[10px] font-[400] text-[12px] justify-center text-white bg-[#929292] w-full rounded-[12px]">
                      Download CSV
                    </button>
                  </CSVLink>
                )}
              </div>
              <div className="relative">
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search here"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debouncedSearch(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/search.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="sm:rounded-lg mt-2 overflow-scroll">
              <table className="w-full">
                <thead className="bg-white">
                  <tr className="rounded-[12px]">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Opportunity
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Staff
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Subtotal
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      VAT
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Total
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Is Reimbursed
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Approved{" "}
                      <input
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Expense Paid
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                ) : (
                  <tbody>
                    {expenses.map((expense) => (
                      <tr key={expense.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                          {moment(expense.expense_date).format("DD-MM-yyyy")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {expense.customer_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {expense.opportunity_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {expense.user_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          ({expense.category_name}) : {expense.notes}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {expense.line_amount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {expense.vat_amount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          {expense.amount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                          <span
                            className={`px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-lg ${
                              expense.is_reimbursable == true
                                ? `bg-green-200 text-green-800`
                                : `bg-red-200 text-red-800`
                            } `}
                          >
                            {expense.is_reimbursable ? "Yes" : "No"}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] text-center">
                          <input
                            type="checkbox"
                            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                            defaultChecked={expense.is_approved}
                            checked={selectedIds.includes(expense.id)}
                            onChange={(e) => handleSelectOne(e, expense.id)}
                            // onChange={(e) => {
                            //   handleApprovedStatus(
                            //     expense.id,
                            //     e.target.checked
                            //   );
                            // }}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] text-center">
                          <input
                            type="checkbox"
                            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                            defaultChecked={expense.expense_paid}
                            disabled
                          />
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-right font-medium">
                          <div className="flex gap-[10px] text-center">
                            <div>
                              <Link to={`/expense/${expense.id}`}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/icons/edit.png"
                                  }
                                  alt=""
                                  className="w-[40px] bg-teal-100 rounded-lg"
                                />
                              </Link>
                            </div>
                            <div>
                              <span
                                className="cursor-pointer"
                                onClick={() => handleDelete(expense.uniqueId)}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/icons/delete.png"
                                  }
                                  alt=""
                                  className="w-[40px] bg-red-100 rounded-lg"
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center "
          />
        </div>
      </div>
    </div>
  );
}

export default ListExpense;
