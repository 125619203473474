import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getUserForUI } from "../../../store/reducer/user/userSlice";
import {
  addProposalFile,
  deleteProposalFile,
  getAllProposalFiles,
} from "../../../store/reducer/proposalFiles/proposalFileSilce";

function ProposalFiles() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [fileData, setFileData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    dispatch(getUserForUI()).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
      }
    });
    dispatch(getAllProposalFiles()).then((result) => {
      if (result.payload) {
        setFileData(result.payload);
        setLoading(false);
      }
    });
  }, []);

  const validationSchema = Yup.object().shape({
    document_for: Yup.string().required("please select document for"),
    display_name: Yup.string().required("please enter display name"),
    document_file: Yup.string().required("please select document file"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addProposalFile({ data: formData })).then((result) => {
      if (result.payload) {
        resetForm();
        dispatch(getAllProposalFiles()).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleDeleteFile = async (id) => {
    await dispatch(deleteProposalFile({ id })).then((result) => {
      if (result.payload) {
        dispatch(getAllProposalFiles()).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/setting`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Proposal Files / Upload
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Proposal Files
            </span>
          </div>
        </div>
      </div>

      <Formik
        initialValues={{
          document_for: "",
          display_name: "",
          document_file: null,
          position: 0,
          is_static: false,
          status: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <div className="grid grid-cols-4 items-center gap-[20px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="document_for"
                >
                  Document For
                </label>
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="document_for"
                    name="document_for"
                    onChange={(e) => {
                      setFieldValue("document_for", e.target.value);
                    }}
                  >
                    <option value={""}>Select User</option>
                    <option value={"All"}>All</option>
                    {userData
                      .filter((user) => user.role != 1)
                      .map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.firstname} {user.lastname}
                        </option>
                      ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {errors.document_for && touched.document_for ? (
                  <div className="text-red-700">{errors.document_for}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="display_name"
                >
                  Display Name
                </label>
                <div className="relative">
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="display_name"
                    name="display_name"
                    onChange={(e) => {
                      setFieldValue("display_name", e.target.value);
                    }}
                  ></Field>
                </div>
                {errors.display_name && touched.display_name ? (
                  <div className="text-red-700">{errors.display_name}</div>
                ) : null}
              </div>
              <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="document_file"
                >
                  Select File
                </label>
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="file"
                  name="document_file"
                  id="document_file"
                  accept="application/pdf"
                  onChange={(e) => {
                    setFieldValue("document_file", e.currentTarget.files[0]);
                  }}
                />
                {errors.document_file && touched.document_file ? (
                  <div className="text-red-700">{errors.document_file}</div>
                ) : null}
              </div>
              {/* <div className="mb-2">
                <label
                  className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                  htmlFor="position"
                >
                  Position
                </label>
                <div className="relative">
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="position"
                    name="position"
                    onChange={(e) => {
                      setFieldValue("position", e.target.value);
                    }}
                  ></Field>
                </div>
                {errors.position && touched.position ? (
                  <div className="text-red-700">{errors.position}</div>
                ) : null}
              </div>
              <div className="mb-2 mt-10">
                <div className="flex items-center mb-4">
                  <Field
                    id="is_static"
                    type="checkbox"
                    checked={values.is_static}
                    onChange={(e) =>
                      setFieldValue("is_static", e.target.checked)
                    }
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="is_static"
                    className="ms-2 text-sm font-medium text-[#9E9E9E]"
                  >
                    Is Static File
                  </label>
                </div>
              </div> */}
              <div className="flex items-center">
                <button
                  type="submit"
                  name="submit"
                  className={`py-[12px] px-[48px] text-center text-white rounded-[12px] text-[18px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Upload File"
                  )}
                </button>
              </div>
            </div>
            <div className="p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
              <div className="flex flex-col">
                <div className="sm:rounded-lg mt-5">
                  <table className="w-full">
                    <thead className="bg-white">
                      <tr className="rounded-[12px]">
                        <th
                          scope="col"
                          className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                        >
                          Document for
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                        >
                          Display Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                        >
                          Uploaded By
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                        >
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {!loading ? (
                        fileData.map((file) => (
                          <tr key={file.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                              {file.document_for === "All"
                                ? "All"
                                : file.document_for_name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {file.display_name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {file.uploaded_by}
                            </td>

                            <td className="px-3 py-2 whitespace-nowrap text-right font-medium">
                              <div className="flex gap-[10px] items-center justify-center">
                                <Link to={file.document_name} target="blank">
                                  <span className="cursor-pointer">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/icons/viewfile.png"
                                      }
                                      alt=""
                                      className="w-[40px] p-[10px] bg-blue-100 rounded-lg"
                                    />
                                  </span>
                                </Link>
                                <span className="cursor-pointer">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/icons/delete.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-red-100 rounded-lg"
                                    onClick={() => {
                                      handleDeleteFile(file.id);
                                    }}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            size="2xl"
                            style={{ color: "#55A14A" }}
                            spin
                          />
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ProposalFiles;
