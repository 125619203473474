import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const ListSupplierContacts = createAsyncThunk(
  "ListSupplierContacts",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/supplier_contacts?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getSupplierContactById = createAsyncThunk(
  "getSupplierContactById",
  async (data) => {
    const request = await api(`api/admin/supplier_contact/${data}`, "", "get");
    return request;
  }
);

export const addSupplierContact = createAsyncThunk(
  "addSupplierContact",
  async (data) => {
    const request = await api("api/admin/supplier_contact", data, "post");
    return request;
  }
);

export const updateSupplierContact = createAsyncThunk(
  "updateSupplierContact",
  async ({ data, contact_id }) => {
    const request = await api(
      `api/admin/supplier_contact/${contact_id}`,
      data,
      "post"
    );
    return request;
  }
);

const supplierContactSlice = createSlice({
  name: "supplierContacts",
  initialState: {
    loading: false,
    contacts: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(ListSupplierContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ListSupplierContacts.fulfilled, (state, action) => {
        state.contacts = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(ListSupplierContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addSupplierContact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSupplierContact.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts.push(action.payload);
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(addSupplierContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSupplierContact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSupplierContact.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = state.contacts.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateSupplierContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default supplierContactSlice.reducer;
