import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ListBusinessMileage from "../BusinessMileage/ListBusinessMileage";
import ListCarMileage from "../CarMileage/ListCarMileage";

const Mileage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab");

  const [activeTab, setActiveTab] = useState("business-mileage");

  useEffect(() => {
    if (tabParam === "car-mileage") {
      setActiveTab("car-mileage");
    }
  }, [tabParam]);

  return (
    <div className="min-h-screen">
      {/* Tabs Navigation */}
      <div className="flex border-b-2 border-gray-200">
        <button
          className={`px-6 py-2 mx-4 border-b-4 transition duration-300 ${
            activeTab === "business-mileage"
              ? "border-green-600 text-green-600 font-bold"
              : "border-transparent text-gray-500 hover:text-green-600"
          }`}
          onClick={() => setActiveTab("business-mileage")}
        >
          Business Mileage
        </button>
        <button
          className={`px-6 py-2 mx-4 border-b-4 transition duration-300 ${
            activeTab === "car-mileage"
              ? "border-green-600 text-green-600 font-bold"
              : "border-transparent text-gray-500 hover:text-green-600"
          }`}
          onClick={() => setActiveTab("car-mileage")}
        >
          Car Mileage
        </button>
      </div>

      {/* Tab Content */}
      <div className="mt-4 mx-4">
        <div className="bg-white shadow-md rounded-lg p-6">
          {activeTab === "business-mileage" && <ListBusinessMileage />}
          {activeTab === "car-mileage" && <ListCarMileage />}
        </div>
      </div>
    </div>
  );
};

export default Mileage;
